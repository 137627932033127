import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from '../app/Services/auth/auth.service';
import { Subscription, timer, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export enum KEY_CODE {
  ESC = 27,//ESC KEY
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gpmsmf-clist-angular';
  sessionCheck;

  constructor(private auth: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    //notify activity
    this.auth.callPing();

    const source = interval(5 * 60 * 1000);
    this.sessionCheck = source.subscribe(() => {
      console.log('session check');
      if (!this.auth.isAuthenticated()) {
        console.log('session timeout, refresing page to get token');
        location.reload();
      }

      setTimeout(() => this.auth.callInactivityCheck(),0);
    });
    
    this.auth.userActivityTimeout();
    this.auth.userInactive.subscribe(() => {
      console.log('notify user is active');
      this.auth.callPing();
    });
  }

  ngOnDestroy() {
    if(this.sessionCheck) this.sessionCheck.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    (window as any).onhelp = function() { return false };
    this.auth.resetActivity();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.auth.resetActivity();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const CurrRoute = this.router.url;
    const excepmtedRoutes = ['Records/ProbationarySystem/EditProbationary/', 'GeneralPersonnel'];
    let isRouteExcluded = false;
    for (let route of excepmtedRoutes) {
      isRouteExcluded = CurrRoute.includes(route);
    }

    if (this.router.routerState.snapshot.url !== '/' && !isRouteExcluded) {
      if (event.keyCode === KEY_CODE.ESC) {
        this.router.navigateByUrl(CurrRoute.substring(CurrRoute.lastIndexOf("/"), 0))
      }
    }
  }

}
