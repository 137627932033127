import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gp-menu',
  templateUrl: './gp-menu.component.html',
  styleUrls: ['./gp-menu.component.scss']
})
export class GPMenuComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.router.navigate(['Mainmenu']);
  }
}
