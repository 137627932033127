import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl} from '@angular/forms';
import { UserService } from 'src/app/Services/auth/user.service';

export class NewEmployee {
  employeeNum: string;
  employeeName: string;
  jobClass: string;
  deptName: string;
  cityCode: string;
  shift: string;
  startDate: string;
  reason: string;
}

@Component({
  selector: 'app-ps-enter-new-records',
  templateUrl: './ps-enter-new-records.component.html',
  styleUrls: ['./ps-enter-new-records.component.scss']
})
export class PSEnterNewRecordsComponent implements OnInit, AfterViewInit {
  empData: NewEmployee = {
    employeeNum: null,
    employeeName: null,
    jobClass: null,
    deptName: null,
    cityCode: null,
    shift: null,
    startDate: null,
    reason: null,
  };

  EmployeeForm = new FormGroup({
    employeeNum: new FormControl(),
    employeeName: new FormControl(),
    jobClass: new FormControl(),
    deptName: new FormControl(),
    cityCode: new FormControl(),
    shift: new FormControl(),
    startDate: new FormControl(),
    reason: new FormControl(),
  });
  showErrorModal: boolean;
  showSuccessModal: boolean;
  showConfirmModal: boolean;
  saveAndExitAction: boolean;
  showSuccessExitModal: boolean;
  formValid: boolean;
  showSaving: boolean;
  savingCompleted: boolean;
  errorMessage: string;
  @ViewChild('focused', {static: false}) private elementRef: ElementRef;
  @ViewChild('startDt', {static: false}) private dateElementRef: ElementRef;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.focus();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'F3':
        event.preventDefault();
        this.onSave(true);
        break;
      case 'F9':
        event.preventDefault();
        this.onSave();
        break;
      default:
    }

  }

  validateFields(): any {
    const requiredFields = [];
    const {
      employeeNum,
      employeeName,
      jobClass,
      deptName,
      cityCode,
      shift,
      startDate,
      reason
    } = this.EmployeeForm.value;
    if (!employeeNum || employeeNum.trim().length === 0) {
      requiredFields.push('EMPNO');
    }
    if (!employeeName || employeeName.trim().length === 0) {
      requiredFields.push('NAME');
    }
    if (!jobClass || jobClass.trim().length === 0) {
      requiredFields.push('JOBCLASS');
    }
    if (!deptName || deptName.trim().length === 0) {
      requiredFields.push('DEPTNAME');
    }
    if (!cityCode || cityCode.trim().length === 0) {
      requiredFields.push('CITYCODE');
    }
    if (!shift || shift.trim().length === 0) {
      requiredFields.push('SHIFT');
    }
    if (!startDate || startDate.trim().length === 0) {
      requiredFields.push('STARTDT');
    }
    if (!reason || reason.trim().length === 0) {
      requiredFields.push('REASON');
    }


    if (requiredFields.length > 0) {
      return {
        valid: false,
        errorMessage: `Error saving new record: ${requiredFields.join(', ')} ${requiredFields.length > 1 ? 'are' : 'is'} required`,
      };
    }

    if (employeeNum && !employeeNum.trim().match(new RegExp('^[0-9]+$'))) {
      return {
        valid: false,
        errorMessage: `Error saving new record: EMPNO must be a number`,
      };
    }

    if (jobClass && !jobClass.trim().match(new RegExp('^[0-9]+$'))) {
      return {
        valid: false,
        errorMessage: `Error saving new record: JOBCLASS must be a number`,
      };
    }

    if (startDate && !startDate.trim().match(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.]\d\d/)) {
      return {
        valid: false,
        errorMessage: `Error saving new record: STARTDT has invalid format`,
      };
    }

    return { valid: true };
  }

  formatDateField(value) {
    if (/\D\/$/.test(value)) {
      value = value.substr(0, value.length - 3);
    }
    const values = value.split('/').map(v => v.replace(/\D/g, ''));
    if (values[0]) { values[0] = this.checkValue(values[0], 12); }
    if (values[1]) { values[1] = this.checkValue(values[1], 31); }
    const output = values.map((v, i) => v.length === 2 && i < 2 ? v + '/' : v);
    value = output.join('').substr(0, 14);
    return value;
  }

  checkValue(str, max) {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str, 10);
      if (isNaN(num) || num <= 0 || num > max) {
        num = 1;
      }
      str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '0' + num : num.toString();
    }
    return str;
  }

  updateDateField(key, e) {
    if (e.key && !e.key.match(new RegExp('^[0-9/]*$'))) {
      return false;
    }

    this.empData[key] = this.formatDateField(e.target.value);
    this.EmployeeForm.controls['startDate'].setValue(e.target.value);
    this.formValid = this.validateFields().valid;
  }

  updateField(key, value) {
    this.formValid = this.validateFields().valid;
  }

  onSave(saveAndExit = false) {
    this.saveAndExitAction = saveAndExit;
    const validator = this.validateFields();
    if (!validator.valid) {
      this.errorMessage = validator.errorMessage;
      this.showErrorModal = true;
    } else {
      this.showConfirmModal = true;
    }
  }

  processSave() {
    const validator = this.validateFields();
    this.showConfirmModal = false;
    if (!validator.valid) {
      this.errorMessage = validator.errorMessage;
      this.showErrorModal = true;
    } else {
      const {
        employeeNum,
        employeeName,
        jobClass,
        deptName,
        cityCode,
        shift,
        startDate,
        reason,
      } = this.EmployeeForm.value;
      this.empData = {
        employeeNum: employeeNum.toUpperCase(),
        employeeName: employeeName.toUpperCase(),
        jobClass: jobClass.toUpperCase(),
        deptName: deptName.toUpperCase(),
        cityCode: cityCode.toUpperCase(),
        shift: shift.toUpperCase(),
        startDate: startDate.toUpperCase(),
        reason: reason.toUpperCase(),
      }
      this.showSaving = true;
      fetch(`${environment.api_home}/api/records/probation/schedule/schedlist`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: (environment.local_dev_mode) ? '' : 'Bearer ' + this.userService.getUser().accessToken
          },
          body: JSON.stringify(this.empData),
        }
      ).then(res => {
        if (res.status === 200) {
          if (!this.saveAndExitAction) {
            this.showSaving = false;
            this.savingCompleted = false;
            this.showSuccessModal = true;
          }
          this.savingCompleted = true;
        } else {
          this.errorMessage = 'Something went wrong, please try again';
          this.showErrorModal = true;
        }
      })
      .catch(() => {
        this.errorMessage = 'There was a problem saving your data. Please try again.';
        this.showErrorModal = true;
      });
    }
  }

  addMore() {
    this.resetFields();
    this.showSuccessModal = false;
  }

  resetFields() {
    this.empData = {
      employeeNum: null,
      employeeName: null,
      jobClass: null,
      deptName: null,
      cityCode: null,
      shift: null,
      startDate: null,
      reason: null,
    };
    this.formValid = false;
    this.elementRef.nativeElement.focus();
  }

  closeErrorModal() {
    this.showErrorModal = false;
  }

  exitTo(path) {
    this.router.navigate([path]);
  }

  closeSavingAndExit() {
    this.showSaving = false;
    this.exitTo('/Records');
  }

  print(willExit = false) {
    fetch(`${environment.api_home}/api/records/probation/schedule/download`, {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': 'attachment; filename="PRINT_PROBATION_NEW_EMPLOYEE"',
        Authorization: (environment.local_dev_mode) ? '' : 'Bearer ' + this.userService.getUser().accessToken
      }
    }).then(res => res.blob())
    .then(blob => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'PRINT_PROBATION_NEW_EMPLOYEE');
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'PRINT_PROBATION_NEW_EMPLOYEE';
        a.click();
        window.URL.revokeObjectURL(url);
      }
      if (willExit) {
        this.exitTo('/Records');
      } else {
        this.addMore();
      }
    })
    .catch(e => {
      this.errorMessage = 'There was a problem printing your data.';
      this.showErrorModal = true;
    });
  }

  closeConfirmBox() {
    this.showConfirmModal = false;
  }
}
