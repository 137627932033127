import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _localStorage: Storage = null;
    constructor() {
        //Some browser will not allow localStorage so we check if sessionStorage is allowed
        try {
            this._localStorage = window.localStorage;
        } catch (e) { }

        try {
            if (!this._localStorage) this._localStorage = window.sessionStorage;
        } catch (e) { }
    }

    set(key: string, val: string) {
        this._localStorage.setItem(key, val);
    }

    get(key: string): string {
        return this._localStorage.getItem(key);
    }

    remove(key:string){
        this._localStorage.removeItem(key);
    }

    clear() {
        this._localStorage.clear();
    }
}