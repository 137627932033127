import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDateFormat]'
})
export class DateFormatDirective {

  private dateElement: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    this.dateElement = this.elRef.nativeElement;
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    if (event.inputType !== 'deleteContentBackward' && event.inputType !== 'insertFromPaste'){
      this.dateElement.value = this.formatDateField(event.target.value);
    }
    if(event.inputType === 'insertFromPaste') {
      if (event.target.value.match(new RegExp('[a-z]'))) this.dateElement.value = '';
      else this.dateElement.value = this.formatDateField(event.target.value);
    }
  }

  @HostListener('keypress', ['$event']) onKeypress(event) {
    if (event.key && !event.key.match(new RegExp('^[0-9/]*$'))) {
      return false;
    }
    this.dateElement.value = this.formatDateField(event.target.value);
  }

  formatDateField(value) {
    if (/\D\/$/.test(value)) {
      value = value.substr(0, value.length - 3);
    }
    const values = value.split('-').map(v => v.replace(/\D/g, ''));
    if (values[0]) { values[0] = this.checkValue(values[0], 9999, 0); }
    if (values[1]) { values[1] = this.checkValue(values[1], 12, 1); }
    if (values[2]) { values[2] = this.checkValue(values[2], 31, 2); }
    // const output = values.map((v, i) => i == 0 ? (v.length === 4 ?  v + '-' : (v.length === 2 && i < 2 ? v + '-' : v )));
    const output = values.map((v, i) => v.length === 4 && i == 0 ? v + '-' : (v.length === 2 && i > 0 && i < 2 ? v + '-' : v ));

    value = output.join('').substr(0, 14);
    return value;
  }

  checkValue(str, max, indx) {
    if(indx !== 0){
      if (str.charAt(0) !== '0' || str === '00') {
        let num = parseInt(str, 10);
          if ((isNaN(num) || num <= 0 || num > max) && indx != 1 && indx != 2) {
            num = 0;
          }
          str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '0' + num : num.toString();

        if (indx == 1 && (str. charAt(0) > 1 || num > max)) {
          if(str.charAt(0) == 1) str = 1;
          else str = '';
        }
        if (indx == 2 && (str. charAt(0) > 3 || num > max)) {
          if(str.charAt(0) == 3) str = 3;
          else str = '';
        }
      }
    }
    
    return str;
  }
}
