import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTimeFormat]'
})
export class TimeFormatDirective {

  private timeElement: HTMLInputElement;

  constructor(private _el: ElementRef) {
    this.timeElement = this._el.nativeElement;
  }
  
  @HostListener('input', ['$event']) onInputChange(event) {
    if (event.inputType !== 'deleteContentBackward' && event.inputType !== 'insertFromPaste'){
      this.timeElement.value = this.formatTimeField(event.target.value);
    }
    if(event.inputType === 'insertFromPaste') {
      if (event.target.value.match(new RegExp('[a-z]'))) this.timeElement.value = '';
      else this.timeElement.value = this.formatTimeField(event.target.value);
    }
  }

  @HostListener('keypress', ['$event']) onKeypress(event) {
    if (event.key && !event.key.match(new RegExp('^[0-9.]*$'))) {
      return false;
    }
    this.timeElement.value = this.formatTimeField(event.target.value);
  }

  formatTimeField(value) {
    if (/\D\/$/.test(value)) {
      value = value.substr(0, value.length - 3);
    }
    const values = value.split('.').map(v => v.replace(/\D/g, ''));
    if (values[0]) { values[0] = this.checkValue(values[0], 23, 0); }
    if (values[1]) { values[1] = this.checkValue(values[1], 59, 1); }
    if (values[2]) { values[2] = this.checkValue(values[2], 59, 2); }
    if (values[3]) { values[3] = this.checkValue(values[3], 1000, 3); }
    const output = values.map((v, i) => v.length === 2 && i < 3 ? v + '.' : v);
    value = output.join('').substr(0, 14);
    return value;
  }

  checkValue(str, max, indx) {
    if (str.charAt(0) !== '0') {
      let num = parseInt(str, 10);
      let numbool = false;
      if ((isNaN(num) || num < 0 || num > max) && indx != 0) {
        if((indx == 1 || indx == 2) && str.length == 2 && str.charAt(0)>5) numbool = true;
        else if(indx == 3 && str > 1000) numbool = true;
        else numbool = false;
        num = 0;
        
      }      
      str = numbool == true ? '' : (num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '' : num.toString());

      if (indx == 0 && str. charAt(0) >= 2 && str.charAt(1) > 3){
        if(str > 23) str = '';
        else str = str.charAt(0) + '';
      }
      if ((indx == 1 || indx == 2) && str. charAt(0) > 6){
        if(str > 59) str = '';
        else str = str.charAt(0) + '';
      }
      // str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '0' + num : num.toString();
    }
    return str;
  }

}
