<app-header></app-header>
<div>
    <div class="col-lg-12 no-padd main-title">
        <span class="">=====            MAINTENANCE OPERATIONS ADMINISTRATION             =====</span><br>
        <span class="">RECORDS SECTION (PROBATIONARY SYSTEM MENU)</span><br>
        <span>============================================================================</span>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd">
        <p class="choices" [routerLink]="['/Records/ProbationarySystem/EnterNewRecords']"><span class="menu-num">&nbsp;1.</span> ENTER NEW RECORDS AND PRINT SCHEDULES</p>
        <p class="choices" (click) = "EditProbationary()"><span class="menu-num">&nbsp;2.</span> EDIT THE PROBATIONARY FILE (CHANGE RECORDS)</p>
        <p class="choices" (click)="gotoPrintReportsDue()"><span class="menu-num">&nbsp;3.</span> PRINT LISTING REPORTS DUE TO BE SENT</p>
        <p class="choices"><span class="menu-num">&nbsp;4.</span> PRINT PROBATION LOG</p>
        <p class="choices"><span class="menu-num">&nbsp;5.</span> PRINT LISTING OF REPORTS THAT ARE LATE</p>
        <p class="choices exit" (click) = "gotoRecordsMenu()"><span class="menu-num">&nbsp;E.</span> EXIT TO RECORDS MENU</p>
        <p class="choices" (click) = "gotoDepartmentalMenu()"><span class="menu-num">&nbsp;X.</span> EXIT TO DEPARTMENTAL MENU</p>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd" name="form-mainmenu">
        <span class="col3 columns">ENTER SELECTION FROM ABOVE MENU: </span>
        <input type="text" class="mf-input" maxlength="1" autofocus (keydown.enter)="NavigateTo($event.target.value)" toUpperCase>
    </div>
</div>
