import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth/user.service";
var baseURL = environment.api_home + '/api';
var APIEndpointService = /** @class */ (function () {
    function APIEndpointService(http, userService) {
        this.http = http;
        this.userService = userService;
    }
    APIEndpointService.prototype.getHttpText = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.PrepareHeader();
                        return [4 /*yield*/, this.http.get(baseURL + url, { headers: this.headers, observe: "body", responseType: 'text' })
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    APIEndpointService.prototype.GetRequest = function (url, data) {
        this.PrepareHeader();
        return this.http.get(baseURL + url, { headers: this.headers, observe: "response" });
    };
    APIEndpointService.prototype.PostRequest = function (url, body, params) {
        this.PrepareHeader();
        return this.http.post(baseURL + url, body, { headers: this.headers, observe: "response", params: params });
    };
    APIEndpointService.prototype.DownloadFile = function (url, filename) {
        this.http.get(baseURL + url, { headers: this.headers, responseType: 'blob' }).subscribe(function (response) {
            var dataType = response.type;
            var binaryData = [];
            binaryData.push(response);
            var blob = new Blob(binaryData, { type: dataType });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                var a = document.createElement('a');
                document.body.appendChild(a);
                var downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                if (filename) {
                    downloadLink.setAttribute('download', filename);
                }
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }
        });
    };
    APIEndpointService.prototype.GetToken = function () {
        return 'Bearer ' + this.userService.getUser().accessToken;
    };
    APIEndpointService.prototype.PrepareHeader = function () {
        this.headerEntry = {
            'Content-Type': 'application/json'
        };
        if (!environment.local_dev_mode) {
            this.headerEntry['Authorization'] = this.GetToken();
        }
        this.headers = new HttpHeaders(this.headerEntry);
    };
    APIEndpointService.prototype.extractData = function (res) {
        var body = res.body;
        if (body) {
            return body;
        }
        else {
            return {};
        }
    };
    APIEndpointService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIEndpointService_Factory() { return new APIEndpointService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService)); }, token: APIEndpointService, providedIn: "root" });
    return APIEndpointService;
}());
export { APIEndpointService };
