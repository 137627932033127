import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  jwtHelper: JwtHelperService = new JwtHelperService();
  pingApi: Observable<any>;
  inactiveCheckApi: Observable<any>;
  ping = environment.ping;
  userActivity;
  userInactive: Subject<any> = new Subject();
  auth_service = environment.auth_api;

  constructor(private http: HttpClient, private usrService: UserService,private router:Router) {

    const httpOptions = {
      withCredentials: true,
      responseType: 'text' as 'text'
    }

    this.pingApi = this.http.get(this.auth_service + 'ping', httpOptions);
    this.inactiveCheckApi = this.http.get(this.auth_service + 'inactiveCheck', httpOptions);
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.usrService.getUser().accessToken);
  }

  resetActivity() {
    this.clearUserActivityTimeout();
    this.userActivityTimeout();
  }

  userActivityTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(), this.ping * 1000);
  }

  clearUserActivityTimeout() {
    clearTimeout(this.userActivity);
  }

  clearUserActivity() {
    clearTimeout(this.userActivity);
    this.userInactive.complete();
  }

  callPing() {
    this.pingApi.subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.log(err);
      }
    );

  }

  callInactivityCheck() {
    this.inactiveCheckApi.subscribe(
      data => {
        var isTrueSet = (data == 'true');
        if (isTrueSet) {
          console.log('timeout : ' + data);
          this.router.navigate(['/logout']);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
