import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { RecordsService } from 'src/app/Services/records.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/Services/auth/user.service';

@Component({
  selector: 'app-print-probationary-due',
  templateUrl: './print-probationary-due.component.html',
  styleUrls: ['./print-probationary-due.component.scss']
})
export class PrintProbationaryDueComponent implements OnInit {

  showModal: boolean;
  showModalStatus = false;
  modalError = false;
  modalDownloadReady = false;
  realTime: string;
  realCount: string;

  constructor(private recordService: RecordsService, private router: Router, private aRoute: ActivatedRoute, private userService: UserService) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.focusOnInput(500);
  }

  NavigateTo(NavKey) {
    switch (NavKey) {
      case "Y":
        this.displayPrintModal();
        break;
      case 'N':
        this.goBackToProbationSystem();
        break;
    }
    return;
  }

  displayPrintModal() {
    this.showModal = true;
    fetch(`${environment.api_home}/api/records/probation/print/extract`,
      {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: (environment.local_dev_mode) ? null : 'Bearer ' + this.userService.getUser().accessToken
        }
      }
    ).then(res => {
      if(res.status !== 200) {
        throw new Error("Error retreiving data.")
      } else {
        res.json().then(data =>
          { 
            this.realCount = data.recordSize;
            this.realTime = data.responseTime;
          });
          this.showModalStatus = true;
      }
    }).catch(err => {
      this.modalError = true;
    });
  }

  printReportsDue() {
    const binaryData = [];
    const fileName = 'PROBNOT';
    fetch(`${environment.api_home}/api/records/probation/print/download`,
    {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: (environment.local_dev_mode) ? null : 'Bearer ' + this.userService.getUser().accessToken
      }
    }
  )
  .then(res => {
    if (res.status !== 200) {
      throw new Error("Error retreiving data.");
    } else {
      res.blob().then(data => {
        const dataType = data.type;
        binaryData.push(data);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(data, fileName);
        } else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType, endings: 'native'})).replace(/\n/g, "\r\n");;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
      setTimeout(() => this.goBackToProbationSystem(), 1000);
    }
  })
  .catch(err => {
    this.showModalStatus = false;
    this.modalError = true;
  }); }


  focusOnInput(time: number) {
    setTimeout(() => document.querySelector("input").focus(), time);
  }

  hideErrorPrompt() {
    this.focusOnInput(0);
    this.modalError = false;
    this.showModal = false;
  }

  goBackToProbationSystem() {
    this.router.navigate(["Records/ProbationarySystem"]);
  }
}
