<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">DOWNLOAD BASIC SHIFTS INTO VACATION PROJECT</span>
    <!-- <span class="page-titlesub"></span> -->
    <br><br>
</div>

<div class="col-lg-12 no-padd">
    <p class="description">This routine will match the employee numbers and update Shift on the vacation file.</p>
    <p class="description">Are you sure you want to continue? (Y/N)</p>
    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <ul class="col-lg-12 no-padd">
        <li class="choices-yn"(click) = 'checkvalue()' ><span class="menu-num">Y.</span> Yes</li>
        <li class="choices-yn" (click) = 'gotoVacationMenu()'><span class="menu-num">N.</span> No</li>
    </ul>
    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">{{ content }}<span class="loader" [style.display]="OnProcess ? 'inline-block' : 'none'"> </span></p>
          <!-- <p class="modal-desc2">{{ ques }}</p> -->
          <div class="btn-holder" [style.display]="showModalButton ? 'block' : 'none'">
            <!-- <button class="btn btn-download" (click) = 'createVacafile()'>Download</button> -->
            <button class="btn btn-exit" (click) = 'gotoVacationMenu()' #Exit>OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->

    <div class="col-lg-12 no-padd" name="form-mainmenu">
        <span class="columns">ENTER OPTION HERE:</span>
        <input type="text" #YN class="mf-input" maxlength="1" autofocus booleanOnly toUpperCase (keydown.enter)="NavigateTo($event.target.value)">
    </div>
</div>


 

