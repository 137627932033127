import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user.service";
import * as i3 from "@angular/router";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, usrService, router) {
        this.http = http;
        this.usrService = usrService;
        this.router = router;
        this.jwtHelper = new JwtHelperService();
        this.ping = environment.ping;
        this.userInactive = new Subject();
        this.auth_service = environment.auth_api;
        var httpOptions = {
            withCredentials: true,
            responseType: 'text'
        };
        this.pingApi = this.http.get(this.auth_service + 'ping', httpOptions);
        this.inactiveCheckApi = this.http.get(this.auth_service + 'inactiveCheck', httpOptions);
    }
    AuthenticationService.prototype.isAuthenticated = function () {
        return !this.jwtHelper.isTokenExpired(this.usrService.getUser().accessToken);
    };
    AuthenticationService.prototype.resetActivity = function () {
        this.clearUserActivityTimeout();
        this.userActivityTimeout();
    };
    AuthenticationService.prototype.userActivityTimeout = function () {
        var _this = this;
        this.userActivity = setTimeout(function () { return _this.userInactive.next(); }, this.ping * 1000);
    };
    AuthenticationService.prototype.clearUserActivityTimeout = function () {
        clearTimeout(this.userActivity);
    };
    AuthenticationService.prototype.clearUserActivity = function () {
        clearTimeout(this.userActivity);
        this.userInactive.complete();
    };
    AuthenticationService.prototype.callPing = function () {
        this.pingApi.subscribe(function (data) {
            console.log(data);
        }, function (err) {
            console.log(err);
        });
    };
    AuthenticationService.prototype.callInactivityCheck = function () {
        var _this = this;
        this.inactiveCheckApi.subscribe(function (data) {
            var isTrueSet = (data == 'true');
            if (isTrueSet) {
                console.log('timeout : ' + data);
                _this.router.navigate(['/logout']);
            }
        }, function (err) {
            console.log(err);
        });
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.Router)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
