<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">PRODUCE ROTATING EMPLOYEE LIST</span>
    <span class="page-titlesub">VACAFILE</span>
    <br><br>
</div>

<div class="col-lg-12 no-padd">
    <p class="description">Determine for each rotating employee - which shift he works the majority of days per week on. Enter proper vacation shift</p>
    <p class="description">using the edit function.</p>
    <p class="description">Listing will also include anyone the computer was unable to determine the proper vacation shift for.</p>
    <p class="description">Are you sure you want to continue? (Y/N)</p>
    <br>

    <ul class="col-lg-12 no-padd">
        <li class="columns choices-yn" (click)="inputYes()"><span class="menu-num">Y.</span> Yes</li>
        <li class="columns choices-yn" (click)="inputNo()"><span class="menu-num">N.</span> No</li>
    </ul>
    <br><br><br>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">{{ content }}</p>
                    <p class="modal-time">{{ time }}</p>
                    <p class="modal-count">{{ count }}</p>
                    <p class="modal-desc2">{{ ques }}</p>
                    <div class="btn-holder" [style.display]="showModalButton ? 'block' : 'none'">
                        <button class="btn btn-download" (click)='produceRotatingList()'>Download</button>
                        <button class="btn btn-exit" (click)='inputNo()'>Exit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 no-padd form-mainmenu" name="form-mainmenu">
        <span class="columns">ENTER OPTION HERE:</span>
        <input type="text" class="mf-input" (keydown.enter)="NavigateTo($event.target.value) " toUpperCase>
    </div>

</div>