import {
  Directive,
  HostListener,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[appDateInput]'
})
export class DateInputDirective {

  private dateElement: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    this.dateElement = this.elRef.nativeElement;
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    if (event.inputType !== 'deleteContentBackward'){
      this.dateElement.value = this.formatDateField(event.target.value);
    }
  }

  @HostListener('keypress', ['$event']) onKeypress(event) {
    if (event.key && !event.key.match(new RegExp('^[0-9/]*$'))) {
      return false;
    }
    this.dateElement.value = this.formatDateField(event.target.value);
  }

  formatDateField(value) {
    if (/\D\/$/.test(value)) {
      value = value.substr(0, value.length - 3);
    }
    const values = value.split('/').map(v => v.replace(/\D/g, ''));
    if (values[0]) { values[0] = this.checkValue(values[0], 12); }
    if (values[1]) { values[1] = this.checkValue(values[1], 31); }
    const output = values.map((v, i) => v.length === 2 && i < 2 ? v + '/' : v);
    value = output.join('').substr(0, 14);
    return value;
  }

  checkValue(str, max) {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str, 10);
      if (isNaN(num) || num <= 0 || num > max) {
        num = 1;
      }
      str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '0' + num : num.toString();
    }
    return str;
  }


}
