<app-header></app-header>
<div>
    <div class="col-lg-12 no-padd main-title">
        <span class="">=====            MAINTENANCE OPERATIONS ADMINISTRATION             =====</span><br>
        <span class="">RECORDS SECTION</span><br>
        <span>============================================================================</span>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd">
        <p class="choices"><span class="menu-num">&nbsp;1.</span> PREBIDS</p>
        <p class="choices" (click) = "Probationary()"><span class="menu-num">&nbsp;2.</span> PROBATIONARY SYSTEM</p>
        <p class="choices exit" (click) = "gotoMainMenu()"><span class="menu-num">&nbsp;E.</span> EXIT TO MAIN MENU</p>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd" name="form-mainmenu">
        <span class="col3 columns">ENTER SELECTION FROM ABOVE MENU: </span>
        <input type="text" class="mf-input" maxlength="1" autofocus (keydown.enter)="NavigateTo($event.target.value)" toUpperCase>
    </div>
</div>
