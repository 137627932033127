import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { APIEndpointService } from '../../../Services/api-endpoint.service';
import { UserService } from '../../../Services/auth/user.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-hc-vacation-project',
  templateUrl: './hc-vacation-project.component.html',
  styleUrls: ['./hc-vacation-project.component.scss']
})
export class HCVacationProjectComponent implements OnInit, AfterViewInit {
  [x: string]: any;

  blockModal: boolean;
  showVacationWorkbrainModal: boolean;
  workbrainGenerateStatus: string;
  extractResult: {
    numberOfRecordsEx: any,
    responseTime: any,
  };

  showCreateIVRModal: boolean;
  createIVRGenerateStatus: string;
  IVRextractResult: {
    responseTime: any,
  };

  showUpdateIVRModal: boolean;
  updateIVRGenerateStatus: string;
  updateIVRextractResult: {
    responseTime: any,
  };

  showLoadVacationToDB2Modal: boolean;
  loadVacationToDB2Status: string;
  loadVacationToDB2Result: {
    responseTime: any,
  };
  requestBuffer: any;
  loadVacationToDB2ErrorMessage = 'Something went wrong, please try again!';

  hasMEADADMN: boolean = false;
  hasSUPERADMIN: boolean = false;
  restrictionInprogress : boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private apiService: APIEndpointService, private userService: UserService, private http: HttpClient) { }

  ngOnInit(): void {
    this.hasMEADADMN = false;
    this.hasSUPERADMIN = false;

    const auth_service = environment.auth_api;
    this.http.get(auth_service + 'api/authUsers?email=' + this.userService.getUser().email)
      .subscribe((data: any) => {
        this.restrictionInprogress = true;
        if (data) {
          data.forEach(e => {
            if ("SUPERADMIN" === e.racfGroup) {
              this.hasSUPERADMIN = true;
              console.log(this.restrictionInprogress);
            } else if ("MEADADMN" === e.racfGroup) {
              this.hasMEADADMN = true;
            }
          });
        }
        console.log(this.restrictionInprogress);
        this.restrictionInprogress = false;
      });
  }

  ngAfterViewInit() {
    setTimeout(() => document.querySelector("input").focus(), 500);
  }


  NavigateTo(NavKey) {
    NavKey = NavKey.replace(/\s/g, "");
    switch (NavKey) {
      case "1":
        if ((this.hasMEADADMN && !this.hasSUPERADMIN) || this.restrictionInprogress) return;
        this.gotoCreateVacafile();
        break;
      case "2":
        if(this.restrictionInprogress) return;
        this.gotoEditVacafile();
        break;
      case "3":
        if ((this.hasMEADADMN && !this.hasSUPERADMIN) || this.restrictionInprogress) return;
        this.gotoBasicShift();
        break;
      case "4":
        if ((this.hasMEADADMN && !this.hasSUPERADMIN) || this.restrictionInprogress) return;
        this.showBlockModal();
        break;
      case "5A":
        if(this.restrictionInprogress) return;
        this.gotoInitialVacRoster();
        break;
      case "5B":
        if(this.restrictionInprogress) return;
        this.gotoFinalVacRoster();
        break;
      case "6A":
        if(this.restrictionInprogress) return;
        this.gotoProdVacCalcA();
        break;
      case "6B":
        if ((this.hasMEADADMN && !this.hasSUPERADMIN) || this.restrictionInprogress) return;
        this.gotoProdVacCalcB();
        break;
      case "7":
        if ((this.hasMEADADMN && !this.hasSUPERADMIN) || this.restrictionInprogress) return;
        this.processLoadVactionToDB2();
        break;
      case "8A":
        if(this.restrictionInprogress) return;
        this.generateIVRReports();
        break;
      case "8B":
        if ((this.hasMEADADMN && !this.hasSUPERADMIN) || this.restrictionInprogress) return;
        this.generateUpdateIVR();
        break;
      case "9":
        if ((this.hasMEADADMN && !this.hasSUPERADMIN) || this.restrictionInprogress) return;
        this.downloadWorkbrainVacaFile();
        break;
      case "E":
        this.gotHeadcountMenu();
        break;
    }
    return;
  }

  gotoCreateVacafile() {
    this.router.navigate(['Headcount/VacationProject/CreateVacafile']);
  }
  gotoEditVacafile() {
    this.router.navigate(['Headcount/VacationProject/VacationList']);
  }
  gotoBasicShift() {
    this.router.navigate(['Headcount/VacationProject/DownloadBasicShift']);
  }
  gotoProduceInactiveList() {
    this.router.navigate(['Headcount/VacationProject/ProduceInactiveList']);
  }
  gotoProduceRotatingEmp() {
    this.router.navigate(['Headcount/VacationProject/RotatingEmployees']);
  }
  gotoInitialVacRoster() {
    this.router.navigate(['Headcount/VacationProject/InitialRoster']);
  }
  gotHeadcountMenu() {
    this.router.navigate(['Headcount']);
  }
  gotoFinalVacRoster() {
    this.router.navigate(['Headcount/VacationProject/FinalRoster']);
  }
  gotoProdVacCalcA() {
    this.router.navigate(['Headcount/VacationProject/ProduceVacCalcA']);
  }
  gotoProdVacCalcB() {
    this.router.navigate(['Headcount/VacationProject/ProduceVacCalcB']);
  }
  showBlockModal() {
    this.blockModal = true;
  }
  hideBlockModal() {
    this.blockModal = false;
  }



  downloadWorkbrainVacaFile() {
    this.showVacationWorkbrainModal = true;
  }

  generateMctcfile() {
    this.workbrainGenerateStatus = 'generating';
    this.apiService.GetRequest('/headcount/vacation/create/workbrain/extract')
      .subscribe(
        (response) => {
          if (response.ok) {
            const { numberOfRecordsEx, responseTime } = response.body;
            this.extractResult = {
              numberOfRecordsEx,
              responseTime,
            };
            this.workbrainGenerateStatus = 'completed';
          } else {
            this.workbrainGenerateStatus = 'error';
          }
        },
        (error) => {
          this.workbrainGenerateStatus = 'error';
        });
  }

  startMctcDownload() {
    const d = new Date();
    const workBrainYear = d.getFullYear() + 1;

    this.workbrainGenerateStatus = 'generating';
    this.apiService.DownloadFile('/headcount/vacation/create/workbrain/download', `Year${workBrainYear}_Vacation_Report.xlsx`);
    this.closeVacationWorkbrainDownload();
  }

  closeVacationWorkbrainDownload() {
    this.workbrainGenerateStatus = null;
    this.extractResult = null;
    this.showVacationWorkbrainModal = false;
  }


  /**
   * Create IVR for Audit Reports
   */

  generateIVRReports() {
    this.showCreateIVRModal = true;
      this.showCreateIVRModal = true;
      this.apiService.GetRequest('/headcount/vacation/ivr/create')
        .subscribe(
          (response) => {
            if (response.ok) {
              console.log(response);
              const { responseTime } = response.body;
              this.IVRextractResult = {
                responseTime,
              };
            }
          },
          (error) => {
            this.createIVRGenerateStatus = 'error';
          });
    
  }

  generateCreateIVRZip() {
    this.apiService.GetRequest('/headcount/vacation/ivr/compress-files')
      .subscribe(
        (response) => {
          if (response.ok) {
            clearTimeout(this.requestBuffer);
            this.createIVRGenerateStatus = 'completed';
          }
        },
        (error) => {
          this.createIVRGenerateStatus = 'error';
        });
  }

  startIVRReportsDownload() {
    this.createIVRGenerateStatus = 'generating';
    this.apiService.DownloadFile('/headcount/vacation/ivr/download', `IVRReportFiles.zip`);
    this.closeIVRReportsDownload();
  }

  closeIVRReportsDownload() {
    this.createIVRGenerateStatus = null;
    this.IVRextractResult = null;
    this.showCreateIVRModal = false;
  }

  /**
   * Update IVR for Audit Reports
   */


  generateUpdateIVR() {
    this.showUpdateIVRModal = true;
    this.apiService.GetRequest('/headcount/vacation/updateivr/update')
      .subscribe(
        (response) => {
          console.log('RESPONSE');
          console.log(response);
          if (response.ok) {
            const { responseTime } = response.body;
            this.updateIVRextractResult = {
              responseTime,
            };
            this.updateIVRGenerateStatus = 'completed';
          }
        },
        (error) => {
          this.updateIVRGenerateStatus = 'error';
          console.log('ERROR');
          console.log(error);
        });
  }

  startUpdateIVRDownload() {
    this.apiService.DownloadFile('/headcount/vacation/updateivr/download', `UpdateIVRFiles.zip`);
  }

  closeUpdateIVRDownload() {
    this.updateIVRGenerateStatus = null;
    this.updateIVRextractResult = null;
    this.showUpdateIVRModal = false;
  }

  /**
   * Load Vacation to DB2
   */

  processLoadVactionToDB2() {
    this.showLoadVacationToDB2Modal = true;
    this.apiService.GetRequest('/headcount/vacation/LoadVacationFile/LoadFile')
      .subscribe(
        (response) => {
          if (response.ok) {
            const { responseTime } = response.body;
            this.loadVacationToDB2Result = {
              responseTime,
            };
            if (responseTime === 'Error in batch script process'
              || responseTime === 'Connection failed on DB2 server'
              || responseTime === 'FileStorage Failed') {
              this.loadVacationToDB2Status = 'error';
              this.loadVacationToDB2ErrorMessage = responseTime;
            } else {
              this.loadVacationToDB2Status = 'completed';
            }
          }
        },
        (error) => {
          this.loadVacationToDB2Status = 'error';
        });
  }

  closeLoadVacationToDB2() {

    this.showLoadVacationToDB2Modal = false;
  }


}
