
import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RecordsProbationService } from '../../../../Services/records-probation.service'
import * as moment from 'moment';



@Component({
  selector: 'app-edit-probationary',
  templateUrl: './edit-probationary.component.html',
  styleUrls: ['./edit-probationary.component.scss']
})
export class EditProbationaryComponent implements OnInit {


  constructor(private activatedRoute: ActivatedRoute, private ProbationService: RecordsProbationService, private router: Router) {

    this.EmployeeForm = new FormGroup({
      id: new FormControl("", [Validators.required]),
      empNo: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      jobClass: new FormControl("", [Validators.required]),
      deptName: new FormControl("", [Validators.required]),
      cityCode: new FormControl("", [Validators.required]),
      shift: new FormControl("", [Validators.required]),
      startDt: new FormControl("", [Validators.required]),
      day30: new FormControl("", [Validators.required]),
      day60: new FormControl("", [Validators.required]),
      day90: new FormControl("", [Validators.required]),
      reason: new FormControl("", [Validators.required]),
      sent30: new FormControl("", [Validators.required]),
      sent60: new FormControl("", [Validators.required]),
      sent90: new FormControl("", [Validators.required]),
      sento30: new FormControl("", [Validators.required]),
      sento60: new FormControl("", [Validators.required]),
      sento90: new FormControl("", [Validators.required]),
      recd30: new FormControl("", [Validators.required]),
      recd60: new FormControl("", [Validators.required]),
      recd90: new FormControl("", [Validators.required]),
      comments: new FormControl("", [Validators.required])
    });

   }

  @ViewChild('ProbationTable', {static: false}) ProbationTable: ElementRef;
  EmployeeForm: FormGroup;
  CheckChangesModal = false;
  saveSuccessModal = false;
  hasSearchResults: boolean;
  ProbList: any;
  Page: number;
  EmpNo = "";
  Name = "";
  last: boolean;
  first: boolean;
  totalPages = "";
  VCurrentPage = "0";
  CurrentPage = "0";
  SelectedID = "";
  SelectedProb = "";
  CurrentIndex = 0;
  ExitModal = false;
  SaveAndExitModal = false;
  DeleteModal = false;
  enableForm=false;
  willExit = false;
  isLoading = false;
  isPageLoading = false;
  changeActiveProb: number;
  PaginationDisabled=true;

  InitializeForm() {

 
  }


  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
        event.preventDefault();
        if (this.isListModified()) {
          this.willExit = true;
          this.ExitModal = true;
        } else {
          this.router.navigate(['Records/ProbationarySystem']);
        }
        return false;
        break;
      default:
    }

  }
  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.params.subscribe(params => {
      this.Page = parseInt(params['Page']);
    });
    this.GetAllProbationList();
    setTimeout(() => { this.ProbationTable.nativeElement.querySelector('.tr-list').classList.add('colortest') }, 800)

  }

  GetAllProbationList() {
    this.ProbationService.SearchProbation({ empNo: this.EmpNo, name: this.Name }, this.Page).subscribe(res => {
      let data = this.extractData(res);
      if (res["status"] == 200 || res["status"] == 201) {
        if (!data["empty"]) {
          this.ProbList = data["content"];
          this.first = data["first"];
          this.last = data["last"];
          this.totalPages = data["totalPages"];
          this.hasSearchResults = true;
          this.SelectProb(0);
          this.VCurrentPage = ((this.Page + 1)).toString();
          this.enableForm = true;
          this.isLoading = false;
          this.PaginationDisabled=false;      
        }
      }
    });
  }

  CloseModal() {
    this.CheckChangesModal = false;
    this.saveSuccessModal = false;
    this.ExitModal = false;
  }

  private extractData(res) {
    let body = res.body;
    if (body) {
      return body;
    } else {
      return {};
    }
  }
  ResetSearch() {
    if (this.isListModified())
      this.CheckChangesModal = true;
    else {
      this.EmpNo = '';
      this.Name = '';
      this.router.navigateByUrl(this.router.url.replace(this.Page.toString(), "0"));
      this.SearchProb(0,0);
    }
  }

  gotoProbationaryMenu() {
    if (this.isListModified())
      this.CheckChangesModal = true;
    else
      this.router.navigate(['Records/ProbationarySystem']);
  }

  isListModified() {
    let requestBody = {};
    for (let control in this.EmployeeForm.controls) {
      if (this.EmployeeForm.get(control).dirty) {
        requestBody[control] = this.EmployeeForm.get(control).value;
      }
    }
    return (Object.keys(requestBody).length) ? true : false;
  }

  SelectProb(index) {
    if (this.isListModified()) {
        this.CheckChangesModal = true;
        this.changeActiveProb = index;
    } else {
      try {
        this.EmployeeForm.setValue(this.ProbList[index]);
        this.SelectedProb = this.ProbList[index]['emp_NO'];
        this.SelectedID = this.ProbList[index]['id'];
        this.CurrentIndex = index;
        this.EmployeeForm.setValue(this.ProbList[index]);
        (index != 0) ? this.ProbationTable.nativeElement.querySelector('.tr-list').classList.remove('colortest') : '';
      }
      catch (Error) { console.log(Error.message) }
    }
  }

  SearchProb(index?,Page?) {
    if (this.isListModified()) {
      this.CheckChangesModal = true;
    } else {
        try {
          this.ProbationService.SearchProbation({ empNo: this.EmpNo, name: this.Name },0).subscribe(res => {
            let data = this.extractData(res);
            if (res["status"] == 200 || res["status"] == 201) {
              if (!data["empty"]) {    
                console.log(data)         
                this.ProbList = data["content"];                
                this.first = data["first"];
                this.last = data["last"];
                this.CurrentPage = data["number"];
                this.totalPages = data["totalPages"];
                this.VCurrentPage = ((parseInt(this.CurrentPage)) + 1).toString();
                this.hasSearchResults = true;
                this.enableForm = true;
                
                // this.SelectProb(index ?? 0); 
                let i;
                if(index) i = index;
                else i = 0;  
                this.SelectProb(i);                
              } else {
                console.log(data)  
                this.hasSearchResults = false;
                this.EmployeeForm.reset();
                this.enableForm = false;
              }
            }
            this.router.navigateByUrl(this.router.url.replace(this.Page.toString(), "0"));
          });
        } catch (Error) { console.log(Error.message);
      }}
  }

  NextPage() {
    if (this.isListModified()) {
      this.CheckChangesModal = true;
    } else {
      this.PaginationDisabled=true;
      let PageNo = ((this.Page + 1) >= parseInt(this.totalPages)) ? this.Page : ((this.Page) + 1);
      this.ProbationService.SearchProbation({ empno: this.EmpNo, name: this.Name }, PageNo).subscribe(res => {
        this.isPageLoading = true;
        let data = this.extractData(res);
        if (res["status"] == 200 || res["status"] == 201) {
          if (data["content"].length) {
            this.ProbList = data["content"];
            this.first = data["first"];
            this.last = data["last"];
            this.VCurrentPage = ((this.Page + 1) + 1).toString();
            this.router.navigateByUrl(this.router.url.replace(this.Page.toString(), (this.Page + 1).toString()));
            this.SelectProb(0);
          }
          this.isPageLoading = false;
        }
        this.PaginationDisabled=false;
      });

    }
  }

  PreviousPage() {
    if (this.isListModified()) {
      this.CheckChangesModal = true;
    } else {
      this.PaginationDisabled=true;
    let PageNo = ((this.Page - 1) <= 0) ? 0 : ((this.Page) - 1);
    this.ProbationService.SearchProbation({ empno: this.EmpNo, name: this.Name }, PageNo | 0).subscribe(res => {
      this.isPageLoading = true;
      let data = this.extractData(res);
      if (res["status"] == 200 || res["status"] == 201) {
        if (data["content"].length) {
          this.ProbList = data["content"];
          this.first = data["first"];
          this.last = data["last"];
          this.VCurrentPage = ((this.Page + 1) - 1).toString();
          this.router.navigateByUrl(this.router.url.replace(this.Page.toString(), (this.Page - 1).toString()));
          this.SelectProb(0);
        }
        this.isPageLoading = false;
      }
      this.PaginationDisabled=false;
    });
  }
  }


  getLastProb() {
    if (this.isListModified()) {
      this.CheckChangesModal = true;
    }
    else {
      console.log(this.totalPages);
      this.ProbationService.FetchLast({ empno: this.EmpNo, name: this.Name }, parseInt(this.totalPages)-1).subscribe(res => {
        let data = this.extractData(res);
        if (res["status"] == 200 || res["status"] == 201) {
          this.ProbList = data["content"];
          console.log(data);
          this.router.navigateByUrl(this.router.url.replace(this.Page.toString(), (parseInt(this.totalPages) - 1).toString()));
          this.first = data["first"];
          this.last = data["last"]; 
          this.VCurrentPage = (this.totalPages).toString();         
          this.SelectProb(data["numberOfElements"]-1);
        }
      });
    }
  }

  getFirstProb() {
    if (this.isListModified()) {//Prompt user if there are changes
      this.CheckChangesModal = true;
    }
    else {//
      this.ProbationService.FetchFirst({ empno: this.EmpNo, name: this.Name }, 0).subscribe(res => {
        let data = this.extractData(res);
        if (res["status"] == 200 || res["status"] == 201) {
          this.ProbList = data["content"];
          this.ProbList = data["content"];
          console.log(data);
          this.router.navigateByUrl(this.router.url.replace(this.Page.toString(), "0"));
          this.first = data["first"];
          this.last = data["last"];    
          this.VCurrentPage = "1";
          this.SelectProb(0);
        }
      });
    }
  }

  SaveChanges(postAction?,AutoselectdIndex?) {
    let name = this.EmployeeForm.controls.name.value;
    if (name.trim() === "" && postAction != "YesFromDelModal") {
      this.DeleteModal = true;
    } else {
      this.ProbationService.SaveProbRecord(this.EmployeeForm.value).subscribe(res => {
        if (res["status"] == 200 || res["status"] == 201) {
          this.saveSuccessModal = !this.saveSuccessModal;
          this.EmployeeForm.reset();
          this.CloseModal();
          (this.changeActiveProb || this.changeActiveProb == 0) ? //Actions after updating a record
            this.SearchProb(this.changeActiveProb) : this.SearchProb(this.CurrentIndex);
          (postAction === "exit") ? (this.SaveAndExitModal = true) : '';//actions if exit and save is click
          if (name.trim() === "" && postAction == "YesFromDelModal") {//Actions after you deleted a record

            this.DeleteModal = false;
            this.ResetSearch();
            setTimeout(() => {this.SelectProb(0);this.ProbationTable.nativeElement.querySelector('.tr-list').classList.add('colortest') }, 800)
          }
          this.saveSuccessModal = true;
        }
      });
    }
    if (this.willExit) {
      this.router.navigate(['Records/ProbationarySystem']);
    }
  }


  validateRecInput(e) {
    if(e.key.toUpperCase() !== 'Y'){
      e.preventDefault();
    }
  }


  DiscardChanges() {
    this.EmployeeForm.reset();
    this.CloseModal();
    if (this.changeActiveProb || this.changeActiveProb == 0) {
      this.SelectProb(this.changeActiveProb);
    } else {
      this.SelectProb(0);
    }
  }

  DiscardAndExit() {
    const nameCheck = this.EmployeeForm.controls.name.value;
    if (this.isListModified() && nameCheck.trim() == '') {
      this.DeleteModal = true;
      this.willExit = true;
    } else if (this.isListModified()) {
      this.ExitModal = true;
    } else if (!this.isListModified()) {
      this.router.navigate(['Records/ProbationarySystem']);
    }
  }


  CloseDeleteModal() {
    this.DeleteModal = false;
    if (this.willExit) {
      this.router.navigate(['Records/ProbationarySystem']);
    }
  }

  ComputeDates(event: any) {
    let StartDate = "";

    StartDate += event.target.value;

    if (event.target.value.length >= 8) {
      let Day30 = moment(StartDate, 'MM/DD/YY').add(39, 'days').format('MM/DD/YY');
      let Day60 = moment(StartDate, 'MM/DD/YY').add(60, 'days').format('MM/DD/YY');
      let Day90 = moment(StartDate, 'MM/DD/YY').add(90, 'days').format('MM/DD/YY');

      this.EmployeeForm.get("day30").setValue(Day30);
      this.EmployeeForm.get("day60").setValue(Day60);
      this.EmployeeForm.get("day90").setValue(Day90);
    }
    else if (event.target.value.length<1)
    {
      this.EmployeeForm.get("day30").setValue("");
      this.EmployeeForm.get("day60").setValue("");
      this.EmployeeForm.get("day90").setValue("");
    }

  }

}
