import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mv-menu',
  templateUrl: './mv-menu.component.html',
  styleUrls: ['./mv-menu.component.scss']
})
export class MVMenuComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => document.querySelector("input").focus(), 500);
  }

  NavigateTo(NavKey) {
    NavKey = NavKey.replace(/\s/g, "");
    switch (NavKey) {
      case "4":
        this.gotoMoveLetters();
        break;
      case "8":
        this.gotoDisplacementResolution();
        break;
      case "E":
        this.gotoMainMenu();
        break;
    }
    return;
  }

  gotoMainMenu() {
    this.router.navigate(['Mainmenu']);
  }
  gotoMoveLetters() {
    this.router.navigate(['MoveProcessing/MoveLetters']);
  }
  gotoDisplacementResolution() {
    this.router.navigate(['MoveProcessing/DisplacementOrResolutionNotices']);
  }

}
