import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from 'src/app/Services/storage.service';

export enum Employee {
  emp_NO,
  lastname,
  subshift,
  shiftbrk,
  occcode,
  nameinit,
  city,
  deptno,
  vacshift,
  hireda,
  hireyr,
  seniority,
  hiremo,
  workasgn,
  status,
  subdept,
  vacwk,
  vacyr,
  prevdats,
  vacday,
  dat,
  vacmo
}

@Component({
  selector: 'app-edit-vacation',
  templateUrl: './edit-vacation.component.html',
  styleUrls: ['./edit-vacation.component.scss']
})

export class EditVacationComponent implements OnInit {

  EmployeeForm: FormGroup;
  SelectedEmployee: any;

  public constructor(private route: ActivatedRoute, private nav: Location, private LStorage: StorageService) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.SelectedEmployee = params;
    });


    this.EmployeeForm = new FormGroup({
      emp_NO: new FormControl(this.SelectedEmployee.emp_NO, [
        Validators.required
      ]),
      lastname: new FormControl(this.SelectedEmployee.lastname, [
        Validators.required
      ]),
      subshift: new FormControl(this.SelectedEmployee.subshift, [
        Validators.required
      ]),
      shiftbrk: new FormControl(this.SelectedEmployee.shiftbrk, [
        Validators.required
      ]),
      occcode: new FormControl(this.SelectedEmployee.occcode, [
        Validators.required
      ]),
      nameinit: new FormControl(this.SelectedEmployee.nameinit, [
        Validators.required
      ]),
      city: new FormControl(this.SelectedEmployee.city, [
        Validators.required
      ]),
      deptno: new FormControl(this.SelectedEmployee.deptno, [
        Validators.required
      ]),
      vacshift: new FormControl(this.SelectedEmployee.vacshift, [
        Validators.required
      ]),
      hireda: new FormControl(this.SelectedEmployee.hireda, [
        Validators.required
      ]),
      hireyr: new FormControl(this.SelectedEmployee.hireyr, [
        Validators.required
      ]),
      seniority: new FormControl(this.SelectedEmployee.seniority, [
        Validators.required
      ]),
      hiremo: new FormControl(this.SelectedEmployee.hiremo, [
        Validators.required
      ]),
      workasgn: new FormControl(this.SelectedEmployee.workasgn, [
        Validators.required
      ]),
      status: new FormControl(this.SelectedEmployee.status, [
        Validators.required
      ]),
      subdept: new FormControl(this.SelectedEmployee.subdept, [
        Validators.required
      ]),
      vacwk: new FormControl(this.SelectedEmployee.vacwk, [
        Validators.required
      ]),
      vacyr: new FormControl(this.SelectedEmployee.vacyr, [
        Validators.required
      ]),
      prevdats: new FormControl(this.SelectedEmployee.prevdats, [
        Validators.required
      ]),
      vacday: new FormControl(this.SelectedEmployee.vacday, [
        Validators.required
      ]),
      dat: new FormControl(this.SelectedEmployee.dat, [
        Validators.required
      ]),
      vacmo: new FormControl(this.SelectedEmployee.vacmo, [
        Validators.required
      ])
    });

  }

  UpdateLStorage(empID, NewRecord) {
    //UPDATE THE original list and the updated list LIST
    let x, y = 0;
    let VACARRAY = JSON.parse(this.LStorage.get("Updatelist"));
    VACARRAY.forEach(empRec => { (empRec['emp_NO'] == empID) ? VACARRAY.splice(x, 1) : ""; x++; });
    VACARRAY.push(NewRecord);
    let VACALISTRRAY = JSON.parse(this.LStorage.get("VacaList"));
    VACALISTRRAY.forEach(empRec => { (empRec['emp_NO'] == empID) ? VACALISTRRAY.splice(y, 1) : ""; y++; });
    VACALISTRRAY.push(NewRecord);
    this.LStorage.remove("VacaList");
    this.LStorage.set("VacaList", JSON.stringify(VACALISTRRAY));

    return VACARRAY;
  }

  DiscardChanges() {
    //Revert Back the Original State
    this.LStorage.remove("VacaList");
    this.LStorage.remove("Updatelist");
    this.LStorage.set("VacaList", this.LStorage.get("VACALISTBKP"));
  }


  UpdateRecord() {

    let requestBody = {};
    let x = [];
    //DETECT IF THERE ARE CHANGES IN THE RECORD 
    for (let control in this.EmployeeForm.controls) {
      if (this.EmployeeForm.get(control).dirty) {
        requestBody[control] = this.EmployeeForm.get(control).value;
      }
    }

    if (Object.keys(requestBody).length) {
      let fetchFromCache = this.LStorage.get("Updatelist");
      if ((fetchFromCache) !== null) {
        let z = this.UpdateLStorage(this.EmployeeForm.controls.emp_NO.value, this.EmployeeForm.value);
        //  APPEND THE UPDATED RECORD TO THE LOCALSTORAGE
        this.LStorage.remove("Updatelist");
        this.LStorage.set("Updatelist", JSON.stringify(z));
        this.nav.back();
      }
      else {
        //CREATE A BACKUP OF THE VACALIST
        let VACALISTBKP = this.LStorage.get("VacaList");
        this.LStorage.set("VACALISTBKP", VACALISTBKP);
        //ADD THE UPDATED RECORD TO THE LOCALSTORAGE        
        x.push(this.EmployeeForm.value);
        this.LStorage.set("Updatelist", JSON.stringify(x));
        let VACALISTRRAY = JSON.parse(this.LStorage.get("VacaList"));
        VACALISTRRAY.push(this.EmployeeForm.value);
        this.LStorage.remove("VacaList");
        this.LStorage.set("VacaList", JSON.stringify(VACALISTRRAY));
        this.nav.back();
      }
    }
  }


goback(){
  this.nav.back();
}

}

