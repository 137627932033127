/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./r-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../header/header.component.ngfactory";
import * as i3 from "../../header/header.component";
import * as i4 from "../../../Services/auth/user.service";
import * as i5 from "../../../Directives/uppercase.directive";
import * as i6 from "./r-menu.component";
import * as i7 from "@angular/router";
var styles_RMenuComponent = [i0.styles];
var RenderType_RMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RMenuComponent, data: {} });
export { RenderType_RMenuComponent as RenderType_RMenuComponent };
export function View_RMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.UserService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 31, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col-lg-12 no-padd main-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["===== MAINTENANCE OPERATIONS ADMINISTRATION ====="])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["RECORDS SECTION"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["============================================================================"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "line columns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 12, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "p", [["class", "choices"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A01."])), (_l()(), i1.ɵted(-1, null, [" PREBIDS"])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "p", [["class", "choices"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Probationary() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A02."])), (_l()(), i1.ɵted(-1, null, [" PROBATIONARY SYSTEM"])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "p", [["class", "choices exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoMainMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0E."])), (_l()(), i1.ɵted(-1, null, [" EXIT TO MAIN MENU"])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "span", [["class", "line columns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 4, "div", [["class", "col-lg-12 no-padd"], ["name", "form-mainmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [["class", "col3 columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ENTER SELECTION FROM ABOVE MENU: "])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "input", [["autofocus", ""], ["class", "mf-input"], ["maxlength", "1"], ["toUpperCase", ""], ["type", "text"]], null, [[null, "keydown.enter"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 33).onKeyup($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.NavigateTo($event.target.value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 16384, null, 0, i5.UppercaseDirective, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_RMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-r-menu", [], null, null, null, View_RMenuComponent_0, RenderType_RMenuComponent)), i1.ɵdid(1, 4308992, null, 0, i6.RMenuComponent, [i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RMenuComponentNgFactory = i1.ɵccf("app-r-menu", i6.RMenuComponent, View_RMenuComponent_Host_0, {}, {}, []);
export { RMenuComponentNgFactory as RMenuComponentNgFactory };
