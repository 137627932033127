/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gp-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./gp-menu.component";
import * as i3 from "@angular/router";
var styles_GPMenuComponent = [i0.styles];
var RenderType_GPMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GPMenuComponent, data: {} });
export { RenderType_GPMenuComponent as RenderType_GPMenuComponent };
export function View_GPMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Page Unavailable"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Press any key to return to MAIN MENU"]))], null, null); }
export function View_GPMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gp-menu", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GPMenuComponent_0, RenderType_GPMenuComponent)), i1.ɵdid(1, 114688, null, 0, i2.GPMenuComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GPMenuComponentNgFactory = i1.ɵccf("app-gp-menu", i2.GPMenuComponent, View_GPMenuComponent_Host_0, {}, {}, []);
export { GPMenuComponentNgFactory as GPMenuComponentNgFactory };
