import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api-endpoint.service";
var RecordsProbationService = /** @class */ (function () {
    function RecordsProbationService(api) {
        this.api = api;
    }
    RecordsProbationService.prototype.SearchProbation = function (filter, page) {
        return this.api.PostRequest("/records/probation/edit/search", filter, { "page": page });
    };
    RecordsProbationService.prototype.SaveProbRecord = function (Probation) {
        return this.api.PostRequest("/records/probation/edit/save", Probation);
    };
    RecordsProbationService.prototype.FetchFirst = function (filter, page) {
        return this.api.PostRequest("/records/probation/edit/search", filter, { "page": page });
    };
    RecordsProbationService.prototype.FetchLast = function (filter, page) {
        return this.api.PostRequest("/records/probation/edit/search", filter, { "page": page });
    };
    RecordsProbationService.prototype.extractData = function (data) {
        return this.api.extractData(data);
    };
    RecordsProbationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecordsProbationService_Factory() { return new RecordsProbationService(i0.ɵɵinject(i1.APIEndpointService)); }, token: RecordsProbationService, providedIn: "root" });
    return RecordsProbationService;
}());
export { RecordsProbationService };
