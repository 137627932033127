<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">DISPLACEMENT OR RESOLUTION NOTICES</span>
    <span class="page-titlesub"></span>
</div>

<div class="col-lg-12 no-padd">
    <p class="description"><br>This routine will generate Displacement Letter or Resolution Letter.</p>
    <!-- <p class="description">Format for Date and time: yyyy-mm-dd hh.mm.ss.mili</p> -->
    <br>

    <ul class="col-lg-12 no-padd">
        <p class="columns choices-yn" (click) = "Notices(1)"><span class="menu-num">1.</span> Displacement Notices (Abolishment/Bumps)</p>
        <p class="columns choices-yn" (click) = "Notices(2)">
            <span class="menu-num">2.</span> 
            Resolution Notices <br>
            <li class="li-resolution">- Exercise Seniority</li>
            <li class="li-resolution">- Filled Vacancy</li>
            <li class="li-resolution">- Furlough</li>
        </p>
    </ul>

    <div [ngClass]="{'col-lg-12': true, 'no-padd': true, 'form-mainmenu': true, 'marginbottom2': !showInputDate}">
        <span class="columns">PLEASE CHOOSE 1 OR 2:</span>
        <input type="text" class="mf-input" #option autofocus numbersOnly maxlength="1" (keydown.enter)="NavigateTo($event.target.value)" value = {{choice}}>
    </div>

    <div class="col-lg-12 no-padd form-mainmenu" [style.display]="showInputDate ? 'flex' : 'none'">
        <span class="columns">ENTER DATE:</span>
        <div>
            <input type="text" class="mf-input biddate DateTimePlaceholder" #DatePlaceholder appDateFormat disabled value="yyyy-mm-dd" 
            [style.display]="DateInputIE ? 'flex' : 'none'">
            <input type="text" class="mf-input biddate DateTime" #date autofocus appDateFormat (keypress)="DateTimeChecker(1)" (input)="DateTimeChecker(1)" maxlength="10" [placeholder]="isIE() ? '' : 'yyyy-mm-dd'" (paste) = "paste($event, 1)" (click) = Reset(1) >
        </div>
    </div>

    <div class="col-lg-12 no-padd form-mainmenu" [style.display]="showInputDate ? 'flex' : 'none'">
        <span class="columns">ENTER TIME:</span>
        <div>
            <input type="text" class="mf-input biddate DateTime" #time autofocus appTimeFormat (keypress)="DateTimeChecker(2)" (input)="DateTimeChecker(2)" (keydown.enter)="EnterGenerateFile()" maxlength="13" [placeholder]="isIE() ? '' : '00.00.00.0000'" (paste) = "paste($event, 2)" (click) = Reset(2)>
            <input type="text" class="mf-input biddate DateTimePlaceholder" #DatePlaceholder appDateFormat disabled value="00.00.00.0000" 
            [style.display]="TimeInputIE ? 'flex' : 'none'">
        </div>
    </div>

    <div class="btn-holder">
        <button [ngClass]="{'btn-save':HasValidInputs, 'btn-disable':!HasValidInputs}" [disabled]="!HasValidInputs" (click)="GenerateFile()" #ProceedBtn>Proceed</button>
        <button class="btn-cancel" (click)="gotoMoveProcessing()">CANCEL</button>
    </div>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">{{ content }}<span class="loader" [style.display]="OnProcess ? 'inline-block' : 'none'"> </span></p>
                    <p class="modal-time">{{ timee }}</p>
                    <p class="modal-count">{{ count }}</p>
                    <p class="modal-desc2">{{ ques }}</p>
                    <div class="btn-holder" [style.display]="ActivateDownload ? 'block' : 'none'">
                        <button class="btn btn-download" (click)='DownloadFile()' #DownloadBtn>Download</button>
                        <button class="btn btn-exit" (click)='gotoMoveProcessing()'>Exit</button>
                    </div>
                    <div class="btn-holder" [style.display]="ActivateExit ? 'block' : 'none'">
                        <button class="btn btn-exit" (click)='HideErrorPrompt()' #ErrorOKBtn>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="errorModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">Invalid date. Please ensure that the date exist.</p>
                    <div class="btn-holder">
                        <button class="btn btn-exit" (click)='HideErrorDateModal()'>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>