import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ɵɵresolveBody } from '@angular/core';
import { HeadcountService } from '../../../../Services/headcount.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { StorageService } from 'src/app/Services/storage.service';

export enum Employee {
  emp_NO,
  lastname,
  subshift,
  shiftbrk,
  occcode,
  nameinit,
  city,
  deptno,
  vacshift,
  hireda,
  hireyr,
  seniority,
  hiremo,
  workasgn,
  status,
  subdept,
  vacwk,
  vacyr,
  prevdats,
  vacday,
  dat,
  vacmo
}

@Component({
  selector: 'app-edit-vacation-list',
  templateUrl: './edit-vacation-list.component.html',
  styleUrls: ['./edit-vacation-list.component.scss']
})

export class EditVacationListComponent implements OnInit {

  @ViewChild('EmpListRecTable', {static: false}) EmpListRecTable: ElementRef;
  @ViewChild('vacwk_id', {static: false}) vacwk_id: ElementRef;
  @ViewChild('dat_id', {static: false}) dat_id: ElementRef;
  @ViewChild('empno_id', {static: false}) empno_id: ElementRef;
  EmployeeForm: FormGroup;
  SelectedEmployee: any;

  public SlctedEmpNo: number;
  public SlctedCacheId: string;

  constructor(private headService: HeadcountService, private Aroute: Router, private LStorage: StorageService) { }

  NameInit = "";
  EmpList: Array<any>;
  EmpNo = "";
  LName = "";
  SearchEmpRes: Object;
  showModal: boolean;
  saveSuccessModal = false;
  hasSearchResults: boolean;
  showPaginationModal: boolean;
  showSearchModal: boolean;
  showExitModal: boolean;
  enableSaveBtn: boolean;
  firstPage: boolean;
  lastPage: boolean;
  content: string;
  searchWarning: string;
  pagination: string;
  isLoading=true;
  totalPages: string;
  last: boolean;
  first: boolean;
  CurrentPage: string;
  VCurrentPage: string;
  timer;
  vacwk_decimal;
  dat_realval;

  InitializeForm(){
    this.EmployeeForm = new FormGroup({});
   
    this.EmployeeForm = new FormGroup({
      cacheId: new FormControl("", [Validators.required]),
      deptno: new FormControl("", [Validators.required]),
      shiftbrk: new FormControl("", [Validators.required]),
      subshift: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      hireda: new FormControl("", [Validators.required]),
      emp_NO: new FormControl("", [Validators.required]),
      status: new FormControl("", [Validators.required]),
      hireyr: new FormControl("", [Validators.required]),
      workasgn: new FormControl("", [Validators.required]),
      hiremo: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      nameinit: new FormControl("", [Validators.required]),
      seniority: new FormControl("", [Validators.required]),
      occcode: new FormControl("", [Validators.required]),
      vacmo: new FormControl("", [Validators.required]),
      subdept: new FormControl("", [Validators.required]),
      vacwk: new FormControl("", [Validators.required]),
      dat: new FormControl("", [Validators.required]),
      vacday: new FormControl("", [Validators.required]),
      vacyr: new FormControl("", [Validators.required]),
      prevdats: new FormControl("", [ Validators.required]),
      vacshift: new FormControl("", [Validators.required])
    });
  }


  ngOnInit(): void {
    this.InitializeForm();
    if (this.LStorage.get("VacaList") != null) {
      this.EmpList = JSON.parse(this.LStorage.get("VacaList"))
      this.totalPages = this.LStorage.get("TotalPages");
      this.CurrentPage = this.LStorage.get("CurrentPage");
      this.VCurrentPage = ((parseInt(this.CurrentPage)) + 1).toString();
      this.selectDefaultEmp();
      this.hasSearchResults = true;     
      this.isLoading = false; 
      //CREATE A BACKUP OF THE VACALIST
      let VACALISTBKP = this.LStorage.get("VacaList");
      this.LStorage.set("VACALISTBKP", VACALISTBKP);
    }
    else {
      this.GetAllVacaList(true);
      // this.CurrentPage = this.CurrentPage ?? "0";
      if(!this.CurrentPage) { this.CurrentPage = "0" }
      this.VCurrentPage = ((parseInt(this.CurrentPage)) + 1).toString();
    }

    this.enableSaveBtn = this.ListHasChanged();
    
    if (parseInt(this.CurrentPage) == 0) this.first = true;

    window.addEventListener("keydown", function (event) { if (event.key == "F3") event.preventDefault(); });
    const onClick = (e) => { if (e.key == "F3") this.CheckExit(); };
    window.addEventListener('keydown', onClick);
    setTimeout(() => { this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.add('colortest') }, 1200)
  }

  modalSaveChanges() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.DiscardChanges(0);
  }

  closeSaveSuccessModal() {
    this.saveSuccessModal = false;
  }

  gotoVacationMenu() {
    this.Aroute.navigate(['Headcount/VacationProject']);
  }

  ListHasChanged(): boolean {
    let FetchedCache = this.LStorage.get("Updatelist");
    return (FetchedCache != null) ? true : false
  }

  SelectEmp(index) {
    this.ViewVarMapper(index);
    this.EmployeeForm.setValue(this.SelectedEmployee);
    this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.remove('colortest');
    this.SlctedEmpNo = this.ExtractEmployee(index)['emp_NO'];
    if (index == 0) this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.add('colortest');
  }

  selectDefaultEmp() {
    try {
      this.SelectedEmployee = this.ExtractEmployee(0);
      this.EmployeeForm.setValue(this.SelectedEmployee);  
    }catch(ex){}
  }

  SearchEmp() {
    try{
    if(this.ListHasChanged() && this.isRecordEdited()){
      this.showSearchModal=true;
    }
    else{
      this.isLoading = true;
      if (this.EmpNo || this.LName || this.NameInit) {
        this.headService.SearchEmpRecord({ emp_no: this.EmpNo, lastname: this.LName, nameinit: this.NameInit },0).subscribe(response => {
          let res = this.headService.extractData(response);   

          console.log(parseInt(res["totalElements"])>0);
          this.EmpList = res["content"];
            if(parseInt(res["totalElements"])>0) {
              this.totalPages = res["totalPages"];
              this.LStorage.set("CurrentPage", "0");
              this.VCurrentPage = "1";
              this.ViewVarMapper(0);
              this.EmployeeForm.setValue(this.SelectedEmployee);
              this.hasSearchResults = true;
              //Sets Local Storage to the new set
              this.LStorage.set("VACALISTBKP", JSON.stringify(this.EmpList));
              this.LStorage.set("VacaList", JSON.stringify(this.EmpList));
              //Sets first row for focus
              this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.remove('colortest');
              this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.add('colortest');

              // Previous and Next
              if (parseInt(this.totalPages) == 1) {
                this.first = true;
                this.last = true;
              }
              else if (parseInt(this.totalPages) > 1) this.last = false;
              if (parseInt(this.VCurrentPage) == 1) this.first = true;
            }
            else {
              this.hasSearchResults = false;
              this.clearInputFields();            
            }
            this.isLoading = false;

          }, error => {
            console.log(error);
          })
        } else
        {
          this.LStorage.set("CurrentPage", "0");
          this.GetAllVacaList(true);
          //Sets first row for focus
          this.clearInputFields();
          this.ViewVarMapper(0);
          this.EmployeeForm.setValue(this.SelectedEmployee);
          this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.remove('colortest');
          this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.add('colortest');
        }

      }
    } catch (Ex) { console.log(Ex.message) }
  }
  
  ResetSearch() {
    this.EmpNo = '';
    this.LName = '';
    this.NameInit = '';
    this.LStorage.set("CurrentPage", "0");
    this.SearchEmp();
  }

  ExtractEmployee(index) {
    return this.EmpList[index];
  }

  NextPage(){
    if(this.ListHasChanged() && this.isRecordEdited()){
      this.pagination = "next";
      this.showPaginationModal=true;
    }
    else {
      this.isLoading = true;
      let currntPage = this.LStorage.get("CurrentPage");
      this.headService.FetchVacaList({ emp_no: this.EmpNo, lastname: this.LName, nameinit: this.NameInit }, (parseInt(currntPage) + 1) | 0).subscribe(response => {
        let res = this.headService.extractData(response);   
        this.EmpList = res["content"];
        this.first = res["first"];
        this.last = res["last"];
        this.LStorage.remove("CurrentPage");
        this.CurrentPage = res["number"];
        this.VCurrentPage = ((parseInt(this.CurrentPage)) + 1).toString();
        this.LStorage.set("CurrentPage", JSON.stringify(parseInt(currntPage) + 1));
        //Sets Local Storage to the new set
        this.LStorage.set("VACALISTBKP", JSON.stringify(this.EmpList));
        this.LStorage.set("VacaList", JSON.stringify(this.EmpList));
        //Sets first row for focus
        this.ViewVarMapper(0);
        this.EmployeeForm.setValue(this.SelectedEmployee);
        this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.remove('colortest');
        this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.add('colortest')
        this.isLoading = false;
      });
    }
  }

  PreviousPage(){
    if(this.ListHasChanged() && this.isRecordEdited()){
      this.pagination = "previous";
      this.showPaginationModal=true;
    }
    else {
      this.isLoading = true;
      let currntPage = this.LStorage.get("CurrentPage");
      let PageNo = ((parseInt(currntPage) - 1) == 0) ? 0 : (parseInt(currntPage) - 1);
      this.headService.FetchVacaList({ emp_no: this.EmpNo, lastname: this.LName, nameinit: this.NameInit }, PageNo).subscribe(response => {
        let res = this.headService.extractData(response);   
        this.EmpList = res["content"];
        this.first = res["first"];
        this.last = res["last"];
        this.CurrentPage = res["number"];
        this.VCurrentPage = ((parseInt(this.CurrentPage)) + 1).toString();
        this.LStorage.remove("CurrentPage");
        this.LStorage.set("CurrentPage", JSON.stringify(parseInt(currntPage) - 1));
        //Sets Local Storage to the new set
        this.LStorage.set("VACALISTBKP", JSON.stringify(this.EmpList));
        this.LStorage.set("VacaList", JSON.stringify(this.EmpList));
        //Sets first row for focus
        this.ViewVarMapper(0);
        this.isLoading = false;
        this.EmployeeForm.setValue(this.SelectedEmployee);
        this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.remove('colortest');
        this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.add('colortest')
      });
    }
  }

  GetAllVacaList(autoSelect?: boolean) {
    // let currntPage = this.LStorage.get("CurrentPage") ?? "0";
    let currntPage = "";
    if(this.LStorage.get("CurrentPage")) { currntPage = this.LStorage.get("CurrentPage") }
    else  currntPage = "0";
    
    // this.headService.FetchVacaList({ emp_no: this.EmpNo, lastname: this.LName, nameinit: this.NameInit }, currntPage ?? "0").subscribe(response => {
    this.headService.FetchVacaList({ emp_no: this.EmpNo, lastname: this.LName, nameinit: this.NameInit }, currntPage).subscribe(response => {
      let res = this.headService.extractData(response);    
      this.LStorage.set("VacaList", JSON.stringify(res["content"]));
      this.LStorage.set("TotalPages", JSON.stringify(res["totalPages"]));
      this.EmpList = res["content"];
      this.totalPages = res["totalPages"];
      this.LStorage.set("CurrentPage", "0");
      this.VCurrentPage = ((parseInt(currntPage)) + 1).toString();
      this.first = res["first"];
      this.last = res["last"];
      (autoSelect) ? this.selectDefaultEmp() : ';'
      //CREATE A BACKUP OF THE VACALIST
      let VACALISTBKP = this.LStorage.get("VacaList");
      this.LStorage.set("VACALISTBKP", VACALISTBKP);
      this.isLoading = false;
      this.hasSearchResults=true;
    },
      error => { console.log(error); }
    );
  }

  DiscardChanges(x) {
    //Revert Back the Original State
    this.LStorage.remove("VacaList");
    this.LStorage.remove("Updatelist");
    this.LStorage.set("VacaList", this.LStorage.get("VACALISTBKP"));
    this.showModal = false;
    this.showPaginationModal = false;
    this.showSearchModal = false;
    
    this.GetAllVacaList(true)
    this.CurrentPage = "0";
    this.VCurrentPage = ((parseInt(this.CurrentPage)) + 1).toString();
    this.SelectEmp(0);

    if (x == 1) { //Pagination
      if(this.pagination === "next") this.NextPage();
      else if(this.pagination === "previous") this.PreviousPage();
    }
    else if (x == 2) { //Search
      this.GetAllVacaList(true);
      this.SearchEmp();
    }
    else if (x == 3) { //Exit
      this.LStorage.remove("VacaList");
      this.LStorage.remove("Updatelist");
      this.LStorage.remove("CurrentPage");
      this.LStorage.remove("VACALISTBKP");
      this.LStorage.remove("TotalPages");
      this.Aroute.navigate(['Headcount/VacationProject']);
    }

    this.enableSaveBtn = false;
  }

  SaveChanges(modalcall) {
    let FetchedCache = this.LStorage.get("Updatelist");
    let x = [];
    if (FetchedCache != null) {
      x.unshift(JSON.parse(FetchedCache));
      this.headService.SaveEmpRecord(x[0]).subscribe(() => { }, res => {
        this.saveSuccessModal = true;
      })
      this.LStorage.remove("Updatelist");
    }
    if(modalcall == 1){ // Pagination
      this.showPaginationModal = false;
      if(this.pagination === "next") this.NextPage();
      else if(this.pagination === "previous") this.PreviousPage();
    }
    else if(modalcall == 2){// Search
      this.showSearchModal = false;
      this.GetAllVacaList(true);
      this.SearchEmp();
    }
    else if(modalcall == 3){ //Exit
      this.showExitModal = false;
      this.LStorage.remove("VacaList");
      this.LStorage.remove("Updatelist");
      this.LStorage.remove("CurrentPage");
      this.LStorage.remove("VACALISTBKP");
      this.LStorage.remove("TotalPages");
      this.enableSaveBtn = false;
      this.gotoVacationMenu();
    }
    this.showModal = false;
    this.enableSaveBtn = this.ListHasChanged();
  }

  SaveChangesExit() {
    let FetchedCache = this.LStorage.get("Updatelist");
    let x = [];
    if (FetchedCache != null) {
      x.unshift(JSON.parse(FetchedCache));
      this.headService.SaveEmpRecord(x[0]).subscribe(Response => {
      }, onError => {
        this.saveSuccessModal = true;
        this.LStorage.remove("VacaList");
        this.LStorage.remove("VACALISTBKP");
      })
      this.LStorage.remove("Updatelist");
    }
    this.showExitModal = false;
    this.LStorage.remove("VacaList");
    this.LStorage.remove("Updatelist");
    this.LStorage.remove("CurrentPage");
    this.LStorage.remove("VACALISTBKP");
    this.LStorage.remove("TotalPages");
    this.enableSaveBtn = false;
    this.gotoVacationMenu();
  }

  UpdateLStorage(cacheId, NewRecord) {
    //UPDATE THE original list and the updated list LIST
    let x = 0, y = 0;
    // let VACARRAY = JSON.parse(this.LStorage.get("Updatelist")) ?? [];
    let VACARRAY;
    if(JSON.parse(this.LStorage.get("Updatelist"))) VACARRAY = JSON.parse(this.LStorage.get("Updatelist"));
    else VACARRAY = [];

    if (VACARRAY) {
      VACARRAY.forEach(empRec => { (empRec['cacheId'] == cacheId) ? VACARRAY.splice(x, 1) : ""; x++; });
      VACARRAY.unshift(NewRecord);
    }

    let VACALISTRRAY = JSON.parse(this.LStorage.get("VacaList"));
    VACALISTRRAY.forEach(empRec => {
      (empRec['cacheId'] == cacheId) ? VACALISTRRAY[y] = NewRecord : ''; y++;
    });
    this.LStorage.remove("VacaList");
    this.LStorage.set("VacaList", JSON.stringify(VACALISTRRAY));
    return VACARRAY;
  }

  UpdateRecord(field) {
    if(field == 2 && this.hasSearchResults){
      let vacwk_value = this.vacwk_id.nativeElement.value;
      let dat_value = this.dat_id.nativeElement.value;
      this.vacwk_decimal = parseFloat(vacwk_value).toFixed(1);

      if (isNaN(this.vacwk_decimal)) {
        this.vacwk_decimal = "0.0";
        dat_value = "NO";
      }
      else {
        if (this.vacwk_decimal >= 10 && this.vacwk_decimal < 100) this.vacwk_decimal = (this.vacwk_decimal / 10).toFixed(1);
        else if (this.vacwk_decimal >= 100) this.vacwk_decimal = (this.vacwk_decimal / 100).toFixed(1);

        if (this.vacwk_decimal >= 1.0) dat_value = "YES";
        else dat_value = "NO";
      }
      this.dat_realval = dat_value;
      this.vacwk_id.nativeElement.value = this.vacwk_decimal;
      this.EmployeeForm.controls.vacwk.setValue(this.vacwk_decimal);
      this.EmployeeForm.controls.dat.setValue(this.dat_realval);
    }

    let requestBody = {};
    let x = [];
    let currntPage = this.LStorage.get("CurrentPage");

    //DETECT IF THERE ARE CHANGES IN THE RECORD 
    for (let control in this.EmployeeForm.controls) {
      if (this.EmployeeForm.get(control).dirty) {
        requestBody[control] = this.EmployeeForm.get(control).value;
      }
    }
    if (Object.keys(requestBody).length) {
      let fetchFromCache = this.LStorage.get("Updatelist");
      if ((fetchFromCache) !== null) {
        let z = this.UpdateLStorage(this.EmployeeForm.controls.cacheId.value, this.EmployeeForm.value);
        //  APPEND THE UPDATED RECORD TO THE LOCALSTORAGE
        this.LStorage.remove("Updatelist");
        this.LStorage.set("Updatelist", JSON.stringify(z));
      }
      else {
        this.UpdateLStorage(this.EmployeeForm.controls.cacheId.value, this.EmployeeForm.value);
        x.push(this.EmployeeForm.value);
        //ADD THE UPDATED RECORD TO THE LOCALSTORAGE
        this.LStorage.set("Updatelist", JSON.stringify(x));
      }
      this.EmpList = JSON.parse(this.LStorage.get("VacaList"))
      this.EmpListRecTable.nativeElement.querySelector('.tr-list').classList.add('colortest');
      this.enableSaveBtn=this.isRecordEdited()
    }
  }

  CheckExit() {
    if (this.ListHasChanged()) {
      this.showExitModal = true;
    }
    else {
      this.gotoVacationMenu();
      this.LStorage.remove("VacaList");
      this.LStorage.remove("Updatelist");
      this.LStorage.remove("CurrentPage");
      this.LStorage.remove("VACALISTBKP");
      this.LStorage.remove("TotalPages");
      this.enableSaveBtn = false;
    }
  }

  ViewVarMapper(index) {
    let tempExtracteEmp = this.ExtractEmployee(index);
    this.SelectedEmployee = tempExtracteEmp;
    this.SlctedEmpNo = tempExtracteEmp['emp_NO'];
    this.SlctedCacheId = tempExtracteEmp['cacheId'];
  }

  clearInputFields() {
    this.EmployeeForm.reset();
  }

  isRecordEdited(){
    let x,y = 0;
    // let updateList = JSON.parse(this.LStorage.get("VacaList"))??[];
    let updateList;
    if(JSON.parse(this.LStorage.get("VacaList"))) updateList = JSON.parse(this.LStorage.get("VacaList"));
    else updateList = [];

    // let backupList = JSON.parse(this.LStorage.get("VACALISTBKP"))??[];
    let backupList;
    if(JSON.parse(this.LStorage.get("VACALISTBKP"))) backupList = JSON.parse(this.LStorage.get("VACALISTBKP"));
    else backupList = [];

    let what = false;

    for(x=0; x < backupList.length ; x++ ){
      for(y=0; y < updateList.length; y++ ){
        if(backupList[x].cacheId == updateList[y].cacheId) {
          if(backupList[x].vacshift === updateList[y].vacshift &&
            backupList[x].lastname === updateList[y].lastname &&
            backupList[x].city === updateList[y].city &&
            backupList[x].deptno === updateList[y].deptno &&
            backupList[x].shiftbrk === updateList[y].shiftbrk &&
            backupList[x].subshift === updateList[y].subshift &&
            backupList[x].occcode === updateList[y].occcode &&
            backupList[x].status === updateList[y].status &&
            backupList[x].emp_NO === updateList[y].emp_NO &&
            backupList[x].nameinit === updateList[y].nameinit &&
            backupList[x].vacyr === updateList[y].vacyr &&
            backupList[x].workasgn === updateList[y].workasgn &&
            backupList[x].seniority === updateList[y].seniority &&
            backupList[x].hiremo === updateList[y].hiremo &&
            backupList[x].hireda === updateList[y].hireda &&
            backupList[x].subdept === updateList[y].subdept &&
            backupList[x].vacmo === updateList[y].vacmo &&
            backupList[x].hireyr === updateList[y].hireyr &&
            backupList[x].vacday === updateList[y].vacday &&
            backupList[x].vacwk === updateList[y].vacwk &&
            backupList[x].dat === updateList[y].dat &&
            backupList[x].prevdats === updateList[y].prevdats) {
            what = false;
          }
          else{ what = true;}
        }
      }
      if(what == true) break;
      else what = false;
    }
    return what;
  }

  FocusVacWk() {
    this.vacwk_id.nativeElement.focus();
  }

  DownloadVacaEditFile() {
    this.headService.DownloadVacationEdit('/headcount/vacation/edit/download', 'VACAEDIT');
    this.gotoVacationMenu();
  }

  unfocus(e){
    if(e.keyCode == 8)
      event.preventDefault ? event.preventDefault() : (event.returnValue = false);
  }
}
