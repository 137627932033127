import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeadcountService } from '../../../../Services/headcount.service';
import {Router} from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { APIEndpointService } from 'src/app/Services/api-endpoint.service';

@Component({
  selector: 'app-create-vacation-file',
  templateUrl: './create-vacation-file.component.html',
  styleUrls: ['./create-vacation-file.component.scss']
})
export class CreateVacationFileComponent implements OnInit, AfterViewInit {

  FetchedData;
  subscription: Subscription;
  subcatcher: Subscription;
  vacafileFF = [];
  showModal: boolean;
  showModalButton: boolean;
  showExitButton: boolean;
  OnProcess: boolean;
  content: string;
  time: string;
  count: string;
  ques: string;
  realTime;
  realCount;
  counter: number;
  extractResult: {
    numberOfRecordsEx: any,
    responseTime: any,
  };

  constructor(private headCountService: HeadcountService, private route:ActivatedRoute, private router:Router, private apiService: APIEndpointService) { }

  ngOnInit() {}
  
  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }
  gotoMainMenu() {
    this.router.navigate(['']);
  }
  gotoVacationMenu() {
    this.router.navigate(['Headcount/VacationProject']);
    this.showModal = false;
  }
  vacafileffDownload() {
    this.headCountService.getCreateVacaFile();
  }

  NavigateTo(NavKey) {
    switch (NavKey) {
      case "N":
        this.gotoVacationMenu();
        break;
      case "Y":
        this.show();
        break;
    }
    return;
  }

  extractVacafile() {
    this.showModal = true;
    this.showModalButton = true;
    this.content = "Process will be completed approximately 5mins.";
    this.apiService.GetRequest('/headcount/vacation/create/extract', 'VACAFILE')
      .subscribe(
        (response) => {
          if(response.ok) {
            const { numberOfRecordsEx, responseTime } = response.body;
            this.extractResult = {
              numberOfRecordsEx,
              responseTime,
            };
            this.OnProcess = false;
          } else {

            this.showError();
          }
        },
        (error) => {
          this.showError();
        });
  }

  show() {
    this.showModal = true;
    this.content = 'The system is creating the vacation file...';
    this.OnProcess = true;
    this.extractVacafile();
  }

  showError() {
    this.content = "The system failed to create the file. Please try again later."; 
    // this.showExitButton = true;
    this.OnProcess = false;
  }

  createVacafile() {
    this.apiService.DownloadFile('/headcount/vacation/create/download', 'VACAFILE')
    this.closeVacaCreate();
  }

  closeVacaCreate() {
    this.showModal = false;
    this.showModal = false;
    this.showModalButton = false;
    this.showExitButton = false;
  }
}
