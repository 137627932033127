<!-- <app-header></app-header>
<div class="col-lg-12 no-padd">
    <span class="page-title">EDITING VACATION FILE</span>
    <span class="page-titlesub">VACAFILE</span>
    <br><br>
</div> -->

<div class="col-lg-12 no-padd" ><span class="line columns"> </span></div>
<form [formGroup]="EmployeeForm">
<table class="table" >
     <tr>
          <td> <span class="label">DEPTNO:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-1 no-pad"
               formControlName="deptno" ></td>

          <td> <span class="label">CITY:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-2 no-pad" formControlName="city" toUpperCase></td>

          <td> <span class="label">OCCCODE:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-3 no-pad" formControlName="occcode" toUpperCase></td>
     </tr>
     <tr>
          <td> <span class="label">EMP_NO:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-1 no-pad" readonly formControlName="emp_NO" toUpperCase></td>

          <td> <span class="label">NAMEINIT:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-2 no-pad" formControlName="nameinit" toUpperCase></td>
     </tr>
     <tr>
          <td> <span class="label">LASTNAME:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-1 no-pad" formControlName="lastname" toUpperCase></td>
     </tr>
     <tr>
          <td> <span class="label">WORKASGN:</span></td>
          <td colspan="3"><input type="text" class="mf-input mf-input-edit-1 no-pad" formControlName="workasgn" toUpperCase></td>

          <td> <span class="label">SHIFTBRK:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-3 no-pad" numeric formControlName="shiftbrk" toUpperCase></td>
     </tr>
     <tr>
          <td> <span class="label">SUBSHIFT:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-1 no-pad" formControlName="subshift" toUpperCase></td>

          <td> <span class="label">SENIORITY:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-2 no-pad" numeric formControlName="seniority" toUpperCase></td>


          <td> <span class="label">HIREMO:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-3 no-pad" numeric formControlName="seniority" toUpperCase></td>
     </tr>

     <tr>
          <td> <span class="label">HIREDAY:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-1 no-pad" formControlName="hireda" toUpperCase></td>

          <td> <span class="label">HIREYR:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-2 no-pad" formControlName="hireyr" toUpperCase></td>

          <td> <span class="label">STATUS:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-3 no-pad" numeric formControlName="status" toUpperCase></td>
     </tr>

     <tr>
          <td> <span class="label">SUBDEPT:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-1 no-pad" formControlName="subdept" toUpperCase></td>

          <td> <span class="label">VACMO:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-2 no-pad" numeric formControlName="vacmo" toUpperCase></td>

          <td> <span class="label">VACDAY:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-3 no-pad" numeric formControlName="vacday" toUpperCase></td>
     </tr>

     <tr>
          <td> <span class="label">VACYR:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-1 no-pad" formControlName="vacyr" toUpperCase></td>

          <td> <span class="label">VACSHIFT:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-2 no-pad" formControlName="vacshift" toUpperCase></td>

          <td> <span class="label">PREVDATS:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-3 no-pad" formControlName="prevdats" toUpperCase></td>
     </tr>
</table>
<div class="pull-right">
     <button class="btn-submit" (click)=UpdateRecord()>DONE</button>
     <button class="btn-cancel" (click)=goback()>CANCEL</button>
</div>
</form>
