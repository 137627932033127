import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule,HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';  
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { HCReportFunctionComponent } from './components/Headcount/hc-report-function/hc-report-function.component';
import { HCMenuComponent } from './components/Headcount/hc-menu/hc-menu.component';
import { HCVacationProjectComponent } from './components/Headcount/hc-vacation-project/hc-vacation-project.component';
import { MVMenuComponent } from './components/MoveProcessing/mv-menu/mv-menu.component';
import { RMenuComponent } from './components/Records/r-menu/r-menu.component';
import { GPMenuComponent } from './components/GeneralPersonnel/gp-menu/gp-menu.component';
import { CreateVacationFileComponent } from './components/Headcount/hc-vacation-project/create-vacation-file/create-vacation-file.component';
import { EditVacationComponent } from './components/Headcount/hc-vacation-project/edit-vacation/edit-vacation.component';
import { ProduceInactiveListComponent } from './components/Headcount/hc-vacation-project/produce-inactive-list/produce-inactive-list.component';
import { ProduceRotatingEmpListComponent } from './components/Headcount/hc-vacation-project/produce-rotating-emp-list/produce-rotating-emp-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { NumberOnlyDirective } from './Directives/number-only.directive';
import { EditVacationListComponent } from './components/Headcount/hc-vacation-project/edit-vacation-list/edit-vacation-list.component';
import { InitialVacRosterComponent } from './components/Headcount/hc-vacation-project/initial-vac-roster/initial-vac-roster.component';
import { FinalVacRosterComponent } from './components/Headcount/hc-vacation-project/final-vac-roster/final-vac-roster.component';
import { ProdVacCalcAComponent } from './components/Headcount/hc-vacation-project/prod-vac-calc-a/prod-vac-calc-a.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxLocalStorageModule} from 'ngx-localstorage';
import { HttpErrorInterceptor } from './Services/interceptor.service';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { ProdVacCalcBComponent } from './components/Headcount/hc-vacation-project/prod-vac-calc-b/prod-vac-calc-b.component';
import { BooleanInputOnlyDirective } from './Directives/boolean-input-only.directive';
import { MoveLettersComponent } from './components/MoveProcessing/move-letters/move-letters.component';
import { ProbationaryMenuComponent } from './components/Records/probationary-menu/probationary-menu.component';
import { EditProbationaryComponent } from './components/Records/probationary-menu/edit-probationary/edit-probationary.component';
import { PrintProbationaryDueComponent } from './components/Records/probationary-menu/print-probationary-due/print-probationary-due.component';
import { PSEnterNewRecordsComponent } from './components/Records/probationary-menu/ps-enter-new-records/ps-enter-new-records.component';
import { ExitComponent } from './components/exit/exit.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { DateInputDirective } from './Directives/date-input.directive';
import { DisplacementOrResolutionComponent } from './components/MoveProcessing/displacement-or-resolution/displacement-or-resolution.component';
import { TimeFormatDirective } from './Directives/time-format.directive';
import { DownloadBasicShiftComponent } from './components/Headcount/hc-vacation-project/download-basic-shift/download-basic-shift.component';
import { DateFormatDirective } from './Directives/date-format.directive';
import { UppercaseDirective } from './Directives/uppercase.directive';

//Service
import { AuthenticationService } from './Services/auth/auth.service';
import { AuthenticationGuardService } from './Services/auth/auth-guard.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './Services/storage.service';
import { UserService } from './Services/auth/user.service';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainMenuComponent,
    HCReportFunctionComponent,
    HCMenuComponent,
    HCVacationProjectComponent,
    MVMenuComponent,
    RMenuComponent,
    GPMenuComponent,
    CreateVacationFileComponent,
    EditVacationComponent,
    ProduceInactiveListComponent,
    ProduceRotatingEmpListComponent,
    FooterComponent,
    NumberOnlyDirective,
    EditVacationListComponent,
    InitialVacRosterComponent,
    FinalVacRosterComponent,
    ProdVacCalcAComponent,
    ProdVacCalcBComponent,
    BooleanInputOnlyDirective,
    MoveLettersComponent,
    ProbationaryMenuComponent,
    EditProbationaryComponent,
    PrintProbationaryDueComponent,
    PSEnterNewRecordsComponent,
    ExitComponent,
    UnauthorizedComponent,
    DateInputDirective,
    DisplacementOrResolutionComponent,
    TimeFormatDirective,
    DownloadBasicShiftComponent,
    DateFormatDirective,
    UppercaseDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxLocalStorageModule.forRoot()
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  {
    provide : LocationStrategy ,
    useClass: HashLocationStrategy
  },
  AuthenticationService,
  AuthenticationGuardService,
  JwtHelperService,
  StorageService,
  UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
