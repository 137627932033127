import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { APIEndpointService } from '../Services/api-endpoint.service'

@Injectable({
  providedIn: 'root'
})
export class MoveprocessingService {

  constructor(private api: APIEndpointService) { }

  awardsmonday;
  reawards;
  awardsthursday;
  displacement;
  resolution;
  private awardsmondayListener = new Subject();
  private reawardsListener = new Subject();
  private awardsthursdayListener = new Subject();
  private displacementListener = new Subject();
  private resolutionListener = new Subject();

  getAwardsMonday(bidDateJSON): Observable<object> {
    return this.api.PostRequest("/move/MoveAwards3/extract", bidDateJSON);
  }

  getReawards(bidDateJSON) {
    return this.api.PostRequest("/moves/MoveReAward/extract", bidDateJSON);
  }
  
  getAwardsThursday(bidDateJSON) {
    return this.api.PostRequest("/move/MoveAwards5/extract", bidDateJSON);
  }

  getDisplacement(DateTimeJSON) {
    return this.api.PostRequest("/move/displacement/notice/extract", DateTimeJSON);
  }

  getResolution(DateTimeJSON) {
    return this.api.PostRequest("/move/notices/resolution/extract", DateTimeJSON);
  }

  DownloadMoveLettersFile(Url,FileName){
    this.api.DownloadFile(Url,FileName);
  }

  DownloadNotices(Url,FileName){
    this.api.DownloadFile(Url,FileName);
  }

  extractData(data){
   return this.api.extractData(data);
  }
}
