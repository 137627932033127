import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/Services/auth/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss']
})
export class ExitComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private usrService: UserService,
    private router: Router) {
    console.log('constructor AppExit');
  }

  ngOnInit() {
    console.log('Exit - Logout SSO');
    this.usrService.clearUser();
    const auth_api = environment.auth_api;
    window.top.location.href = auth_api + 'logout';
  }

}
