import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeadcountService } from '../../../../Services/headcount.service';
import {Router} from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-basic-shift',
  templateUrl: './download-basic-shift.component.html',
  styleUrls: ['./download-basic-shift.component.scss']
})
export class DownloadBasicShiftComponent implements OnInit {
  
  @ViewChild('Exit', {static: false}) Exit: ElementRef;
  @ViewChild('YN', {static: false}) YN: ElementRef;
  showModalButton: boolean;
  showModal: boolean;
  subscription: Subscription;
  subcatcher: Subscription;
  basicshiftFF = [];
  showExitButton: boolean;
  OnProcess: boolean;
  content: string;
  time: string;
  count: string;
  ques: string;
  realTime;
  realCount;
  counter: number;

  constructor(private headCountService: HeadcountService, private route:ActivatedRoute, private router:Router) { }

  ngOnInit() {
    this.subscription = this.headCountService.basicshiftFFListener().subscribe((data: any)=> {
      this.basicshiftFF.push(data);
      this.realCount = this.basicshiftFF[0].numberOfRecordsEx;
      this.realTime = this.basicshiftFF[0].responseTime;
      }, error => (console.log(error)) );
  }
  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }

  NavigateTo(NavKey) {
    switch (NavKey) {
      case "N":
        this.gotoVacationMenu();
        break;
      case "Y":
        this.checkvalue();
        break;
    }
    return;
  }

  checkvalue() {
    this.headCountService.downloadBasicShift();
    this.YN.nativeElement.blur();
    this.showModal = true;
    this.showModalButton = true;
        this.subcatcher && this.subcatcher.unsubscribe();
        this.content = "Process will be completed approximately 5mins."
        this.OnProcess = false        
  }

  ngOnDestroy() {
    this.subcatcher && this.subcatcher.unsubscribe();
  }

  gotoVacationMenu() {
    this.showModal = false;
    this.showModalButton = false;
    this.router.navigate(['Headcount/VacationProject']);
  }

}
