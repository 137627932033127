<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">PRODUCING A LISTING OF INACTIVE EMPLOYEES</span>
    <span class="page-titlesub">VACAINAC</span>
    <br><br>
</div>

<div class="col-lg-12 no-padd">
    <p class="description">This list contain all employees in status 3 or 5 (Dept 422 - Paid or Unpaid Leaves)</p>
    <p class="description">Determine for each employee who is not working, whether we think they will return to work any time before the end of the next year (For OI's meet with ground safety to decide)</p>
    <p class="description">If No - Delete them from the file <br> If YES - Move them to the job they own in the vacation file</p>
    <p class="description">For employees in 422 but working (ie. Rehab) Move them to the job they are working, in the vacation file</p>
    <p class="description">Are you sure you want to continue? (Y/N)</p>

    <ul class="col-lg-12 no-padd">
        <li class="choices-yn" (click)='show()'><span class="menu-num">Y.</span> Yes</li>
        <li class="choices-yn" (click)='gotoVacationMenu()'><span class="menu-num">N.</span> No</li>
    </ul>

    <!-- Modal -->
    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">{{ content }}</p>
                    <p class="modal-time">{{ time }}</p>
                    <p class="modal-count">{{ count }}</p>
                    <p class="modal-desc2">{{ ques }}</p>
                    <div class="btn-holder" [style.display]="showModalButton ? 'block' : 'none'">
                        <button class="btn btn-download" (click)='produceInactiveList()'>Download</button>
                        <button class="btn btn-exit" (click)='gotoVacationMenu() '>Exit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="col-lg-12 no-padd form-mainmenu" name="form-mainmenu">
        <span class="columns">ENTER OPTION HERE:</span>
        <input type="text" class="mf-input" toUpperCase autofocus (keydown.enter)="NavigateTo($event.target.value)">
        <input type="submit" hidden>
    </div>
</div>
