<app-header></app-header>
<div>
    <div class="col-lg-12 no-padd main-title">
        <span class="">=====            MAINTENANCE OPERATIONS ADMINISTRATION             =====</span><br>
        <span class="">HEADCOUNT/AUDIT MENU</span><br>
        <span>============================================================================</span>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd">
        <p class="choices"><span class="menu-num">&nbsp;1.</span> HEADCOUNT REPORT FUNCTIONS</p>
        <p class="choices" (click)="gotoVacationProj()"><span class="menu-num">&nbsp;2.</span> VACATION PROJECT</p>
        <p class="choices"><span class="menu-num">&nbsp;3.</span> MANAGEMENT SENIORITY</p>
        <p class="choices"><span class="menu-num">&nbsp;4.</span> FILEDOWN</p>
        <p class="choices"><span class="menu-num">&nbsp;5.</span> WEEKLY STATUS REPORT</p>
        <p class="choices"><span class="menu-num">&nbsp;6.</span> CREATE AUTOMATIC TRANSFER SHEETS</p>
        <p class="choices"><span class="menu-num">&nbsp;7.</span> LOOKUP</p>
        <p class="choices"><span class="menu-num">&nbsp;8.</span> CHECK FOR APPEARANCE OF THE XPI FILE</p>
        <p class="choices"><span class="menu-num">&nbsp;9.</span> UPDATE THE TSO MASTER FILE</p>
        <p class="choices"><span class="menu-num">10.</span> CHECK FOR REPAIR. CERT. EMPL. MOVES</p>
        <p class="choices"><span class="menu-num">11.</span> PE-15</p>
        <p class="choices"><span class="menu-num">12.</span> ALPHA ROSTER FOR IAM (RUN MONDAY)</p>
        <p class="choices"><span class="menu-num">13.</span> LABELS FOR SEN ROSTER DISTRIBUTION</p>
        <p class="choices"><span class="menu-num">14.</span> PIT CHECK SORTING LIST</p>
        <p class="choices" (click)="gotoMainMenu()"><span class="menu-num">&nbsp;E.</span> EXIT TO MAIN MENU</p>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd" name="form-mainmenu">
        <span class="col3 columns">ENTER SELECTION FROM ABOVE MENU: </span>
        <input type="text" class="mf-input" maxlength="2" autofocus  (keydown.enter)="NavigateTo($event.target.value)" toUpperCase>
    </div>
</div>