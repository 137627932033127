import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { MoveprocessingService } from 'src/app/Services/moveprocessing.service';
​
@Component({
  selector: 'app-displacement-or-resolution',
  templateUrl: './displacement-or-resolution.component.html',
  styleUrls: ['./displacement-or-resolution.component.scss']
})
export class DisplacementOrResolutionComponent implements OnInit {
​
  @ViewChild('date', {static: false}) date_el: ElementRef;
  @ViewChild('time', {static: false}) time_el: ElementRef;
  @ViewChild('option', {static: false}) option: ElementRef;
  @ViewChild('ProceedBtn', {static: false}) ProceedBtn: ElementRef;
  @ViewChild('DownloadBtn', {static: false}) DownloadBtn: ElementRef;
  @ViewChild('ErrorOKBtn', {static: false}) ErrorOKBtn: ElementRef;
  subscription: Subscription;
  subcatcher: Subscription;
  showInputDate: boolean;
  showModal: boolean;
  errorModal: boolean;
  HasValidInputs:boolean;
  TimeChecker: boolean;
  DateChecker: boolean;
  ActivateDownload: boolean;
  ActivateExit: boolean;
  DateInputIE: boolean;
  TimeInputIE: boolean;
  OnProcess: boolean;
  choice: number
  subcounter:number;
  counter: number;
  content: string;
  pasted_date: string;
  pasted_time: string;
  date_parts;
  realTime;
  realCount;
  noticeslist = [];
  DT = [];
  timee;
  count;
  ques;
  date_val;
  time_val;
​
  constructor(private route: ActivatedRoute, private router: Router, private moveProcessing: MoveprocessingService) { }
​
  ngOnInit(): void {
    this.subcounter = -1;
  }
​
  ngAfterViewInit() {
    setTimeout(() => document.querySelector("input").focus(), 500);
  }
​
  NavigateTo(NavKey) {
    switch (NavKey) {
      case "1":
        this.Notices(1);
        break;
      case "2":
        this.Notices(2);
        break;
    }
    return;
  }
​
  gotoMoveProcessing() {
    this.router.navigate(['MoveProcessing']);
  }
​
  Notices(choice) {
    this.showInputDate = true;
    this.choice = choice;
    this.date_el.nativeElement.value = "";
    this.time_el.nativeElement.value = "";
    setTimeout(() => this.date_el.nativeElement.focus(), 50);
    this.HasValidInputs = false;
​
    this.DateInputIE = this.isIE();
    this.TimeInputIE = this.isIE();
  }
​
  GenerateFile() {
    this.OnProcess = true;
    this.ProceedBtn.nativeElement.blur();
    if(this.time_val === "" || this.time_val === undefined) this.time_val = "00.00.00.0000";

    let DateTimeJSON = '{"seldaTim": "'+ this.date_val + "-" + this.time_val + '"}';

    if(this.date_val === "" || this.time_val === ""){
      this.errorModal = true;
    }
    else{
      this.showModal = true;
      switch (this.choice) {
        case 1:
          this.content = "The system is creating the Displacement Notices...";
          this.subscription = this.moveProcessing.getDisplacement(DateTimeJSON).subscribe((res: any)=> {
            let data= this.moveProcessing.extractData(res);
            this.noticeslist.push(data);
            this.subcounter++;
            this.realCount = this.noticeslist[this.subcounter].recordSize;
            this.realTime = this.noticeslist[this.subcounter].responseTime;
          }, onError => {
            console.log("error");
          })
          break;
​
        case 2:
          this.content = "The system is creating the Resolution Notices...";
          this.subscription = this.moveProcessing.getResolution(DateTimeJSON).subscribe((res: any)=> {
            let data= this.moveProcessing.extractData(res);
            this.subcounter++;
            this.noticeslist.push(data);
            this.realCount = this.noticeslist[this.subcounter].recordSize;
            this.realTime = this.noticeslist[this.subcounter].responseTime;
          }, onError => {
            console.log("error");
          })
          break;
      }

      const source = interval(1000);
      this.counter = 1;
      this.subcatcher = source.subscribe(val => this.checkvalue(this.realTime, this.realCount, this.counter++));
    }
  }
​
  checkvalue(realtime, realcount, counter) {
    if(counter <= 90) {
      if(realtime === undefined) {
      }
      else{
        this.OnProcess = false;
        this.subcatcher && this.subcatcher.unsubscribe();
        if(realcount == 0 || realcount === undefined){
          this.content = 'No records found.';
          this.ActivateExit = true;
        }
        else{
          switch (this.choice) {
            case 1:
              this.content = 'The system is done creating Displacement Notices';
              break;
            case 2:
              this.content = 'The system is done creating Resolution Notices';
              break;
          }
          this.timee = 'Time: ' + this.realTime + ' millisecond(s)';
          this.count = 'Total number of records: ' + this.realCount;
          this.ques = 'Do you want to download the file?';
          this.ActivateDownload = true;
          setTimeout(() => this.DownloadBtn.nativeElement.focus(), 50);
        }
      }
    }
    else {
      this.subcatcher && this.subcatcher.unsubscribe();
      this.content = "The system failed to create the file. Please try again later.";
      this.ActivateExit = true;
      setTimeout(() => this.ErrorOKBtn.nativeElement.focus(), 50);
      this.OnProcess = false;
    }
  }
​
  EnterGenerateFile() {

  }
​
  DateTimeChecker(InputType) {
    let date_raw = this.date_el.nativeElement.value;
    let time_raw = this.time_el.nativeElement.value;
​
    if (this.isIE() && date_raw === '') this.DateInputIE = true;
    else this.DateInputIE = false;
    if (this.isIE() && time_raw === '') this.TimeInputIE = true;
    else this.TimeInputIE = false;
​
    if(date_raw || InputType == 1){
      if (date_raw.trim().match(/([0-9][0-9][0-9][0-9])[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])/) && date_raw.length >= 9) {
        this.DateChecker = true;
        this.date_val = date_raw;
​
        setTimeout(() => this.time_el.nativeElement.focus(), 50);
        this.TimeChecker = true;
      }
      else{
        this.DateChecker = false;
      }
    }
​
    if(time_raw || InputType == 2){
      if (time_raw.trim().match(/([01][0-9]|2[01234])[- /.]([0-6][0-9])[- /.]([0-6][0-9])[- /.]([0-9][0-9])\d\d/) && time_raw.length >= 12) {
        this.TimeChecker = true;
        setTimeout(() => this.ProceedBtn.nativeElement.focus(), 50);
        this.time_val = time_raw;
      }
      else if(time_raw === ""){
        this.time_val = "00.00.00.0000";
        this.TimeChecker = true;
      }
      else
        this.TimeChecker = false;
    }
​
    if(this.DateChecker && this.TimeChecker) this.HasValidInputs = true;
    else this.HasValidInputs = false;
​
  }
​
  DownloadFile() {
    this.showModal = false;
    switch (this.choice) {
      case 1:
        this.moveProcessing.DownloadNotices("/move/displacement/notice/download", "MP_8.1_DISPLACEMENT_NOTICES");
        this.showModal =false;
        break;
      case 2:
        this.moveProcessing.DownloadNotices("/move/notices/resolution/download", "MP_8.2_RESOLUTION_NOTICES");
        this.showModal =false;
        break;
    }
    this.gotoMoveProcessing();
  }
​
  HideErrorDateModal() {
    this.errorModal = false;
    this.gotoMoveProcessing();
  }
​
  HideErrorPrompt() {
    this.showModal = false;
    this.gotoMoveProcessing();
  }
​
  Reset(TD) {
    switch (TD) {
      case 1:
        this.date_el.nativeElement.value = "";
        this.DateTimeChecker(1);
        break;
      case 2:
        this.time_el.nativeElement.value = "";
        this.DateTimeChecker(2);
        break;
    }
  }
​
  isIE() {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }
​
  paste(Event, InputType) {
    switch (InputType) {
    case 1:
      setTimeout(() => this.CheckPasteValue(this.date_el.nativeElement.value, InputType), 30);
      break;
    case 2:
      setTimeout(() => this.CheckPasteValue(this.time_el.nativeElement.value, InputType), 30);
      break;
  }
  }
​
  CheckPasteValue(pastevalue, InputType) {
    if(InputType == 1 && pastevalue !== '') {
      if(pastevalue.length >= 4 || pastevalue <= 6) {
        pastevalue = pastevalue.replace('-','');
        let e = pastevalue.substring(0, 4);
        let f = pastevalue.substring(4, 6) < 13 && pastevalue.substring(4, 6) > 0 ? pastevalue.substring(4, 6): '';
        this.date_el.nativeElement.value = (e + '-' + f );
      }
      if(pastevalue.length > 6 || pastevalue <= 8) {
        pastevalue = pastevalue.replace('-','');
        let e = pastevalue.substring(0, 4) ;
        let f = pastevalue.substring(4, 6) < 13 && pastevalue.substring(4, 6) > 0 ? pastevalue.substring(4, 6): '';
        let g = pastevalue.substring(6, 8) < 32 && pastevalue.substring(6, 8) > 0 ? pastevalue.substring(6, 8): '';
        let datevalue = f === '' ? (e + '-') : (g === '' ? (e + '-' + f + '-') : (e + '-' + f + '-' + g));
​
        this.date_el.nativeElement.value = datevalue;
      }
    }
​
      if(InputType == 2 && pastevalue !== '') {
        if(pastevalue.length >= 2 || pastevalue <= 4) {
          pastevalue = pastevalue.replace('.','');
          let e = pastevalue.substring(0, 2) < 24 ? pastevalue.substring(0,2): '';
          let f = pastevalue.substring(2, 4) < 60 ? (pastevalue.charAt(2) < 6 ? pastevalue.substring(2, 4):''): '';
          this.time_el.nativeElement.value = e === '' ? '' : (e.length == 1 ? e: (e + '.' + f + '.'));
        }
        if(pastevalue.length > 4 || pastevalue <= 6) {
          pastevalue = pastevalue.replace('.','');
          let e = pastevalue.substring(0, 2) < 24 ? pastevalue.substring(0,2): '';
          let f = pastevalue.substring(2, 4) < 60 ? (pastevalue.charAt(2) < 6 ? pastevalue.substring(2, 4):''): '';
          let g = pastevalue.substring(4, 6) < 60 ? (pastevalue.charAt(4) < 6 ? pastevalue.substring(4, 6):''): '';
          let datevalue = e === '' ? '' : (f === '' ? (e + '.') : (g === '' ? (e + '.' + f + '.') : (e + '.' + f + '.' + g + '.')));
          this.time_el.nativeElement.value = datevalue;
        }
        if(pastevalue.length > 6 || pastevalue <= 8) {
          pastevalue = pastevalue.replace('.','');
          let e = pastevalue.substring(0, 2) < 24 ? pastevalue.substring(0,2): '';
          let f = pastevalue.substring(2, 4) < 60 ? (pastevalue.charAt(2) < 6 ? pastevalue.substring(2, 4):''): '';
          let g = pastevalue.substring(4, 6) < 60 ? (pastevalue.charAt(4) < 6 ? pastevalue.substring(4, 6):''): '';
          let h = pastevalue.substring(6, 10) < 1001 ? (pastevalue.charAt(6) < 2 ? pastevalue.substring(6, 10):''): '';
          let datevalue = e === '' ? '' : (f === '' ? (e + '.') : (g === '' ? (e+'.'+f+'.') : (h === '' ?  e+'.'+f+'.'+g+'.' : (e+'.'+f+'.'+g+'.'+h))));
          this.time_el.nativeElement.value = datevalue;
        }
      }
    this.DateTimeChecker(InputType);
  }

}
