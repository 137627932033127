import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api-endpoint.service";
var HeadcountService = /** @class */ (function () {
    function HeadcountService(api) {
        this.api = api;
        this.vacafileffListener = new Subject();
        this.basicshiftffListener = new Subject();
        this.inactivefileffListener = new Subject();
        this.rotatingfileffListener = new Subject();
        this.initialVacRosterffListener = new Subject();
        this.vacationCalculationXffListener = new Subject();
        this.vacationCalculation6B1ffListener = new Subject();
        this.finalVacationRosterListener = new Subject();
    }
    HeadcountService.prototype.extractData = function (data) {
        return this.api.extractData(data);
    };
    HeadcountService.prototype.fetchfromUrl = function (url) {
        return this.api.GetRequest(url);
    };
    HeadcountService.prototype.FetchVacaList = function (filter, page) {
        return this.api.PostRequest("/headcount/vacation/edit/SearchVacaList", filter, { "size": 5, "page": page });
    };
    HeadcountService.prototype.SearchEmpRecord = function (filter, page) {
        return this.api.PostRequest("/headcount/vacation/edit/SearchVacaList", filter, { "size": 5, "page": page });
    };
    HeadcountService.prototype.SaveEmpRecord = function (EmpData) {
        return this.api.PostRequest("/headcount/vacation/edit/SaveVacaList", EmpData);
    };
    HeadcountService.prototype.getCreateVacaFile = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/create/extract').subscribe(function (Response) {
            _this.vacafileff = _this.api.extractData(Response);
            _this.vacafileffListener.next(_this.vacafileff);
        });
    };
    HeadcountService.prototype.downloadBasicShift = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/downloadBasicShifts/insertShifts').subscribe(function (Response) {
            _this.basicshiftff = _this.api.extractData(Response);
            _this.basicshiftffListener.next(_this.basicshiftff);
        });
    };
    HeadcountService.prototype.produceInactiveList = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/inactive/extract').subscribe(function (Response) {
            _this.inactiveff = _this.api.extractData(Response);
            _this.inactivefileffListener.next(_this.inactiveff);
        });
    };
    HeadcountService.prototype.produceRotatingList = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/rotate/extract').subscribe(function (Response) {
            _this.rotatingff = _this.api.extractData(Response);
            _this.rotatingfileffListener.next(_this.rotatingff);
        });
    };
    HeadcountService.prototype.initialVacRoster = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/vaclist/extract').subscribe(function (Response) {
            _this.initialff = _this.api.extractData(Response);
            _this.initialVacRosterffListener.next(_this.initialff);
        });
    };
    HeadcountService.prototype.vacationCalculationX = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/vaccalcx/extract').subscribe(function (Response) {
            _this.vaccalcxff = _this.api.extractData(Response);
            _this.vacationCalculationXffListener.next(_this.vaccalcxff);
        });
    };
    HeadcountService.prototype.vacationCalculation6B1 = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/vac6B1/extract').subscribe(function (Response) {
            _this.vaccalc6B1ff = _this.api.extractData(Response);
            _this.vacationCalculation6B1ffListener.next(_this.vaccalc6B1ff);
        });
    };
    HeadcountService.prototype.finalVacationRoster = function () {
        var _this = this;
        this.fetchfromUrl('/headcount/vacation/vac5B/extract').subscribe(function (Response) {
            _this.finalVacationf = _this.api.extractData(Response);
            _this.finalVacationRosterListener.next(_this.finalVacationf);
        });
    };
    HeadcountService.prototype.DownoadVacationFile = function (Url, FileName) {
        this.api.DownloadFile(Url, FileName);
    };
    HeadcountService.prototype.DownloadVacationEdit = function (Url, FileName) {
        this.api.DownloadFile(Url, FileName);
    };
    HeadcountService.prototype.vacafileFFListener = function () {
        return this.vacafileffListener.asObservable();
    };
    HeadcountService.prototype.basicshiftFFListener = function () {
        return this.basicshiftffListener.asObservable();
    };
    HeadcountService.prototype.inactivefileListener = function () {
        return this.inactivefileffListener.asObservable();
    };
    HeadcountService.prototype.rotatingfileListener = function () {
        return this.rotatingfileffListener.asObservable();
    };
    HeadcountService.prototype.FinalVacationRosterListener = function () {
        return this.finalVacationRosterListener.asObservable();
    };
    HeadcountService.prototype.initialVacRosterListener = function () {
        return this.initialVacRosterffListener.asObservable();
    };
    HeadcountService.prototype.vacationCalculationXListener = function () {
        return this.vacationCalculationXffListener.asObservable();
    };
    HeadcountService.prototype.vacationCalculation6B1Listener = function () {
        return this.vacationCalculation6B1ffListener.asObservable();
    };
    HeadcountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeadcountService_Factory() { return new HeadcountService(i0.ɵɵinject(i1.APIEndpointService)); }, token: HeadcountService, providedIn: "root" });
    return HeadcountService;
}());
export { HeadcountService };
