<app-header></app-header>
<div class="vacalist">
  <div>
    <div class="col-lg-12 no-padd ">
      <span class="page-title">EDITING VACATION FILE</span>
      <span class="page-titlesub">VACAEDIT</span>
    </div>

    <div class="form-inline">
      <div class="form-group no-padd">
        <label for="EMPNO">EMP_NO:</label>
        <input type="text" class="mf-input-vaca empno" minlength="6"  maxlength="6" [(ngModel)]="EmpNo" numbersOnly>
      </div>

      <div class="form-group">
        <label for="EMPNO1">LASTNAME:</label>
        <input type="text" class="mf-input-vaca lastname" toUpperC [(ngModel)]="LName" maxlength="18"
          toUpperCase>
      </div>

      <div class="form-group">
        <label for="EMPNO2">NAMEINIT:</label>
        <input type="text" class="mf-input-vaca nameinit" [(ngModel)]="NameInit" maxlength="12"
          toUpperCase>
      </div>

      <div class="form-group no-padd">
        <!-- <input type="image" src="../../../../../assets/icons/icon_search.png" alt="Search" class="icon icon-search" (click)="SearchEmp()"> -->
        <input type="button" class="btn-search" (click)="SearchEmp()" value="SEARCH">
        <!-- <input type="image" src="../../../../../assets/icons/icon_reset.png" alt="Reset" class="icon icon-reset" (click)="ResetSearch()"> -->
        <input type="button" class="btn-clear" (click)="ResetSearch()" value="CLEAR">
      </div>
    </div>

    <div class="table-list" [hidden]="!hasSearchResults">
      <table class="col-xs-12" #EmpListRecTable>
        <th>DEPTNO</th>
        <th>EMP_NO</th>
        <th>LASTNAME</th>
        <th>NAME_INIT</th>
        <th>WORKASGN</th>
        <tr *ngFor="let item of EmpList ; let i = index;" (click)=SelectEmp(i) [ngClass]="{'colortest':item.cacheId == SlctedCacheId,'tr-list':true}">
          <td class="td-deptno" >{{item.deptno}}</td>
          <td class="td-empno">{{item.emp_NO}}</td>
          <td class="td-lastname">{{item.lastname}}</td>
          <td class="td-nameinit">{{item.nameinit}}</td>
          <td class="td-workasgn">{{item.workasgn}}</td>
        </tr>
      </table>
    </div>
  </div>
  
  <div [hidden]="hasSearchResults" class="search-warning">
    <p>{{ isLoading ? 'Loading...' : 'No Results found' }}</p>
  </div>

  <div class="pagination" [hidden]="!hasSearchResults">
    <button class="bttn bttn-previous" (click)='PreviousPage()' [disabled]="first" [style.color]="first ? '#232323' : '#FFFFFF'"><<</button>
      <span class="paginationSpan">SHOWING {{VCurrentPage}} page of {{totalPages}}</span>
        <button class="bttn bttn-next" (click)='NextPage()' [disabled]="last" [style.color]="last ? '#232323' : '#FFFFFF'">>></button>
  </div>

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="saveSuccessModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Changes made in this page has been saved.</p>
          <div class="btn-holder">
            <button class="btn btn-exit" (click)='closeSaveSuccessModal()'>OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Save all the changes made?</p>
          <div class="btn-holder">
            <button class="btn btn-download" (click)='SaveChanges(0)'>YES</button>
            <button class="btn btn-exit" (click)='DiscardChanges(0)'>NO</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-dialog-centered" id="pagination-modal" role="dialog"
    [style.display]="showPaginationModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Save recent changes made in this page before proceeding?</p>
          <div class="btn-holder">
            <button class="btn btn-download" (click)='SaveChanges(1)'>KEEP CHANGES</button>
            <button class="btn btn-exit" (click)='DiscardChanges(1)'>DISCARD</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-dialog-centered" id="search-modal" role="dialog"
    [style.display]="showSearchModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Save recent changes made in this page before proceeding?</p>
          <div class="btn-holder">
            <button class="btn btn-download" (click)='SaveChanges(2)'>KEEP CHANGES</button>
            <button class="btn btn-exit" (click)='DiscardChanges(2)'>DISCARD</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-dialog-centered" id="exit-modal" role="dialog"
    [style.display]="showExitModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Save recent changes made in this page before proceding?</p>
          <div class="btn-holder">
            <button class="btn btn-download" (click)='SaveChanges(3)'>KEEP CHANGES</button>
            <button class="btn btn-exit" (click)='DiscardChanges(3)'>DISCARD</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->

  <form [formGroup]="EmployeeForm" [hidden]="!hasSearchResults">
    <table class="table" >
         <tr>
              <input type="hidden" formControlName="cacheId">

              <td> <span class="label">DEPTNO:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-1 no-pad" [readonly]="!hasSearchResults" formControlName="deptno" toUpperCase (blur)='UpdateRecord(0)' maxlength="3"></td>
    
              <td> <span class="label">CITY:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-2 no-pad" [readonly]="!hasSearchResults" formControlName="city" toUpperCase (blur)='UpdateRecord(0)' maxlength="3"></td>
    
              <td> <span class="label">OCCCODE:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-3 no-pad" [readonly]="!hasSearchResults" formControlName="occcode" toUpperCase (blur)='UpdateRecord(0)' maxlength="3"></td>
         </tr>
         <tr>
              <td> <span class="label">EMP_NO:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-1 no-pad default nocursor" readonly formControlName="emp_NO" (keydown)="unfocus($event)" #empno_id></td>
    
              <td> <span class="label">NAMEINIT:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-2 no-pad" [readonly]="!hasSearchResults" formControlName="nameinit" toUpperCase (blur)='UpdateRecord(0)' maxlength="12"></td>
         </tr>
         <tr>
              <td> <span class="label">LASTNAME:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-1 no-pad" [readonly]="!hasSearchResults" formControlName="lastname" toUpperCase (blur)='UpdateRecord(0)' maxlength="18"></td>
         </tr>
         <tr>
              <td> <span class="label">WORKASGN:</span></td>
              <td colspan="3"><input type="text" class="mf-input mf-input-edit-1 no-pad" [readonly]="!hasSearchResults" formControlName="workasgn" toUpperCase (blur)='UpdateRecord(0)' maxlength="18"></td>
    
              <td> <span class="label">SHIFTBRK:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-3 no-pad" [readonly]="!hasSearchResults" numeric formControlName="shiftbrk" toUpperCase (blur)='UpdateRecord(0)' maxlength="3"></td>
         </tr>
         <tr>
              <td> <span class="label">SUBSHIFT:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-1 no-pad" [readonly]="!hasSearchResults" formControlName="subshift" toUpperCase (blur)='UpdateRecord(0)' maxlength="3"></td>
    
              <td> <span class="label">SENIORITY:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-2 no-pad" [readonly]="!hasSearchResults" numeric formControlName="seniority" toUpperCase (blur)='UpdateRecord(0)' maxlength="6"></td>
    
    
              <td> <span class="label">HIREMO:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-3 no-pad" [readonly]="!hasSearchResults" numeric formControlName="hiremo" toUpperCase (blur)='UpdateRecord(0)' maxlength="2" numbersOnly></td>
         </tr>
    
         <tr>
              <td> <span class="label">HIREDA:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-1 no-pad" [readonly]="!hasSearchResults" formControlName="hireda" toUpperCase (blur)='UpdateRecord(0)' maxlength="2" numbersOnly></td>
    
              <td> <span class="label">HIREYR:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-2 no-pad" [readonly]="!hasSearchResults" formControlName="hireyr" toUpperCase (blur)='UpdateRecord(0)' maxlength="2" numbersOnly></td>
    
              <td> <span class="label">STATUS:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-3 no-pad" [readonly]="!hasSearchResults" numeric formControlName="status" toUpperCase (blur)='UpdateRecord(0)' maxlength="2"></td>
         </tr>
    
         <tr>
              <td> <span class="label">SUBDEPT:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-1 no-pad" [readonly]="!hasSearchResults" formControlName="subdept" toUpperCase (blur)='UpdateRecord(0)' maxlength="1"></td>
    
              <td> <span class="label">VACMO:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-2 no-pad" [readonly]="!hasSearchResults" numeric formControlName="vacmo" toUpperCase (blur)='UpdateRecord(0)' maxlength="2" numbersOnly></td>
    
              <td> <span class="label">VACDAY:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-3 no-pad" [readonly]="!hasSearchResults" numeric formControlName="vacday" toUpperCase (blur)='UpdateRecord(0)' maxlength="2" numbersOnly></td>
         </tr>
    
         <tr>
              <td> <span class="label">VACYR:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-1 no-pad" [readonly]="!hasSearchResults" formControlName="vacyr" toUpperCase (blur)='UpdateRecord(0)' maxlength="2" numbersOnly></td>
    
              <td> <span class="label">VACSHIFT:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-2 no-pad" [readonly]="!hasSearchResults" formControlName="vacshift" toUpperCase (blur)='UpdateRecord(0)' maxlength="4" numbersOnly></td>
    
              <td> <span class="label">PREVDATS:</span></td>
              <td><input type="text" class="mf-input mf-input-edit-3 no-pad" [readonly]="!hasSearchResults" formControlName="prevdats" toUpperCase (blur)='UpdateRecord(0)' maxlength="2" numbersOnly></td>
         </tr>

         <tr>
          <td> <span class="label">VACWK:</span></td>
          <td>
            <div class="mf-input-edit-1 no-pad vacwkdiv" (click) = 'FocusVacWk()'>
              <input type="text" class="dat-input mf-input" [readonly]="!hasSearchResults" formControlName="vacwk" toUpperCase (blur)='UpdateRecord(2)' maxlength="3" #vacwk_id>
            </div>
          </td>

          <td> <span class="label">DAT:</span></td>
          <td><input type="text" class="mf-input mf-input-edit-2 no-pad default nocursor" readonly formControlName="dat" toUpperCase (blur)='UpdateRecord(0)' maxlength="3" #dat_id (keydown)="unfocus($event)"></td>
     </tr>
    </table>
  </form>

    <div class="btn-holder" [hidden]="!hasSearchResults">
      <!-- <button [ngClass]="{'btn-save':enableSaveBtn, 'btn-disable':!enableSaveBtn}" (click)="modalSaveChanges()" [disabled]="!enableSaveBtn">SAVE ALL</button> -->
      <button [ngClass]="{'btn-save':true}" (click)="modalSaveChanges()">SAVE ALL</button>
      <button class="btn-cancel" (click)="CheckExit()">EXIT</button>
    </div>
    <!--
    <div class="btn-holder" [hidden]="!hasSearchResults">
      <button class="btn-download-edit" (click)="DownloadVacaEditFile()">DOWNLOAD VACAEDIT FILE</button>
    </div>
    -->
</div>