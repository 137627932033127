/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./prod-vac-calc-b.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../header/header.component.ngfactory";
import * as i3 from "../../../header/header.component";
import * as i4 from "../../../../Services/auth/user.service";
import * as i5 from "../../../../Directives/number-only.directive";
import * as i6 from "../../../../Directives/uppercase.directive";
import * as i7 from "./prod-vac-calc-b.component";
import * as i8 from "../../../../Services/headcount.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../../Services/api-endpoint.service";
var styles_ProdVacCalcBComponent = [i0.styles];
var RenderType_ProdVacCalcBComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProdVacCalcBComponent, data: {} });
export { RenderType_ProdVacCalcBComponent as RenderType_ProdVacCalcBComponent };
export function View_ProdVacCalcBComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.UserService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-lg-12 no-padd "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["VACATION CALCULATION SUMMARIES"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "page-titlesub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["VACCALC"])), (_l()(), i1.ɵeld(7, 0, null, null, 49, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PRODUCE VAC CALC SUMMARIES BY DIVISION/CITY AFTER 11/01 "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Calculates employees allowed off at a time for line departments"])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 8, "ul", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "li", [["class", "columns choices-yn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.choice(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1."])), (_l()(), i1.ɵted(-1, null, [" SUMMARIES FOR DISTRIBUTION TO STNS (LONG VERSION)"])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "li", [["class", "columns choices-yn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2."])), (_l()(), i1.ɵted(-1, null, [" BACK TO VACATION PROJECT MENU"])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 15, "div", [["class", "modal modal-dialog-centered"], ["id", "yes-modal"], ["role", "dialog"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 14, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 13, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 2, "p", [["class", "modal-desc1"]], null, null, null, null, null)), (_l()(), i1.ɵted(31, null, ["", ""])), (_l()(), i1.ɵeld(32, 0, null, null, 0, "span", [], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "p", [["class", "modal-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(34, null, ["", ""])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "p", [["class", "modal-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(36, null, ["", ""])), (_l()(), i1.ɵeld(37, 0, null, null, 1, "p", [["class", "modal-desc2"]], null, null, null, null, null)), (_l()(), i1.ɵted(38, null, ["", ""])), (_l()(), i1.ɵeld(39, 0, null, null, 2, "div", [["class", "btn-holder"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"])), (_l()(), i1.ɵeld(42, 0, null, null, 8, "div", [["class", "modal modal-dialog-centered"], ["id", "yes-modal"], ["role", "dialog"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(43, 0, null, null, 7, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(46, 0, null, null, 1, "p", [["class", "modal-desc1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This option is not currently used."])), (_l()(), i1.ɵeld(48, 0, null, null, 2, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(49, 0, null, null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideBlockModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"])), (_l()(), i1.ɵeld(51, 0, null, null, 5, "div", [["class", "col-lg-12 no-padd form-mainmenu"], ["name", "form-mainmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(52, 0, null, null, 1, "span", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ENTER OPTION HERE:"])), (_l()(), i1.ɵeld(54, 0, null, null, 2, "input", [["autofocus", ""], ["class", "mf-input"], ["maxlength", "1"], ["numbersOnly", ""], ["toUpperCase", ""], ["type", "text"]], null, [[null, "keydown.enter"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 55).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("input" === en)) {
        var pd_1 = (i1.ɵnov(_v, 56).onKeyup($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.enter" === en)) {
        var pd_2 = (_co.NavigateTo($event.target.value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(55, 16384, null, 0, i5.NumberOnlyDirective, [i1.ElementRef], null, null), i1.ɵdid(56, 16384, null, 0, i6.UppercaseDirective, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showModal ? "flex" : "none"); _ck(_v, 26, 0, currVal_0); var currVal_1 = _co.content; _ck(_v, 31, 0, currVal_1); var currVal_2 = (_co.OnProcess ? "inline-block" : "none"); _ck(_v, 32, 0, currVal_2); var currVal_3 = _co.time; _ck(_v, 34, 0, currVal_3); var currVal_4 = _co.count; _ck(_v, 36, 0, currVal_4); var currVal_5 = _co.ques; _ck(_v, 38, 0, currVal_5); var currVal_6 = (_co.showModalButton ? "block" : "none"); _ck(_v, 39, 0, currVal_6); var currVal_7 = (_co.blockModal ? "flex" : "none"); _ck(_v, 42, 0, currVal_7); }); }
export function View_ProdVacCalcBComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prod-vac-calc-b", [], null, null, null, View_ProdVacCalcBComponent_0, RenderType_ProdVacCalcBComponent)), i1.ɵdid(1, 4440064, null, 0, i7.ProdVacCalcBComponent, [i8.HeadcountService, i9.Router, i9.ActivatedRoute, i10.APIEndpointService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProdVacCalcBComponentNgFactory = i1.ɵccf("app-prod-vac-calc-b", i7.ProdVacCalcBComponent, View_ProdVacCalcBComponent_Host_0, {}, {}, []);
export { ProdVacCalcBComponentNgFactory as ProdVacCalcBComponentNgFactory };
