import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


@Component({
  selector: 'app-probationary-menu',
  templateUrl: './probationary-menu.component.html',
  styleUrls: ['./probationary-menu.component.scss']
})
export class ProbationaryMenuComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }

  NavigateTo(NavKey) {
    NavKey = NavKey.replace(/\s/g, "");
    switch (NavKey) {
      case "1":
        this.router.navigate(['/Records/ProbationarySystem/EnterNewRecords'])
        break;
      case "2":
        this.EditProbationary();
        break;
      case "3":
        this.gotoPrintReportsDue();
        break;
      case "E":
        this.gotoRecordsMenu();
        break;
      case "X":
        this.gotoDepartmentalMenu();
        break;
    }
    return;
  }

  gotoPrintReportsDue() {
    this.router.navigate(["Records/ProbationarySystem/PrintReportsDue"]);
  }

  gotoRecordsMenu() {
    this.router.navigate(['Records']);
  }

  gotoDepartmentalMenu() {
    this.router.navigate(['Mainmenu']);
  }

  EditProbationary() {
    this.router.navigate(['Records/ProbationarySystem/EditProbationary',0]);
  }

}
