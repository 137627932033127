import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { UserService } from './auth/user.service';
const baseURL = environment.api_home + '/api';


@Injectable({
  providedIn: 'root'
})

export class APIEndpointService {

  headerEntry: any;
  headers: HttpHeaders;

  constructor(private http: HttpClient, private userService: UserService) {
      
   }

   async getHttpText(url: string): Promise<any> {
     this.PrepareHeader();
    return await this.http.get<string>(baseURL + url, { headers: this.headers, observe: "body", responseType: 'text' as 'json' })
      .toPromise();
  }
   
   GetRequest(url, data?): Observable<any> {
    this.PrepareHeader();
    return this.http.get<any>(baseURL + url, { headers: this.headers, observe: "response" });
  }

  PostRequest(url, body?, params?): Observable<any> {
    this.PrepareHeader();
    return this.http.post<any>(baseURL + url, body, { headers: this.headers, observe: "response", params: params });
  }
  DownloadFile(url, filename) {
    this.http.get(baseURL + url, { headers: this.headers, responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const blob = new Blob(binaryData, { type: dataType });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename) {
            downloadLink.setAttribute('download', filename);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      }
    )
  }

  GetToken() {
    return 'Bearer ' + this.userService.getUser().accessToken;
  }

  PrepareHeader() {
    this.headerEntry = {
      'Content-Type': 'application/json'
    };

    if (!environment.local_dev_mode) {
      this.headerEntry['Authorization'] = this.GetToken();
    }
    this.headers = new HttpHeaders(this.headerEntry);
  }

  extractData(res) {
    let body = res.body;
    if (body) {
      return body;
    } else {
      return {};
    }
  }

}
