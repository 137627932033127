<form [formGroup]="EmployeeForm" class="py-5" >
  <div class="form-group">
    <label class="primary-blue" for="empNo">EMPNO:</label>
    <input
      id="empNo"
      autocomplete="off"
      type="text"
      maxlength="6"
      value="{{empData.employeeNum}}"
      formControlName="employeeNum"
      (keyup)="updateField('employeeNum', $event.target.value)"
      numbersOnly
      class="mf-input mf-input-edit-2 no-pad" #focused />
  </div>
  <div class="form-group">
    <label class="primary-blue" for="name">NAME:</label>
    <input
      id="name"
      autocomplete="off"
      value="{{empData.employeeName}}"
      (keyup)="updateField('employeeName', $event.target.value)"
      formControlName="employeeName"
      type="text"
      maxlength="25"
      class="mf-input mf-input-edit-2 no-pad" />
  </div>
  <div class="form-group">
    <label class="primary-blue" for="jobClass">JOBCLASS:</label>
    <input
      id="jobClass"
      autocomplete="off"
      value="{{empData.jobClass}}"
      formControlName="jobClass"
      (keyup)="updateField('jobClass', $event.target.value)"
      type="text"
      maxlength="3"
      numbersOnly
      class="mf-input mf-input-edit-2 no-pad" />
  </div>
  <div class="form-group">
    <label class="primary-blue" for="deptName">DEPTNAME:</label>
    <input
      id="deptName"
      maxlength="20"
      autocomplete="off"
      formControlName="deptName"
      value="{{empData.deptName}}"
      (keyup)="updateField('deptName', $event.target.value)"
      type="text"
      class="mf-input mf-input-edit-2 no-pad" />
  </div>
  <div class="form-group">
    <label class="primary-blue" for="cityCode">CITYCODE:</label>
    <input
      id="cityCode"
      autocomplete="off"
      maxlength="3"
      formControlName="cityCode"
      value="{{empData.cityCode}}"
      (keyup)="updateField('cityCode', $event.target.value)"
      type="text"
      class="mf-input mf-input-edit-2 no-pad" />
  </div>
  <div class="form-group">
    <label class="primary-blue" for="shift">SHIFT:</label>
    <input
      id="shift"
      autocomplete="off"
      maxlength="3"
      value="{{empData.shift}}"
      formControlName="shift"
      (keyup)="updateField('shift', $event.target.value)"
      type="text"
      class="mf-input mf-input-edit-2 no-pad" />
  </div>
  <div class="form-group">
    <label class="primary-blue"  for="startDt">STARTDT:</label>
    <input
      id="startDt"
      autocomplete="off"
      name="startDt"
      [(ngModel)]="empData.startDate"
      [ngModelOptions]="{standalone: true}"
      (keypress)="updateDateField('startDate', $event)"
      (input)="updateDateField('startDate', $event)"
      type="text"
      maxlength="8"
      class="mf-input mf-input-edit-2 no-pad" />
  </div>
  <div class="form-group">
    <label class="primary-blue" for="reason">REASON:</label>
    <input
      id="reason"
      autocomplete="off"
      maxlength="20"
      value="{{empData.reason}}"
      formControlName="reason"
      (keyup)="updateField('reason', $event.target.value)"
      type="text"
      class="mf-input mf-input-edit-2 no-pad" />
  </div>

  <div class="flex items-center justify-center pt-5 mt-5">
    <button [disabled]="!formValid" (click)="onSave()" class="btn-submit">SAVE</button>
    <button [disabled]="!formValid" (click)="onSave(true)" class="btn-submit">SAVE & EXIT</button>
    <button type="button" (click)="exitTo('/Records/ProbationarySystem')" class="btn-cancel">CANCEL</button>
  </div>
</form>


<!-- Modal -->
<div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showErrorModal ? 'flex' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-3">
        <div class="requiredErrorMessages">
          {{errorMessage}}
        </div>
        <div class="mt-5 btn-holder">
          <button (click)="closeErrorModal()" class="btn btn-exit">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showConfirmModal ? 'flex' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-3">
        <div class="requiredErrorMessages">
          Are you sure you want to save the new record?
        </div>
        <div class="mt-5 btn-holder">
          <button (click)="processSave()" class="btn btn-exit  mx-3">Yes</button>
          <button (click)="closeConfirmBox()" class="btn btn-exit  mx-3">No</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showSuccessModal ? 'flex' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-3">
        <div class="requiredErrorMessages">
          New record created successfully, do you want to print and add more?
        </div>
        <div class="mt-5 btn-holder flex items-center">
          <button (click)="print()" class="btn btn-exit  mx-3">Print</button>
          <button (click)="addMore()" class="btn btn-exit mx-3">Add more</button>
          <button (click)="exitTo('/Records')" class="btn btn-exit mx-3">Exit</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showSaving ? 'flex' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-3">
        <div class="requiredErrorMessages">
          <div *ngIf="!savingCompleted">Saving record...</div>
          <div *ngIf="savingCompleted">Record saved!</div>
        </div>
        <div *ngIf="savingCompleted && saveAndExitAction" class="mt-5 btn-holder">
          <button (click)="closeSavingAndExit()" class="btn btn-exit">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>
