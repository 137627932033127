/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./displacement-or-resolution.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../header/header.component.ngfactory";
import * as i3 from "../../header/header.component";
import * as i4 from "../../../Services/auth/user.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../Directives/number-only.directive";
import * as i7 from "../../../Directives/date-format.directive";
import * as i8 from "../../../Directives/time-format.directive";
import * as i9 from "./displacement-or-resolution.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../Services/moveprocessing.service";
var styles_DisplacementOrResolutionComponent = [i0.styles];
var RenderType_DisplacementOrResolutionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DisplacementOrResolutionComponent, data: {} });
export { RenderType_DisplacementOrResolutionComponent as RenderType_DisplacementOrResolutionComponent };
export function View_DisplacementOrResolutionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { date_el: 0 }), i1.ɵqud(671088640, 2, { time_el: 0 }), i1.ɵqud(671088640, 3, { option: 0 }), i1.ɵqud(671088640, 4, { ProceedBtn: 0 }), i1.ɵqud(671088640, 5, { DownloadBtn: 0 }), i1.ɵqud(671088640, 6, { ErrorOKBtn: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(7, 114688, null, 0, i3.HeaderComponent, [i4.UserService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "col-lg-12 no-padd "]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DISPLACEMENT OR RESOLUTION NOTICES"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "page-titlesub"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 82, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This routine will generate Displacement Letter or Resolution Letter."])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 15, "ul", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "p", [["class", "columns choices-yn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Notices(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1."])), (_l()(), i1.ɵted(-1, null, [" Displacement Notices (Abolishment/Bumps)"])), (_l()(), i1.ɵeld(22, 0, null, null, 10, "p", [["class", "columns choices-yn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Notices(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2."])), (_l()(), i1.ɵted(-1, null, [" Resolution Notices "])), (_l()(), i1.ɵeld(26, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "li", [["class", "li-resolution"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["- Exercise Seniority"])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "li", [["class", "li-resolution"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["- Filled Vacancy"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "li", [["class", "li-resolution"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["- Furlough"])), (_l()(), i1.ɵeld(33, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(35, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(36, { "col-lg-12": 0, "no-padd": 1, "form-mainmenu": 2, "marginbottom2": 3 }), (_l()(), i1.ɵeld(37, 0, null, null, 1, "span", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PLEASE CHOOSE 1 OR 2:"])), (_l()(), i1.ɵeld(39, 0, [[3, 0], ["option", 1]], null, 1, "input", [["autofocus", ""], ["class", "mf-input"], ["maxlength", "1"], ["numbersOnly", ""], ["type", "text"]], [[8, "value", 0]], [[null, "keydown.enter"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 40).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.NavigateTo($event.target.value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 16384, null, 0, i6.NumberOnlyDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(41, 0, null, null, 7, "div", [["class", "col-lg-12 no-padd form-mainmenu"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "span", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ENTER DATE:"])), (_l()(), i1.ɵeld(44, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, [["DatePlaceholder", 1]], null, 1, "input", [["appDateFormat", ""], ["class", "mf-input biddate DateTimePlaceholder"], ["disabled", ""], ["type", "text"], ["value", "yyyy-mm-dd"]], [[4, "display", null]], [[null, "input"], [null, "keypress"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 46).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 46).onKeypress($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(46, 16384, null, 0, i7.DateFormatDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(47, 0, [[1, 0], ["date", 1]], null, 1, "input", [["appDateFormat", ""], ["autofocus", ""], ["class", "mf-input biddate DateTime"], ["maxlength", "10"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keypress"], [null, "input"], [null, "paste"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 48).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 48).onKeypress($event) !== false);
        ad = (pd_1 && ad);
    } if (("keypress" === en)) {
        var pd_2 = (_co.DateTimeChecker(1) !== false);
        ad = (pd_2 && ad);
    } if (("input" === en)) {
        var pd_3 = (_co.DateTimeChecker(1) !== false);
        ad = (pd_3 && ad);
    } if (("paste" === en)) {
        var pd_4 = (_co.paste($event, 1) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.Reset(1) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(48, 16384, null, 0, i7.DateFormatDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(49, 0, null, null, 7, "div", [["class", "col-lg-12 no-padd form-mainmenu"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(50, 0, null, null, 1, "span", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ENTER TIME:"])), (_l()(), i1.ɵeld(52, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, [[2, 0], ["time", 1]], null, 1, "input", [["appTimeFormat", ""], ["autofocus", ""], ["class", "mf-input biddate DateTime"], ["maxlength", "13"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keypress"], [null, "input"], [null, "keydown.enter"], [null, "paste"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 54).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 54).onKeypress($event) !== false);
        ad = (pd_1 && ad);
    } if (("keypress" === en)) {
        var pd_2 = (_co.DateTimeChecker(2) !== false);
        ad = (pd_2 && ad);
    } if (("input" === en)) {
        var pd_3 = (_co.DateTimeChecker(2) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.enter" === en)) {
        var pd_4 = (_co.EnterGenerateFile() !== false);
        ad = (pd_4 && ad);
    } if (("paste" === en)) {
        var pd_5 = (_co.paste($event, 2) !== false);
        ad = (pd_5 && ad);
    } if (("click" === en)) {
        var pd_6 = (_co.Reset(2) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(54, 16384, null, 0, i8.TimeFormatDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(55, 0, [["DatePlaceholder", 1]], null, 1, "input", [["appDateFormat", ""], ["class", "mf-input biddate DateTimePlaceholder"], ["disabled", ""], ["type", "text"], ["value", "00.00.00.0000"]], [[4, "display", null]], [[null, "input"], [null, "keypress"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 56).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 56).onKeypress($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(56, 16384, null, 0, i7.DateFormatDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(57, 0, null, null, 7, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(58, 0, [[4, 0], ["ProceedBtn", 1]], null, 4, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GenerateFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(60, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(61, { "btn-save": 0, "btn-disable": 1 }), (_l()(), i1.ɵted(-1, null, ["Proceed"])), (_l()(), i1.ɵeld(63, 0, null, null, 1, "button", [["class", "btn-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoMoveProcessing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CANCEL"])), (_l()(), i1.ɵeld(65, 0, null, null, 20, "div", [["class", "modal modal-dialog-centered"], ["id", "yes-modal"], ["role", "dialog"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(66, 0, null, null, 19, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(67, 0, null, null, 18, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(68, 0, null, null, 17, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(69, 0, null, null, 2, "p", [["class", "modal-desc1"]], null, null, null, null, null)), (_l()(), i1.ɵted(70, null, ["", ""])), (_l()(), i1.ɵeld(71, 0, null, null, 0, "span", [["class", "loader"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(72, 0, null, null, 1, "p", [["class", "modal-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(73, null, ["", ""])), (_l()(), i1.ɵeld(74, 0, null, null, 1, "p", [["class", "modal-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(75, null, ["", ""])), (_l()(), i1.ɵeld(76, 0, null, null, 1, "p", [["class", "modal-desc2"]], null, null, null, null, null)), (_l()(), i1.ɵted(77, null, ["", ""])), (_l()(), i1.ɵeld(78, 0, null, null, 4, "div", [["class", "btn-holder"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(79, 0, [[5, 0], ["DownloadBtn", 1]], null, 1, "button", [["class", "btn btn-download"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.DownloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"])), (_l()(), i1.ɵeld(81, 0, null, null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoMoveProcessing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Exit"])), (_l()(), i1.ɵeld(83, 0, null, null, 2, "div", [["class", "btn-holder"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(84, 0, [[6, 0], ["ErrorOKBtn", 1]], null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.HideErrorPrompt() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"])), (_l()(), i1.ɵeld(86, 0, null, null, 8, "div", [["class", "modal modal-dialog-centered"], ["id", "yes-modal"], ["role", "dialog"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(87, 0, null, null, 7, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(88, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(89, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(90, 0, null, null, 1, "p", [["class", "modal-desc1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invalid date. Please ensure that the date exist."])), (_l()(), i1.ɵeld(92, 0, null, null, 2, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(93, 0, null, null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.HideErrorDateModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 7, 0); var currVal_0 = _ck(_v, 36, 0, true, true, true, !_co.showInputDate); _ck(_v, 35, 0, currVal_0); var currVal_9 = _ck(_v, 61, 0, _co.HasValidInputs, !_co.HasValidInputs); _ck(_v, 60, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.choice, ""); _ck(_v, 39, 0, currVal_1); var currVal_2 = (_co.showInputDate ? "flex" : "none"); _ck(_v, 41, 0, currVal_2); var currVal_3 = (_co.DateInputIE ? "flex" : "none"); _ck(_v, 45, 0, currVal_3); var currVal_4 = (_co.isIE() ? "" : "yyyy-mm-dd"); _ck(_v, 47, 0, currVal_4); var currVal_5 = (_co.showInputDate ? "flex" : "none"); _ck(_v, 49, 0, currVal_5); var currVal_6 = (_co.isIE() ? "" : "00.00.00.0000"); _ck(_v, 53, 0, currVal_6); var currVal_7 = (_co.TimeInputIE ? "flex" : "none"); _ck(_v, 55, 0, currVal_7); var currVal_8 = !_co.HasValidInputs; _ck(_v, 58, 0, currVal_8); var currVal_10 = (_co.showModal ? "flex" : "none"); _ck(_v, 65, 0, currVal_10); var currVal_11 = _co.content; _ck(_v, 70, 0, currVal_11); var currVal_12 = (_co.OnProcess ? "inline-block" : "none"); _ck(_v, 71, 0, currVal_12); var currVal_13 = _co.timee; _ck(_v, 73, 0, currVal_13); var currVal_14 = _co.count; _ck(_v, 75, 0, currVal_14); var currVal_15 = _co.ques; _ck(_v, 77, 0, currVal_15); var currVal_16 = (_co.ActivateDownload ? "block" : "none"); _ck(_v, 78, 0, currVal_16); var currVal_17 = (_co.ActivateExit ? "block" : "none"); _ck(_v, 83, 0, currVal_17); var currVal_18 = (_co.errorModal ? "flex" : "none"); _ck(_v, 86, 0, currVal_18); }); }
export function View_DisplacementOrResolutionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-displacement-or-resolution", [], null, null, null, View_DisplacementOrResolutionComponent_0, RenderType_DisplacementOrResolutionComponent)), i1.ɵdid(1, 4308992, null, 0, i9.DisplacementOrResolutionComponent, [i10.ActivatedRoute, i10.Router, i11.MoveprocessingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DisplacementOrResolutionComponentNgFactory = i1.ɵccf("app-displacement-or-resolution", i9.DisplacementOrResolutionComponent, View_DisplacementOrResolutionComponent_Host_0, {}, {}, []);
export { DisplacementOrResolutionComponentNgFactory as DisplacementOrResolutionComponentNgFactory };
