import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIEndpointService } from '../../Services/api-endpoint.service';
import { UserService } from 'src/app/Services/auth/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements AfterViewInit {
  landing_page = environment.landing_page;
  constructor(private router: Router, private apiService: APIEndpointService, private userService: UserService) {}
  showMoveCityToCityModal: boolean;
  displayRecallRepModal: boolean;
  displaySeniorityRepModal: boolean;
  mctcGenerateStatus: string;
  printRecallRepStatus: string;
  printSeniortyRepStatus: string;
  extractResult: {
    numberOfRecordsEx: any,
    responseTime: any,
  };
  extractReport: {
    recordSize: any,
    responseTime: any,
  };

  ngAfterViewInit(){
    setTimeout(() => {
      let input = document.querySelector("input");
      if(input) input.focus();
    },500);
  }

  NavigateTo(NavKey){
    NavKey = NavKey.replace(/\s/g, "");
    switch (NavKey) {
      case "1":
        this.router.navigate(['/MoveProcessing']);
        break;
      case "2":
        this.router.navigate(['/Headcount']);
        break;
      case "3":
        this.router.navigate(['/GeneralPersonnel']);
        break;
      case "4":
        this.router.navigate(['/Records']);
        break;
      case "5":
        this.downloadMoveCityToCityFile();
        break;
      case "6":
        this.downloadReportFile('recall');
        break;
      case "7":
        this.downloadReportFile('seniority');
        break;
      case "E":
        window.top.location.href = this.landing_page;
        break;
    }
    return;
  }

  downloadMoveCityToCityFile() {
    this.showMoveCityToCityModal = true;
  }

  generateMctcfile() {
    this.mctcGenerateStatus = 'generating';
    this.apiService.GetRequest('/movectc/extract')
      .subscribe(
        (response) => {
          if(response.ok){
            const { numberOfRecordsEx, responseTime } = response.body;
            this.extractResult = {
              numberOfRecordsEx,
              responseTime,
            };
            this.mctcGenerateStatus = 'completed';
          } else {
            this.mctcGenerateStatus = 'error';
          }
        },
        (error) => {
          this.mctcGenerateStatus = 'error';
        });
  }

  startMctcDownload() {
    this.mctcGenerateStatus = 'generating';
    this.apiService.DownloadFile('/movectc/download', 'MCCFILE');
    this.closeMctcDownload();
  }

  closeMctcDownload() {
    this.mctcGenerateStatus = null;
    this.extractResult = null;
    this.showMoveCityToCityModal = false;
  }

  downloadReportFile(type?: string) {
    type === 'recall' ?  this.displayRecallRepModal = true :  this.displaySeniorityRepModal = true;
  }

  extractRecallRepFile() {
    this.printRecallRepStatus = 'generating';
    this.apiService.PostRequest('/report/recall-station/extract', this.getPostData()).subscribe(response => {
      if (response.ok) {
        const { recordSize, responseTime } = response.body;
        this.extractReport = {
            recordSize,
            responseTime
          };
        this.printRecallRepStatus = 'completed';
      } else {
        this.printRecallRepStatus = 'error';
      }}, (error) => {
        this.printRecallRepStatus = 'error';
    });
  }

  extractSeniorityRepFile() {
    this.printSeniortyRepStatus = 'generating';
    this.apiService.PostRequest('/report/seniority-lists/extract', this.getPostData()).subscribe(response => {
      if (response.ok) {
        const { recordSize, responseTime } = response.body;
        this.extractReport = {
            recordSize,
            responseTime
          };
        this.printSeniortyRepStatus = 'completed';
      } else {
        this.printSeniortyRepStatus = 'error';
      }}, (error) => {
        this.printSeniortyRepStatus = 'error';
    });
  }

  getTimeStamp() {
    const date = new Date();
    return ('0' + (date.getMonth() + 1).toString()).substr(-2)
            + ('0' + date.getDate().toString()).substr(-2)
            + (date.getFullYear()).toString().substr(2, 2);
  }

  getPostData() {
    let postBody;
    const userName = this.userService.getUser().user;
    if (userName !== null && userName !== 'null') {
        postBody = `{"authorName": "${userName}"}`;
      } else if (this.userService.getUser().email)  {
        postBody = `{"authorName": "${this.userService.getUser().email}"}`;
      } else {
        postBody = '{"authorName": "GPMS"}';
      }
    return postBody;
  }

  startReportDownload(key?: number) {
    if (key === 6 ) {
      this.printRecallRepStatus = 'generating';
      this.apiService.DownloadFile('/report/recall-station/download', `Station_Recall_Report_D${this.getTimeStamp()}.xlsx`);
    } else {
      this.printSeniortyRepStatus = 'generating';
      this.apiService.DownloadFile('/report/seniority-lists/download', `IAM_Seniority_Lists_D${this.getTimeStamp()}.xlsx`);
    }
    this.closeQueryModal(key);
  }

  closeQueryModal(key?: number) {
    if (key === 6) {
      this.displayRecallRepModal = false;
      this.printRecallRepStatus = null;
    } else {
      this.displaySeniorityRepModal = false;
      this.printSeniortyRepStatus = null;
    }
    this.extractReport = null;
  }
}
