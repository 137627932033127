import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HeadcountService } from '../../../Services/headcount.service';
import {Router,ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-hc-menu',
  templateUrl: './hc-menu.component.html',
  styleUrls: ['./hc-menu.component.scss']
})
export class HCMenuComponent implements OnInit, AfterViewInit {
  [x: string]: any;

  constructor(private HeadCountService: HeadcountService, private router:Router,private aRoute:ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }

  NavigateTo(NavKey){
    NavKey = NavKey.replace(/\s/g, "");
    switch (NavKey) {
      case "2":      
        this.gotoVacationProj();
        break;
      case "E":      
        this.gotoMainMenu();
        break;
    }
    return;
  }

  
  gotoReportFunction(){
    this.router.navigate(['Headcount/Reportfunction']);
  }
  gotoVacationProj(){
    this.router.navigate(['Headcount/VacationProject']);
  }
  gotoMainMenu(){
    this.router.navigate(['Mainmenu']);
  }

}
