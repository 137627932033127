import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[booleanOnly]'
})

export class BooleanInputOnlyDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
     this._el.nativeElement.value =  initalValue.replace(/[^\NY]{1}$/igm, '');
    if ( this._el.nativeElement.value=="Y"&&this._el.nativeElement.value=="N") {
      event.stopPropagation();
    }
  }
}
