<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">VACATION CALCULATION SUMMARIES</span>
    <span class="page-titlesub">VACCALC</span>
</div>

<div class="col-lg-12 no-padd">
    <p class="description"><br>PRODUCE VAC CALC SUMMARIES BY DIVISION/CITY BEFORE 11/01</p>
    <p class="description">Calculates employees allowed off at a time for line departments</p>
    <br>

    <ul class="col-lg-12 no-padd">
        <li class="columns choices-yn" (click)="choice(1)"><span class="menu-num">1.</span> SUMMARIES FOR DISTRIBUTION TO STNS (LONG VERSION)</li>
        <!-- <li class="columns choices-yn" (click)="showBlockModal()"><span class="menu-num">2.</span> SUMMARIES FOR DISTRIBUTION TO DIVISION HEADS (SHORTER)</li> -->
        <li class="columns choices-yn" (click)="exit()"><span class="menu-num">2.</span> BACK TO VACATION PROJECT MENU</li>
    </ul>
    <br><br><br>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">{{ content }}<span [style.display]="OnProcess ? 'inline-block' : 'none'"> </span></p>
                    <p class="modal-time">{{ time }}</p>
                    <p class="modal-count">{{ count }}</p>
                    <p class="modal-desc2">{{ ques }}</p>
                    <div class="btn-holder" [style.display]="showModalButton ? 'block' : 'none'">
                        <!-- <button class="btn btn-download" (click)='vacCalcx()'>Download</button> -->
                        <button class="btn btn-exit" (click)='exit()'>Ok</button>
                    </div>
                    <!-- <div class="btn-holder" [style.display]="showExitButton ? 'block' : 'none'">
                        <button class="btn btn-exit" (click)='HideErrorPrompt()'>Ok</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="blockModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">This option is not currently used.</p>
                    <div class="btn-holder">
                        <button class="btn btn-exit" (click)='hideBlockModal() '>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 no-padd form-mainmenu" name="form-mainmenu">
        <span class="columns">ENTER OPTION HERE:</span>
        <input type="text" class="mf-input" numbersOnly autofocus maxlength="1" (keydown.enter)="NavigateTo($event.target.value) " toUpperCase>
    </div>
</div>