/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./components/footer/footer.component.ngfactory";
import * as i4 from "./components/footer/footer.component";
import * as i5 from "./Services/api-endpoint.service";
import * as i6 from "./app.component";
import * as i7 from "./Services/auth/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mf-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-footer", [], null, null, null, i3.View_FooterComponent_0, i3.RenderType_FooterComponent)), i1.ɵdid(4, 114688, null, 0, i4.FooterComponent, [i5.APIEndpointService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "keydown"], ["window", "mousemove"], ["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:keyup" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).keyEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i6.AppComponent, [i7.AuthenticationService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
