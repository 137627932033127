<app-header></app-header>
<div class="vacalist">
  <div>
    <div class="col-lg-12 no-padd ">
      <span class="page-title">EDITING PROBATION FILE</span>
      <span class="page-titlesub">PROBATION</span>
    </div>

    <div class="form-inline">
      <div class="form-group no-padd">
        <label for="EMPNO">EMP_NO:</label>
        <input type="text" class="mf-input-vaca empno" minlength="6" maxlength="6" [(ngModel)]="EmpNo" numbersOnly>
      </div>

      <div class="form-group">
        <label for="EMPNO1">NAME:</label>
        <input type="text" class="mf-input-vaca lastname" toUpperC [(ngModel)]="Name" maxlength="18"
          toUpperCase>
      </div>

      <div class="form-group no-padd">
        <input type="button" class="btn-search" (click)="SearchProb()" value="SEARCH">
        <input type="button" class="btn-clear" (click)="ResetSearch()" value="RESET">
      </div>
    </div>

    <div class="table-list" [hidden]="!hasSearchResults">
      <table class="col-xs-12" #ProbationTable>
        <th>EMP_NO</th>
        <th>DEPT</th>
        <th>NAME</th>
        <th>SHIFT</th>
        <tr *ngFor="let item of ProbList ; let i = index;" (click)=SelectProb(i)
          [ngClass]="{'colortest':item.id == SelectedID,'tr-list':true}">
          <td class="td-empno">{{item.empNo}}</td>
          <td class="td-deptno">{{item.deptName}}</td>
          <td class="td-lastname">{{item.name}}</td>
          <td class="td-lastname">{{item.shift}}</td>
        </tr>
      </table>
    </div>
  </div>

  <div [hidden]="hasSearchResults" class="search-warning">
    <p>{{ isLoading ? 'Loading...' : 'No Results found' }}</p>
  </div>

  <div class="pagination" [hidden]="!hasSearchResults">
    <button class="bttn"  (click)='getFirstProb()' >FIRST</button>
    <button class="bttn bttn-previous ml-1 mr-0" (click)='PreviousPage()' [disabled]="PaginationDisabled || isPageLoading || first"
      [style.color]="first ? '#232323' : '#FFFFFF'"><<</button>
    <span class="paginationSpan">SHOWING {{VCurrentPage}} page of {{totalPages}}</span>
    <button class="bttn bttn-next ml-0 mr-1" (click)='NextPage()' [disabled]="PaginationDisabled|| isPageLoading || last"
          [style.color]="last ? '#232323' : '#FFFFFF'">>></button>
    <button class="bttn" (click)='getLastProb()'>LAST</button>
  </div>

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="saveSuccessModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Changes made in this page has been saved.</p>
          <div class="btn-holder">
            <button class="btn btn-exit" (click)='CloseModal()'>OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="CheckChangesModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Save all the changes made?</p>
          <div class="btn-holder">
            <button class="btn btn-download" (click)='SaveChanges()'>YES</button>
            <button class="btn btn-exit" (click)='DiscardChanges()'>DISCARD</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="DeleteModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Record will be deleted. Do you want to proceed?</p>
          <div class="btn-holder">
            <button class="btn btn-download" (click)='SaveChanges("YesFromDelModal")'>YES</button>
            <button class="btn btn-exit" (click)='CloseDeleteModal()'>No</button>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="ExitModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1">Save all the changes made before exiting?</p>
          <div class="btn-holder">
            <button class="btn btn-download"  (click)='SaveChanges("exit");'>YES</button>
            <button class="btn btn-exit" [routerLink]="['/Records/ProbationarySystem']" >DISCARD</button>
          </div>
        </div>
      </div>
    </div>
  </div>


    <!-- Modal -->
    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="SaveAndExitModal ? 'flex' : 'none'">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p class="modal-desc1">Changes made in this page has been saved.</p>
            <div class="btn-holder">
              <button class="btn btn-exit" [routerLink]="['/Records/ProbationarySystem']" >OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>



  <form [formGroup]="EmployeeForm" [style.display]="enableForm ? 'flex' : 'none'">
    <table class="table">
      <tr>
        <input type="hidden" formControlName="id">

        <td> <span class="label">EMPNO:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-1 no-pad" readonly formControlName="empNo"
            toUpperCase maxlength="6"></td>

        <td><span class="label">NAME:</span></td>
        <td colspan="3"><input type="text" class="mf-input mf-input-edit-name no-pad" formControlName="name"
            toUpperCase maxlength="25"></td>
      </tr>
      <tr>
        <td> <span class="label">JOBCLASS:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-1 no-pad" formControlName="jobClass"
            toUpperCase maxlength="3"></td>

        <td> <span class="label">DEPTNAME:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-2 no-pad" formControlName="deptName"
            toUpperCase maxlength="20"></td>
      </tr>
      <tr>
        <td> <span class="label">CITYCODE:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-1 no-pad" toUpperCase
            formControlName="cityCode" maxlength="3"></td>

        <td> <span class="label">SHIFT:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-2 no-pad" toUpperCase
            formControlName="shift" maxlength="3"></td>

        <td> <span class="label">STARTDT:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-3 no-pad" appDateInput toUpperCase
            (input)="ComputeDates($event)" formControlName="startDt" maxlength="8"></td>
      </tr>
      <tr>
        <td> <span class="label">DAY30:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-1 no-pad"  toUpperCase
            formControlName="day30" maxlength="9" readonly></td>

        <td> <span class="label">DAY60:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-2 no-pad" toUpperCase
            formControlName="day60" maxlength="9" readonly></td>

        <td> <span class="label">DAY90:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-3 no-pad" toUpperCase
            formControlName="day90" maxlength="9" readonly></td>
      </tr>
      <tr>
        <td> <span class="label">REASON:</span></td>
        <td colspan="3"><input type="text" class="mf-input mf-input-edit-reason no-pad"
            toUpperCase formControlName="reason" maxlength="20"></td>

        <td> <span class="label">SENT30:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-3 no-pad" appDateInput toUpperCase
            formControlName="sent30" maxlength="8"></td>
      </tr>
      <tr>
        <td> <span class="label">SENT60:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-1 no-pad" appDateInput toUpperCase
            formControlName="sent60" maxlength="8"></td>

        <td> <span class="label">SENT90:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-2 no-pad" appDateInput toUpperCase
            formControlName="sent90" maxlength="8"></td>

        <td> <span class="label">SENTO_30:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-3 no-pad" toUpperCase
            formControlName="sento30" maxlength="15"></td>
      </tr>
      <tr>
        <td> <span class="label">SENTO_60:</span></td>
        <td colspan="3"><input type="text" class="mf-input mf-input-edit-1 no-pad"
            toUpperCase formControlName="sento60" maxlength="15"></td>

        <td> <span class="label">SENTO_90:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-3 no-pad" toUpperCase
            formControlName="sento90" maxlength="15"></td>
      </tr>
      <tr>
        <td> <span class="label">RECD30:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-1 no-pad" (keypress)="validateRecInput($event)" toUpperCase
            formControlName="recd30" maxlength="1"></td>

        <td> <span class="label">RECD60:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-2 no-pad" booleanOnly (keypress)="validateRecInput($event)" toUpperCase
            formControlName="recd60" maxlength="1"></td>

        <td> <span class="label">RECD90:</span></td>
        <td><input type="text" class="mf-input mf-input-edit-3 no-pad" booleanOnly (keypress)="validateRecInput($event)" toUpperCase
            formControlName="recd90" maxlength="1"></td>
      </tr>
      <tr>
        <td> <span class="label">COMMENTS:</span></td>
        <td colspan="5"><input type="text" class="mf-input mf-input-edit-comment no-pad"
            toUpperCase formControlName="comments" maxlength="42"></td>
      </tr>
    </table>
  </form>

  <div class="btn-holder" [style.display]="enableForm ? 'block' : 'none'">
    <button class="btn-save" (click)="SaveChanges()" >Update</button>
    <!-- <button class="btn-cancel" (click)="CheckExit()">EXIT</button>  (click)="modalSaveChanges()" -->
    <button class="btn-cancel" (click)="DiscardAndExit()">EXIT</button>

  </div>

</div>
