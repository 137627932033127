import { Component, OnInit } from '@angular/core';
import { APIEndpointService } from 'src/app/Services/api-endpoint.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version;

  constructor(private api: APIEndpointService) { }

  ngOnInit(): void {
    const version_link = environment.api_home + '/public/version/generate';

    fetch(version_link, {
      method: "GET",
    }).then(res => {
      res.text().then(data => {
        this.version = data;
      })
    })
  }

}
