/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./probationary-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../header/header.component.ngfactory";
import * as i3 from "../../header/header.component";
import * as i4 from "../../../Services/auth/user.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../Directives/uppercase.directive";
import * as i7 from "./probationary-menu.component";
var styles_ProbationaryMenuComponent = [i0.styles];
var RenderType_ProbationaryMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProbationaryMenuComponent, data: {} });
export { RenderType_ProbationaryMenuComponent as RenderType_ProbationaryMenuComponent };
export function View_ProbationaryMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.UserService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 49, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col-lg-12 no-padd main-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["===== MAINTENANCE OPERATIONS ADMINISTRATION ====="])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["RECORDS SECTION (PROBATIONARY SYSTEM MENU)"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["============================================================================"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "line columns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 30, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "p", [["class", "choices"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 1), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A01."])), (_l()(), i1.ɵted(-1, null, [" ENTER NEW RECORDS AND PRINT SCHEDULES"])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "p", [["class", "choices"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.EditProbationary() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A02."])), (_l()(), i1.ɵted(-1, null, [" EDIT THE PROBATIONARY FILE (CHANGE RECORDS)"])), (_l()(), i1.ɵeld(25, 0, null, null, 3, "p", [["class", "choices"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoPrintReportsDue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A03."])), (_l()(), i1.ɵted(-1, null, [" PRINT LISTING REPORTS DUE TO BE SENT"])), (_l()(), i1.ɵeld(29, 0, null, null, 3, "p", [["class", "choices"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A04."])), (_l()(), i1.ɵted(-1, null, [" PRINT PROBATION LOG"])), (_l()(), i1.ɵeld(33, 0, null, null, 3, "p", [["class", "choices"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A05."])), (_l()(), i1.ɵted(-1, null, [" PRINT LISTING OF REPORTS THAT ARE LATE"])), (_l()(), i1.ɵeld(37, 0, null, null, 3, "p", [["class", "choices exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoRecordsMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0E."])), (_l()(), i1.ɵted(-1, null, [" EXIT TO RECORDS MENU"])), (_l()(), i1.ɵeld(41, 0, null, null, 3, "p", [["class", "choices"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoDepartmentalMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0X."])), (_l()(), i1.ɵted(-1, null, [" EXIT TO DEPARTMENTAL MENU"])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(46, 0, null, null, 0, "span", [["class", "line columns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(47, 0, null, null, 4, "div", [["class", "col-lg-12 no-padd"], ["name", "form-mainmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(48, 0, null, null, 1, "span", [["class", "col3 columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ENTER SELECTION FROM ABOVE MENU: "])), (_l()(), i1.ɵeld(50, 0, null, null, 1, "input", [["autofocus", ""], ["class", "mf-input"], ["maxlength", "1"], ["toUpperCase", ""], ["type", "text"]], null, [[null, "keydown.enter"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 51).onKeyup($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.NavigateTo($event.target.value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(51, 16384, null, 0, i6.UppercaseDirective, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = _ck(_v, 17, 0, "/Records/ProbationarySystem/EnterNewRecords"); _ck(_v, 16, 0, currVal_0); }, null); }
export function View_ProbationaryMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-probationary-menu", [], null, null, null, View_ProbationaryMenuComponent_0, RenderType_ProbationaryMenuComponent)), i1.ɵdid(1, 4308992, null, 0, i7.ProbationaryMenuComponent, [i5.ActivatedRoute, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProbationaryMenuComponentNgFactory = i1.ɵccf("app-probationary-menu", i7.ProbationaryMenuComponent, View_ProbationaryMenuComponent_Host_0, {}, {}, []);
export { ProbationaryMenuComponentNgFactory as ProbationaryMenuComponentNgFactory };
