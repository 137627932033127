import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Subscription, interval } from 'rxjs';
import { MoveprocessingService } from 'src/app/Services/moveprocessing.service';


@Component({
  selector: 'app-move-letters',
  templateUrl: './move-letters.component.html',
  styleUrls: ['./move-letters.component.scss'],
  providers: [DatePipe]
})
export class MoveLettersComponent implements OnInit {

  @ViewChild('closingdate', {static: false}) closingdate: ElementRef;
  @ViewChild('option', {static: false}) option: ElementRef;
  @ViewChild('ProceedBtn', {static: false}) ProceedBtn: ElementRef;
  @ViewChild('DownloadBtn', {static: false}) DownloadBtn: ElementRef;
  @ViewChild('ErrorOKBtn', {static: false}) ErrorOKBtn: ElementRef;
  subscription: Subscription;
  showInputDate: boolean;
  choice: number
  bidDate: any;
  showModal: boolean;
  errorModal: boolean;
  HasValidDate:boolean;
  ActivateDownload: boolean;
  ActivateExit: boolean;
  OnProcess: boolean;
  DateToday: any;
  content: string;
  counter: number;
  subcatcher: Subscription;
  realTime;
  realCount;
  awd3 = [];
  http: any;
  time;
  count;
  ques;
  subcounter:number;

  constructor(private route: ActivatedRoute, private router: Router, private datePipe: DatePipe, private moveProcessing: MoveprocessingService) { }

  ngOnInit(): void {
     let WholeDate= new Date();
     this.DateToday = this.datePipe.transform(WholeDate, 'yyyy-MM-dd');
     this.subcounter = -1;
  }
  
  ngAfterViewInit() {
    setTimeout(() => document.querySelector("input").focus(), 500);
  }

  NavigateTo(NavKey) {
    switch (NavKey) {
      case "1":
        this.Awards(1);
        break;
      case "2":
        this.Awards(2);
        break;
      case "3":
        this.Awards(3);
        break;
      case "4":
        this.gotoMoveProcessing();
        break;
    }
    return;
  }

  gotoMoveProcessing() {
    this.router.navigate(['MoveProcessing']);
  }

  Awards(choice) {
    this.showInputDate = true;
    this.choice = choice;
    this.closingdate.nativeElement.value ="";
    setTimeout(() => this.closingdate.nativeElement.focus(), 50);
    this.HasValidDate = false;
  }

  ClosingBidDate() {
    let biddate_raw = this.closingdate.nativeElement.value;
    this.bidDate = this.closingdate.nativeElement.value;

    if(biddate_raw){
      if (biddate_raw.trim().match(/([0-9][0-9][0-9][0-9])[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])/) && biddate_raw.length >= 9) {
        this.HasValidDate = true;
        this.bidDate = biddate_raw;

        setTimeout(() => this.ProceedBtn.nativeElement.focus(), 50);
      }
      else{
        this.HasValidDate = false;
      }
    }
  }

  ResetBiddate(){
    this.closingdate.nativeElement.value ="";
    this.HasValidDate = false;
  }

  EnterGenerateFile(){
    if(this.HasValidDate) this.GenerateFile();
  }

  GenerateFile() {
    this.OnProcess =  true;
    this.ProceedBtn.nativeElement.blur();
    let bidDateJSON = '{"bidDate": "'+ this.bidDate + '"}';

    if(this.bidDate === ""){
      this.errorModal = true;
    }
    else{
      this.showModal = true;
      switch (this.choice) {
        case 1:
          this.content = "The system is creating the AWARD (MONDAY) file...";
          this.subscription = this.moveProcessing.getAwardsMonday(bidDateJSON).subscribe((res: any)=> {
            let data= this.moveProcessing.extractData(res);
            this.awd3.push(data);
            this.subcounter++;
            this.realCount = this.awd3[this.subcounter].numberOfRecordsEx;
            this.realTime = this.awd3[this.subcounter].responseTime;
          }, onError => {
            console.log("error");
          })
          break;

        case 2:
          this.content = "The system is creating the REAWARD file...";
          this.subscription = this.moveProcessing.getReawards(bidDateJSON).subscribe((res: any)=> {
            let data= this.moveProcessing.extractData(res);
            this.subcounter++;
            this.awd3.push(data);
            this.realCount = this.awd3[this.subcounter].numberOfRecordsEx;
            this.realTime = this.awd3[this.subcounter].responseTime;
          }, onError => {
            console.log("error");
          })
          break;

        case 3:
          this.content = "The system is creating the AWARD (THURSDAY) file...";
          this.subscription = this.moveProcessing.getAwardsThursday(bidDateJSON).subscribe((res: any)=> {
            let data= this.moveProcessing.extractData(res);
            this.subcounter++;
            this.awd3.push(data);
            this.realCount = this.awd3[this.subcounter].numberOfRecordsEx;
            this.realTime = this.awd3[this.subcounter].responseTime;
          }, onError => {
            console.log("error");
          })
          break;
      }
      
      const source = interval(1000);
      this.counter = 1;
      this.subcatcher = source.subscribe(val => this.checkvalue(this.realTime, this.realCount, this.counter++));
    }
  }

  checkvalue(realtime, realcount, counter) {
    if(counter <= 10) {
      if(realtime === undefined) {}
      else{
        this.subcatcher && this.subcatcher.unsubscribe();
        if(realcount == 0){
          this.content = 'No records found.';
          this.ActivateExit = true;
          this.OnProcess = false;
        }
        else{
          this.OnProcess = false;
          switch (this.choice) {
            case 1:
              this.content = 'The system is done creating the AWARD (MONDAY) file';
              break;
            case 2:
              this.content = 'The system is done creating the REAWARD file';
              break;
            case 3:
              this.content = 'The system is done creating the AWARD (THURSDAY) file';
              break;
          }
          this.time = 'Time: ' + this.realTime + ' millisecond(s)';
          this.count = 'Total number of records: ' + this.realCount;
          this.ques = 'Do you want to download the file?';
          this.ActivateDownload = true;
          setTimeout(() => this.DownloadBtn.nativeElement.focus(), 50);
        }
      }
    }
    else {
      this.subcatcher && this.subcatcher.unsubscribe();
      this.content = "The system failed to create the file. Please try again later."; 
      this.ActivateExit = true;
      setTimeout(() => this.ErrorOKBtn.nativeElement.focus(), 50);
      this.OnProcess = false;
    }
  }

  HideErrorDateModal() {
    this.errorModal = false;
  }

  HideErrorPrompt(){
    this.showModal = false;
    this.choice = null;
    this.showInputDate = false;
    this.closingdate.nativeElement.value ="";
    this.ActivateExit = false;
    setTimeout(() => document.querySelector("input").focus(), 500);
    this.gotoMoveProcessing();
  }

  DownloadFile() {
    this.showModal = false;
    switch (this.choice) {
      case 1:
        this.moveProcessing.DownloadMoveLettersFile("/move/MoveAwards3/download", "MP_4.1_AWDS_MON");
        this.showModal =false;
        break;
      case 2:
        this.moveProcessing.DownloadMoveLettersFile("/moves/MoveReAward/download", "MP_4.2_REAWARDS");
        this.showModal =false;
        break;
      case 3:
        this.moveProcessing.DownloadMoveLettersFile("/move/MoveAwards5/download", "MP_4.3_AWDS_THU");
        this.showModal =false;
        break;
    }
    this.gotoMoveProcessing();
  }

  isIE() {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    
    return is_ie; 
  }
  
  
}