<app-header></app-header>
<!-- Start of Main Menu-->
<div>
    <div class="col-lg-12 no-padd main-title">
        <span>=====            MAINTENANCE ADMINISTRATION            =====</span><br>
        <span>MAIN DEPARTMENTAL MENU</span><br>
        <span>============================================================================</span>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <ul class="col-lg-12 no-padd">
        <li class="col3 choices-main" [routerLink]="['/MoveProcessing']"><span class="menu-num">1.</span> MOVE PROCESSING</li>
        <li class="col3 choices-main" [routerLink]="['/Headcount']"><span class="menu-num">2.</span> HEADCOUNT/AUDIT</li>
        <li class="col3 choices-main" [routerLink]="['/GeneralPersonnel']"><span class="menu-num">3.</span> GENERAL PERSONNEL</li>
        <li class="col3 choices-main" [routerLink]="['/Records']"><span class="menu-num">4.</span> RECORDS</li>
        <li class="col3 choices-main" (click)="downloadMoveCityToCityFile()"><span class="menu-num">5.</span> EMPLOYEE MOVING FROM CITY TO CITY</li>
        <li class="col3 choices-main" (click)="downloadReportFile('recall')" ><span class="menu-num">6.</span> RECALL STATION QUERY AND REPORTING</li>
        <li class="col3 choices-main" (click)="downloadReportFile('seniority')"><span class="menu-num">7.</span> SENIORITY QUERY AND REPORTING</li>        
        <li class="col3 choices-main" (click)="NavigateTo('E')"><span class="menu-num">E.</span> EXIT TO GPMS HOME</li>
    </ul>

    <div class="col-lg-12 no-padd">
        <span class="col3">ENTER SELECTION FROM ABOVE MENU: </span>
        <input type="text" class="mf-input" maxlength="2" onblur="this.value = ''" toUpperCase (keydown.enter)="NavigateTo($event.target.value)">

    </div>

    <!-- End of Main Menu-->

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="moveCityModal" role="dialog" [style.display]="showMoveCityToCityModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="mctcGenerateStatus !== 'error'">
            <div *ngIf="!mctcGenerateStatus">
              Click continue to download the Employee Moving From City to City file.
            </div>
            <div *ngIf="mctcGenerateStatus === 'generating'" class="loader">Generating file...</div>

            <div *ngIf="!mctcGenerateStatus" class="btn-holder mt-5">
              <button type="button" (click)="generateMctcfile()" class="btn btn-download">Continue</button>
              <button type="button" (click)="closeMctcDownload()" class="btn btn-exit">Cancel</button>
            </div>

            <div *ngIf="(mctcGenerateStatus === 'completed')">
              <p>The system is done creating the Employee Moving From City to City file.</p>
              <p class="modal-time">Execution time: {{ extractResult && extractResult.responseTime }} milliseconds</p>
              <p class="modal-count">Total number of records: {{ extractResult && extractResult.numberOfRecordsEx }}</p>
              <p>Do you want to download the file?</p>
          </div>

            <div *ngIf="mctcGenerateStatus === 'completed'" class="btn-holder">
              <button type="button" (click)="startMctcDownload()" class="btn btn-download">Download</button>
              <button type="button" (click)="closeMctcDownload()" class="btn btn-exit">Cancel</button>
            </div>
          </div>
          <div *ngIf="mctcGenerateStatus === 'error'">
            Something went wrong, please try again!
            <div class="btn-holder mt-5">
              <button type="button" (click)="closeMctcDownload()" class="btn btn-download">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="moveCityModal" role="dialog" [style.display]="displayRecallRepModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="printRecallRepStatus !== 'error'">
            <div *ngIf="!printRecallRepStatus">
              Click continue to download the Recall Station Report file.
            </div>
            <div *ngIf="printRecallRepStatus === 'generating'" class="loader">Generating file...</div>

            <div *ngIf="!printRecallRepStatus" class="btn-holder mt-5">
              <button type="button" (click)="extractRecallRepFile()" class="btn btn-download">Continue</button>
              <button type="button" (click)="closeQueryModal(6)" class="btn btn-exit">Cancel</button>
            </div>

            <div *ngIf="(printRecallRepStatus === 'completed')">
              <p>The system is done creating the Recall Station Report file.</p>
              <p class="modal-time">Execution time: {{ extractReport && extractReport.responseTime }} milliseconds</p>
              <p class="modal-count">Total number of records: {{ extractReport && extractReport.recordSize }}</p>
              <p>Do you want to download the file?</p>
          </div>

            <div *ngIf="printRecallRepStatus === 'completed'" class="btn-holder">
              <button type="button" (click)="startReportDownload(6)" class="btn btn-download">Download</button>
              <button type="button" (click)="closeQueryModal(6)" class="btn btn-exit">Cancel</button>
            </div>
          </div>
          <div *ngIf="printRecallRepStatus === 'error'">
            Something went wrong, please try again.
            <div class="btn-holder mt-5">
              <button type="button" (click)="closeQueryModal(6)" class="btn btn-download">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="moveCityModal" role="dialog" [style.display]="displaySeniorityRepModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="printSeniortyRepStatus !== 'error'">
            <div *ngIf="!printSeniortyRepStatus">
              Click continue to download the IAM Seniority Lists file.
            </div>
            <div *ngIf="printSeniortyRepStatus === 'generating'" class="loader">Generating file...</div>

            <div *ngIf="!printSeniortyRepStatus" class="btn-holder mt-5">
              <button type="button" (click)="extractSeniorityRepFile()" class="btn btn-download">Continue</button>
              <button type="button" (click)="closeQueryModal()" class="btn btn-exit">Cancel</button>
            </div>

            <div *ngIf="(printSeniortyRepStatus === 'completed')">
              <p>The system is done creating the IAM Seniority Lists file.</p>
              <p class="modal-time">Execution time: {{ extractReport && extractReport.responseTime }} milliseconds</p>
              <p class="modal-count">Total number of records</p>
              <p class="modal-count">{{ extractReport && extractReport.recordSize }}</p>
              <p>Do you want to download the file?</p>
          </div>

            <div *ngIf="printSeniortyRepStatus === 'completed'" class="btn-holder">
              <button type="button" (click)="startReportDownload()" class="btn btn-download">Download</button>
              <button type="button" (click)="closeQueryModal()" class="btn btn-exit">Cancel</button>
            </div>
          </div>
          <div *ngIf="printSeniortyRepStatus === 'error'">
            Something went wrong, please try again.
            <div class="btn-holder mt-5">
              <button type="button" (click)="closeQueryModal()" class="btn btn-download">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>