import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HeadcountService } from 'src/app/Services/headcount.service';
import { Subscription, interval } from 'rxjs';
import { APIEndpointService } from 'src/app/Services/api-endpoint.service';

@Component({
  selector: 'app-final-vac-roster',
  templateUrl: './final-vac-roster.component.html',
  styleUrls: ['./final-vac-roster.component.scss']
})
export class FinalVacRosterComponent implements OnInit {
  showModal: boolean;
  showModalButton: boolean;
  showExitButton: boolean;
  OnProcess: boolean;
  content: string;
  FetchedData;
  time: string;
  count: string;
  ques: string;
  realTime;
  realCount;
  subscription: Subscription;
  subcatcher: Subscription;
  initialff;
  counter: number;
  extractResult: {
    numberOfRecordsEx: any,
    responseTime: any,
  };

  constructor(private HeadCountService: HeadcountService, private router: Router, private apiService: APIEndpointService) { }

  ngOnInit(): void {
    // this.subscription = this.HeadCountService.FinalVacationRosterListener().subscribe((data: any) => {
    //   this.initialff = data;
    //   this.realTime = data.responseTime;
    //   this.realCount = data.numberOfRecordsEx;
    // })
  }

  ngAfterViewInit() {
    setTimeout(() => document.querySelector("input").focus(), 500);
  }

  createfinalVacRoster() {    
    this.HeadCountService.DownoadVacationFile('/headcount/vacation/vac5B/download','5B_FINAL_VACATION_ROSTER');
    this.HideErrorPrompt();
    this.router.navigate(['Headcount/VacationProject']);
  }

  gotoMainMenu() {
    this.router.navigate(['']);
  }

  inputNo() {
    this.router.navigate(['Headcount/VacationProject']);
    this.showModal = false;
  }

  NavigateTo(NavKey) {
    switch (NavKey) {
      case "N":
        this.inputNo();
        break;
      case "Y":
        this.show();
        break;
    }
    return;
  }

  show() {
    this.HeadCountService.finalVacationRoster();
    this.showModal = true;
    this.showModalButton = true;
    this.content = "Process will be completed approximately 5mins.";
    // const source = interval(1000);
    // this.counter = 1;
    // this.subcatcher = source.subscribe(val => this.checkvalue(this.realTime, this.counter++));
    // this.OnProcess = true;
    this.apiService.GetRequest('/headcount/vacation/vac5B/extract', '5B_FINAL_VACATION_ROSTER')
      .subscribe(
        (response) => {
          if(response.ok) {
            const { numberOfRecordsEx, responseTime } = response.body;
            this.extractResult = {
              numberOfRecordsEx,
              responseTime,
            };        
            this.OnProcess = false;
          } else {

            this.showError();
          }
        },
        (error) => {
          this.showError();
        });
  }

  checkvalue(realtime, counter) {
    if (counter <= 10) {
      if (realtime === undefined) {
        // console.log(counter);
      }
      else {
        this.subcatcher && this.subcatcher.unsubscribe();
        this.content = 'The system is done creating final vacation roster file.';
        this.time = 'Time: ' + this.realTime + ' millisecond(s)';
        this.count = 'Total number of records: ' + this.realCount;
        this.ques = 'Do you want to download the file?';
        this.showModalButton = true;
        this.OnProcess = false;
      }
    }
    else {
      this.subcatcher && this.subcatcher.unsubscribe();
      this.content = "The system failed to create the file. Please try again later.";
      this.showExitButton = true;
      this.OnProcess = false;
    }
  }

  ngOnDestroy() {
    this.subcatcher && this.subcatcher.unsubscribe();
  }

  HideErrorPrompt() {
    this.showModal = false;
    this.showModalButton = false;
    this.showExitButton = false;
  }

  showError() {
    this.content = "The system failed to create the file. Please try again later."; 
    // this.showExitButton = true;
    this.OnProcess = false;
  }

}
