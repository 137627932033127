/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./move-letters.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../header/header.component.ngfactory";
import * as i3 from "../../header/header.component";
import * as i4 from "../../../Services/auth/user.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../Directives/number-only.directive";
import * as i7 from "../../../Directives/date-format.directive";
import * as i8 from "./move-letters.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../Services/moveprocessing.service";
var styles_MoveLettersComponent = [i0.styles];
var RenderType_MoveLettersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MoveLettersComponent, data: {} });
export { RenderType_MoveLettersComponent as RenderType_MoveLettersComponent };
export function View_MoveLettersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { closingdate: 0 }), i1.ɵqud(671088640, 2, { option: 0 }), i1.ɵqud(671088640, 3, { ProceedBtn: 0 }), i1.ɵqud(671088640, 4, { DownloadBtn: 0 }), i1.ɵqud(671088640, 5, { ErrorOKBtn: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(6, 114688, null, 0, i3.HeaderComponent, [i4.UserService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-lg-12 no-padd "]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["MOVE LETTERS FROM SYSTEM BID AWARDS/REAWARDS"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "page-titlesub"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 70, "div", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This routine will generate Move Letters from Bid Awards/Reawards"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Calculates employees allowed off at a time for line departments"])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 12, "ul", [["class", "col-lg-12 no-padd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "li", [["class", "columns choices-yn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Awards(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1."])), (_l()(), i1.ɵted(-1, null, [" AWARDS (MONDAY)"])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "li", [["class", "columns choices-yn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Awards(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2."])), (_l()(), i1.ɵted(-1, null, [" REAWARDS (ANY DAY)"])), (_l()(), i1.ɵeld(27, 0, null, null, 3, "li", [["class", "columns choices-yn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Awards(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "span", [["class", "menu-num"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["3."])), (_l()(), i1.ɵted(-1, null, [" AWARDS (THURSDAY)"])), (_l()(), i1.ɵeld(31, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(33, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(34, { "col-lg-12": 0, "no-padd": 1, "form-mainmenu": 2, "marginbottom": 3 }), (_l()(), i1.ɵeld(35, 0, null, null, 1, "span", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CHOOSE EITHER 1, 2, OR 3:"])), (_l()(), i1.ɵeld(37, 0, [[2, 0], ["option", 1]], null, 1, "input", [["autofocus", ""], ["class", "mf-input"], ["maxlength", "1"], ["numbersOnly", ""], ["type", "text"]], [[8, "value", 0]], [[null, "keydown.enter"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 38).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.NavigateTo($event.target.value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(38, 16384, null, 0, i6.NumberOnlyDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(39, 0, null, null, 4, "div", [["class", "col-lg-12 no-padd form-mainmenu"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 1, "span", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ENTER THE CLOSING DATE OF THE BIDS:"])), (_l()(), i1.ɵeld(42, 0, [[1, 0], ["closingdate", 1]], null, 1, "input", [["appDateFormat", ""], ["autofocus", ""], ["class", "mf-input biddate"], ["maxlength", "10"], ["placeholder", "yyyy-mm-dd"], ["type", "text"]], null, [[null, "input"], [null, "click"], [null, "keydown.enter"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 43).onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 43).onKeypress($event) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (_co.ClosingBidDate() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.ResetBiddate() !== false);
        ad = (pd_3 && ad);
    } if (("keydown.enter" === en)) {
        var pd_4 = (_co.EnterGenerateFile() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(43, 16384, null, 0, i7.DateFormatDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(44, 0, null, null, 7, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, [[3, 0], ["ProceedBtn", 1]], null, 4, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GenerateFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(47, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(48, { "btn-save": 0, "btn-disable": 1 }), (_l()(), i1.ɵted(-1, null, ["Proceed"])), (_l()(), i1.ɵeld(50, 0, null, null, 1, "button", [["class", "btn-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoMoveProcessing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CANCEL"])), (_l()(), i1.ɵeld(52, 0, null, null, 20, "div", [["class", "modal modal-dialog-centered"], ["id", "yes-modal"], ["role", "dialog"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 19, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(54, 0, null, null, 18, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(55, 0, null, null, 17, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(56, 0, null, null, 2, "p", [["class", "modal-desc1"]], null, null, null, null, null)), (_l()(), i1.ɵted(57, null, ["", ""])), (_l()(), i1.ɵeld(58, 0, null, null, 0, "span", [["class", "loader"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(59, 0, null, null, 1, "p", [["class", "modal-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(60, null, ["", ""])), (_l()(), i1.ɵeld(61, 0, null, null, 1, "p", [["class", "modal-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(62, null, ["", ""])), (_l()(), i1.ɵeld(63, 0, null, null, 1, "p", [["class", "modal-desc2"]], null, null, null, null, null)), (_l()(), i1.ɵted(64, null, ["", ""])), (_l()(), i1.ɵeld(65, 0, null, null, 4, "div", [["class", "btn-holder"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(66, 0, [[4, 0], ["DownloadBtn", 1]], null, 1, "button", [["class", "btn btn-download"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.DownloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"])), (_l()(), i1.ɵeld(68, 0, null, null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoMoveProcessing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Exit"])), (_l()(), i1.ɵeld(70, 0, null, null, 2, "div", [["class", "btn-holder"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(71, 0, [[5, 0], ["ErrorOKBtn", 1]], null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.HideErrorPrompt() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"])), (_l()(), i1.ɵeld(73, 0, null, null, 8, "div", [["class", "modal modal-dialog-centered"], ["id", "yes-modal"], ["role", "dialog"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(74, 0, null, null, 7, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(75, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(76, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(77, 0, null, null, 1, "p", [["class", "modal-desc1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invalid date. Please ensure that the date exist."])), (_l()(), i1.ɵeld(79, 0, null, null, 2, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(80, 0, null, null, 1, "button", [["class", "btn btn-exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.HideErrorDateModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_0 = _ck(_v, 34, 0, true, true, true, !_co.showInputDate); _ck(_v, 33, 0, currVal_0); var currVal_4 = _ck(_v, 48, 0, _co.HasValidDate, !_co.HasValidDate); _ck(_v, 47, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.choice, ""); _ck(_v, 37, 0, currVal_1); var currVal_2 = (_co.showInputDate ? "flex" : "none"); _ck(_v, 39, 0, currVal_2); var currVal_3 = !_co.HasValidDate; _ck(_v, 45, 0, currVal_3); var currVal_5 = (_co.showModal ? "flex" : "none"); _ck(_v, 52, 0, currVal_5); var currVal_6 = _co.content; _ck(_v, 57, 0, currVal_6); var currVal_7 = (_co.OnProcess ? "inline-block" : "none"); _ck(_v, 58, 0, currVal_7); var currVal_8 = _co.time; _ck(_v, 60, 0, currVal_8); var currVal_9 = _co.count; _ck(_v, 62, 0, currVal_9); var currVal_10 = _co.ques; _ck(_v, 64, 0, currVal_10); var currVal_11 = (_co.ActivateDownload ? "block" : "none"); _ck(_v, 65, 0, currVal_11); var currVal_12 = (_co.ActivateExit ? "block" : "none"); _ck(_v, 70, 0, currVal_12); var currVal_13 = (_co.errorModal ? "flex" : "none"); _ck(_v, 73, 0, currVal_13); }); }
export function View_MoveLettersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-move-letters", [], null, null, null, View_MoveLettersComponent_0, RenderType_MoveLettersComponent)), i1.ɵprd(512, null, i5.DatePipe, i5.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(2, 4308992, null, 0, i8.MoveLettersComponent, [i9.ActivatedRoute, i9.Router, i5.DatePipe, i10.MoveprocessingService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var MoveLettersComponentNgFactory = i1.ɵccf("app-move-letters", i8.MoveLettersComponent, View_MoveLettersComponent_Host_0, {}, {}, []);
export { MoveLettersComponentNgFactory as MoveLettersComponentNgFactory };
