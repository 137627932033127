import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-r-menu',
  templateUrl: './r-menu.component.html',
  styleUrls: ['./r-menu.component.scss']
})
export class RMenuComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }

  NavigateTo(NavKey) {
    switch (NavKey) {
      case "1":
        this.Prebids();
        break;
      case "2":
        this.Probationary();
        break;
      case "E":
        this.gotoMainMenu();
        break;
    }
    return;
  }

  Prebids() {
    this.router.navigate(['Mainmenu']);
  }

  Probationary() {
    this.router.navigate(['Records/ProbationarySystem']);
  }

  gotoMainMenu() {
    this.router.navigate(['Mainmenu']);
  }

}
