<app-header></app-header>

<div class="col-lg-12 no-padd main-title">
    <span>***            ANNUAL HOURLY VACATION PROJECT - MAIN MENU             ***</span>
</div>
<div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

<ul class="col-lg-12 no-padd">
    <li [ngClass]="{'choices-vacation-disabled': (hasMEADADMN && !hasSUPERADMIN),'choices-vacation': true}" (click)='NavigateTo("1")'><span class="menu-num">&nbsp;1.</span> CREATE VACATION FILE DB2 TABLES</li>
    <li class="choices-vacation" (click)='NavigateTo("2")'><span class="menu-num">&nbsp;2.</span> EDIT VACATION FILE</li>
    <li [ngClass]="{'choices-vacation-disabled': (hasMEADADMN && !hasSUPERADMIN),'choices-vacation': true}" (click)='NavigateTo("3")'><span class="menu-num">&nbsp;3.</span> DOWNLOAD BASIC SHIFTS INTO VACATION PROJECT</li>
    <li [ngClass]="{'choices-vacation-disabled': (hasMEADADMN && !hasSUPERADMIN),'choices-vacation': true}" (click)='NavigateTo("4")'><span class="menu-num">&nbsp;4.</span> N/A</li>
    <li class="choices-vacation" (click)='NavigateTo("5A")'><span class="menu-num">5A.</span> INITIAL VAC ROSTERS (BEFORE 11/01) BY DIV/CITY/DEPT/SHIFT</li>
    <li class="choices-vacation" (click)='NavigateTo("5B")'><span class="menu-num">5B.</span> FINAL VAC ROSTERS ( AFTER 11/01) BY DIV/CITY/DEPT/SHIFT</li>
    <li class="choices-vacation" (click)='NavigateTo("6A")'><span class="menu-num">6A.</span> PRODUCE VAC CALC SUMMARIES BY DIVISION/CITY BEFORE 11/01</li>
    <li [ngClass]="{'choices-vacation-disabled': (hasMEADADMN && !hasSUPERADMIN),'choices-vacation': true}" (click)='NavigateTo("6B")'><span class="menu-num">6B.</span> PRODUCE VAC CALC SUMMARIES BY DIVISION/CITY AFTER 11/01</li>
    <li [ngClass]="{'choices-vacation-disabled': (hasMEADADMN && !hasSUPERADMIN),'choices-vacation': true}" (click)='NavigateTo("7")' ><span class="menu-num">&nbsp;7.</span> LOAD THE VACATION FILE INTO DB2 (DO NOT RUN 10/15-11/01)</li>
    <li class="choices-vacation" (click)='NavigateTo("8A")'><span class="menu-num">8A.</span> CREATE DAT AUDIT REPORTS</li>
    <li [ngClass]="{'choices-vacation-disabled': (hasMEADADMN && !hasSUPERADMIN),'choices-vacation': true}" (click)='NavigateTo("8B")'><span class="menu-num">8B.</span> UPDATE PREVIOUS YEAR DAT FOR BIDERS (BEFORE 10/15)</li>
    <li [ngClass]="{'choices-vacation-disabled': (hasMEADADMN && !hasSUPERADMIN),'choices-vacation': true}" (click)='NavigateTo("9")'><span class="menu-num">&nbsp;9.</span> PRODUCE VACATION FILE FOR WORKBRAIN</li>
    <li class="choices-vacation vac-exit" (click)='NavigateTo("E")'><span class="menu-num">&nbsp;E.</span> EXIT</li>
</ul>

<div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

<div class="col-lg-12 no-padd" name="form-mainmenu">
    <span class="columns enter-vacation">ENTER SELECTION FROM ABOVE MENU: </span>
    <input type="text" class="mf-input" maxlength="2" autofocus  (keydown.enter)="NavigateTo($event.target.value)" oninput="this.value = this.value.toUpperCase()">
</div>

<!-- Modal -->
<div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="blockModal ? 'flex' : 'none'">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <p class="modal-desc1">This option is not currently used.</p>
                <div class="btn-holder">
                    <button class="btn btn-exit" (click)='hideBlockModal() '>Exit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->

  <!-- Modal -->
  <div class="modal modal-dialog-centered" id="moveCityModal" role="dialog" [style.display]="showVacationWorkbrainModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="workbrainGenerateStatus !== 'error'">
            <div *ngIf="!workbrainGenerateStatus">
              Click continue to download the Vacation file for Workbrain.
            </div>
            <div *ngIf="workbrainGenerateStatus === 'generating'" class="loader">Generating file...</div>

            <div *ngIf="!workbrainGenerateStatus" class="btn-holder mt-5">
              <button type="button" (click)="generateMctcfile()" class="btn btn-download">Continue</button>
              <button type="button" (click)="closeVacationWorkbrainDownload()" class="btn btn-exit">Cancel</button>
            </div>

            <div *ngIf="(workbrainGenerateStatus === 'completed')">
              <p>The system is done creating the Vacation file for Workbrain.</p>
              <p class="modal-time">Execution time: {{ extractResult && extractResult.responseTime }} milliseconds</p>
              <p class="modal-count">Total number of records: {{ extractResult && extractResult.numberOfRecordsEx }}</p>
              <p>Do you want to download the file?</p>
          </div>

            <div *ngIf="workbrainGenerateStatus === 'completed'" class="btn-holder">
              <button type="button" (click)="startMctcDownload()" class="btn btn-download">Download</button>
              <button type="button" (click)="closeVacationWorkbrainDownload()" class="btn btn-exit">Cancel</button>
            </div>
          </div>
          <div *ngIf="workbrainGenerateStatus === 'error'">
            Something went wrong, please try again!
            <div class="btn-holder mt-5">
              <button type="button" (click)="closeVacationWorkbrainDownload()" class="btn btn-download">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!--Create IVR for Audit Reports Modal -->
  <div class="modal modal-dialog-centered" id="moveCityModal" role="dialog" [style.display]="showCreateIVRModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="createIVRGenerateStatus !== 'error'">
            <div *ngIf="!createIVRGenerateStatus">
              Process will be completed approximately 5mins.
            </div>

            <div *ngIf="!createIVRGenerateStatus" class="btn-holder mt-5">
              <button type="button" (click)="closeIVRReportsDownload()" class="btn btn-exit">OK</button>
            </div>

          </div>
          <div *ngIf="createIVRGenerateStatus === 'error'">
            Something went wrong, please try again!
            <div class="btn-holder mt-5">
              <button type="button" (click)="closeIVRReportsDownload()" class="btn btn-download">Ok</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--Update IVR DAT For Non-Bidder Modal -->
  <div class="modal modal-dialog-centered" id="moveCityModal" role="dialog" [style.display]="showUpdateIVRModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="updateIVRGenerateStatus !== 'error'">
            <div *ngIf="!updateIVRGenerateStatus">
              Process will be completed approximately 5mins.
            </div>

            <div class="btn-holder">
              <button type="button" (click)="closeUpdateIVRDownload()" class="btn btn-exit">OK</button>
            </div>
          </div>
          <div *ngIf="updateIVRGenerateStatus === 'error'">
            Something went wrong, please try again!
            <div class="btn-holder mt-5">
              <button type="button" (click)="closeUpdateIVRDownload()" class="btn btn-download">Ok</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Load Vacation to DB2 Modal -->
  <div class="modal modal-dialog-centered" id="moveCityModal" role="dialog" [style.display]="showLoadVacationToDB2Modal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="loadVacationToDB2Status !== 'error'">
            <div *ngIf="!loadVacationToDB2Status">
              Process will be completed approximately 5mins.
            </div>
            <div *ngIf="!loadVacationToDB2Status" class="btn-holder mt-5">
              <button type="button" (click)="closeLoadVacationToDB2()" class="btn btn-exit">OK</button>
            </div>

          </div>
          <div *ngIf="loadVacationToDB2Status === 'error'">
            {{loadVacationToDB2ErrorMessage}}
            <div class="btn-holder mt-5">
              <button type="button" (click)="closeLoadVacationToDB2()" class="btn btn-download">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
