<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">MOVE LETTERS FROM SYSTEM BID AWARDS/REAWARDS</span>
    <span class="page-titlesub"></span>
</div>

<div class="col-lg-12 no-padd">
    <p class="description"><br>This routine will generate Move Letters from Bid Awards/Reawards</p>
    <p class="description">Calculates employees allowed off at a time for line departments</p>
    <br>

    <ul class="col-lg-12 no-padd">
        <li class="columns choices-yn" (click) = "Awards(1)"><span class="menu-num">1.</span> AWARDS (MONDAY)</li>
        <li class="columns choices-yn" (click) = "Awards(2)"><span class="menu-num">2.</span> REAWARDS (ANY DAY)</li>
        <li class="columns choices-yn" (click) = "Awards(3)"><span class="menu-num">3.</span> AWARDS (THURSDAY)</li>
    </ul>

    <div [ngClass]="{'col-lg-12': true, 'no-padd': true, 'form-mainmenu': true, 'marginbottom': !showInputDate}">
        <span class="columns">CHOOSE EITHER 1, 2, OR 3:</span>
        <input type="text" class="mf-input" #option autofocus numbersOnly maxlength="1" (keydown.enter)="NavigateTo($event.target.value)" value = {{choice}}>
    </div>

    <div class="col-lg-12 no-padd form-mainmenu" [style.display]="showInputDate ? 'flex' : 'none'">
        <span class="columns">ENTER THE CLOSING DATE OF THE BIDS:</span>
        <input type="text" class="mf-input biddate" #closingdate autofocus appDateFormat (input)="ClosingBidDate()" (click) = ResetBiddate() (keydown.enter)="EnterGenerateFile()" maxlength="10" placeholder="yyyy-mm-dd">
    </div>

    <div class="btn-holder">
        <button [ngClass]="{'btn-save':HasValidDate, 'btn-disable':!HasValidDate}" [disabled]="!HasValidDate" (click)="GenerateFile()" #ProceedBtn>Proceed</button>
        <button class="btn-cancel" (click)="gotoMoveProcessing()">CANCEL</button>
    </div>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1" >{{ content }}<span class="loader" [style.display]="OnProcess ? 'inline-block' : 'none'"> </span></p> 
                    <p class="modal-time">{{ time }}</p>
                    <p class="modal-count">{{ count }}</p>
                    <p class="modal-desc2">{{ ques }}</p>
                    <div class="btn-holder" [style.display]="ActivateDownload ? 'block' : 'none'">
                        <button class="btn btn-download" (click)='DownloadFile()' #DownloadBtn>Download</button>
                        <button class="btn btn-exit" (click)='gotoMoveProcessing()'>Exit</button>
                    </div>
                    <div class="btn-holder" [style.display]="ActivateExit ? 'block' : 'none'">
                        <button class="btn btn-exit" (click)='HideErrorPrompt()' #ErrorOKBtn>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="errorModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">Invalid date. Please ensure that the date exist.</p>
                    <div class="btn-holder">
                        <button class="btn btn-exit" (click)='HideErrorDateModal()'>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>