import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { interval } from 'rxjs';
export var KEY_CODE;
(function (KEY_CODE) {
    KEY_CODE[KEY_CODE["ESC"] = 27] = "ESC";
})(KEY_CODE || (KEY_CODE = {}));
var AppComponent = /** @class */ (function () {
    function AppComponent(auth, router) {
        this.auth = auth;
        this.router = router;
        this.title = 'gpmsmf-clist-angular';
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        //notify activity
        this.auth.callPing();
        var source = interval(5 * 60 * 1000);
        this.sessionCheck = source.subscribe(function () {
            console.log('session check');
            if (!_this.auth.isAuthenticated()) {
                console.log('session timeout, refresing page to get token');
                location.reload();
            }
            setTimeout(function () { return _this.auth.callInactivityCheck(); }, 0);
        });
        this.auth.userActivityTimeout();
        this.auth.userInactive.subscribe(function () {
            console.log('notify user is active');
            _this.auth.callPing();
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.sessionCheck)
            this.sessionCheck.unsubscribe();
    };
    AppComponent.prototype.onKeyDown = function (event) {
        window.onhelp = function () { return false; };
        this.auth.resetActivity();
    };
    AppComponent.prototype.onMouseMove = function (event) {
        this.auth.resetActivity();
    };
    AppComponent.prototype.keyEvent = function (event) {
        var e_1, _a;
        var CurrRoute = this.router.url;
        var excepmtedRoutes = ['Records/ProbationarySystem/EditProbationary/', 'GeneralPersonnel'];
        var isRouteExcluded = false;
        try {
            for (var excepmtedRoutes_1 = tslib_1.__values(excepmtedRoutes), excepmtedRoutes_1_1 = excepmtedRoutes_1.next(); !excepmtedRoutes_1_1.done; excepmtedRoutes_1_1 = excepmtedRoutes_1.next()) {
                var route = excepmtedRoutes_1_1.value;
                isRouteExcluded = CurrRoute.includes(route);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (excepmtedRoutes_1_1 && !excepmtedRoutes_1_1.done && (_a = excepmtedRoutes_1.return)) _a.call(excepmtedRoutes_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (this.router.routerState.snapshot.url !== '/' && !isRouteExcluded) {
            if (event.keyCode === KEY_CODE.ESC) {
                this.router.navigateByUrl(CurrRoute.substring(CurrRoute.lastIndexOf("/"), 0));
            }
        }
    };
    return AppComponent;
}());
export { AppComponent };
