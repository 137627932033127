<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">PRINT LISTING REPORTS DUE TO BE SENT</span>
    <br><br>
</div>

<div class="col-lg-12 no-padd">
    <p class="description">This will print the listing of probation reports to be sent this week.</p>
    <p class="description">Are you sure you want to continue? (Y/N)</p>
    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <ul class="col-lg-12 no-padd">
        <li class="choices-yn"(click) = 'displayPrintModal()' ><span class="menu-num">Y.</span> Yes</li>
        <li class="choices-yn" (click) = 'goBackToProbationSystem()'><span class="menu-num">N.</span> No</li>
    </ul>
    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <!-- Modal -->
  <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p class="modal-desc1 loader" *ngIf="!showModalStatus && !modalError">The system is creating the probation reports file...</p>
            <div class="modal-decision" *ngIf="showModalStatus">
              <p class="modal-desc1">The system is done creating the probation reports file.</p>
              <p class="modal-time">Time: {{ realTime }} millisecond(s)</p>
              <p class="modal-count">Total number of records: {{ realCount }}</p>
              <p class="modal-desc2">Do you want to download the file?</p>
              <div class="btn-holder">
                <button class="btn btn-download" (click) = 'printReportsDue()'>Download</button>
                <button class="btn btn-exit" (click) = 'goBackToProbationSystem()'>Exit</button>
              </div>
            </div>
          <div class="btn-holder" *ngIf="modalError">
            <p class="modal-desc1">The system failed to create the file. Please try again later.</p>
            <button class="btn btn-exit" (click) = 'hideErrorPrompt()'>Ok</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->

    <div class="col-lg-12 no-padd" name="form-mainmenu">
        <span class="columns">ENTER OPTION HERE:</span>
        <input type="text" class="mf-input" maxlength="1" autofocus booleanOnly  toUpperCase (keydown.enter)="NavigateTo($event.target.value)" onblur="this.value = ''">
    </div>
</div>


 

