const tsoWeb = 'https://tso-web.gpms-dev1.aa.com';
const tsoService = 'https://tso-svc.gpms-dev1.aa.com';
const gpmsHome = 'https://gpms-dev1.aa.com';
const authService = 'https://auth.gpms-dev1.aa.com/';
const ping = 10;

export const environment = {
  local_dev_mode : false,
  production: false,
  api_home : tsoService,
  index : tsoWeb,
  auth_api : authService,
  landing_page : gpmsHome,
  ping: ping
};
