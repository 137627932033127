import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { APIEndpointService } from '../Services/api-endpoint.service'

@Injectable({
  providedIn: 'root'
})
export class HeadcountService {

  vacafileff;
  basicshiftff;
  inactiveff;
  rotatingff;
  initialff;
  vaccalcxff;
  vaccalc6B1ff;
  finalVacationf;
  private vacafileffListener = new Subject();
  private basicshiftffListener = new Subject();
  private inactivefileffListener = new Subject();
  private rotatingfileffListener = new Subject();
  private initialVacRosterffListener = new Subject();
  private vacationCalculationXffListener = new Subject();
  private vacationCalculation6B1ffListener = new Subject();
  private finalVacationRosterListener = new Subject();

  
  constructor(private api: APIEndpointService) { }


  extractData(data){
    return this.api.extractData(data);
   }

  fetchfromUrl(url: any): Observable<any> {
    return this.api.GetRequest(url);
  }

  FetchVacaList(filter,page): Observable<any> {
    return this.api.PostRequest("/headcount/vacation/edit/SearchVacaList",filter,{"size":5,"page":page});
  }

  SearchEmpRecord(filter,page): Observable<object> {
    return this.api.PostRequest("/headcount/vacation/edit/SearchVacaList", filter,{"size":5,"page":page});
  }

  SaveEmpRecord(EmpData){
    return this.api.PostRequest("/headcount/vacation/edit/SaveVacaList", EmpData);
  }

  getCreateVacaFile() {
    this.fetchfromUrl('/headcount/vacation/create/extract').subscribe((Response)=>{
    this.vacafileff = this.api.extractData(Response);
    this.vacafileffListener.next(this.vacafileff);
    });
  }

  downloadBasicShift() {
    this.fetchfromUrl('/headcount/vacation/downloadBasicShifts/insertShifts').subscribe((Response)=>{
    this.basicshiftff = this.api.extractData(Response);
    this.basicshiftffListener.next(this.basicshiftff);
    });
  }

  produceInactiveList() {
    this.fetchfromUrl('/headcount/vacation/inactive/extract').subscribe((Response) => {
      this.inactiveff = this.api.extractData(Response);
      this.inactivefileffListener.next(this.inactiveff);
    });
  }

  produceRotatingList() {
    this.fetchfromUrl('/headcount/vacation/rotate/extract').subscribe((Response) => {
      this.rotatingff = this.api.extractData(Response);
      this.rotatingfileffListener.next(this.rotatingff);
    });
  }

  initialVacRoster(){
    this.fetchfromUrl('/headcount/vacation/vaclist/extract').subscribe((Response) => {
      this.initialff = this.api.extractData(Response);
      this.initialVacRosterffListener.next(this.initialff);
    });
  }
  vacationCalculationX(){
    this.fetchfromUrl('/headcount/vacation/vaccalcx/extract').subscribe((Response) => {
      this.vaccalcxff = this.api.extractData(Response);
      this.vacationCalculationXffListener.next(this.vaccalcxff);
    });
  }
  
  vacationCalculation6B1(){
    this.fetchfromUrl('/headcount/vacation/vac6B1/extract').subscribe((Response) => {
      this.vaccalc6B1ff = this.api.extractData(Response);
      this.vacationCalculation6B1ffListener.next(this.vaccalc6B1ff);
    });
  }

  finalVacationRoster(){
    this.fetchfromUrl('/headcount/vacation/vac5B/extract').subscribe((Response) => {
      this.finalVacationf = this.api.extractData(Response);
      this.finalVacationRosterListener.next(this.finalVacationf);
    });
  }

  DownoadVacationFile(Url,FileName){
    this.api.DownloadFile(Url,FileName);
  }
  DownloadVacationEdit(Url, FileName) {
    this.api.DownloadFile(Url, FileName);
  }

  vacafileFFListener() {
    return this.vacafileffListener.asObservable();
  }
  basicshiftFFListener() {
    return this.basicshiftffListener.asObservable();
  }
  inactivefileListener() {
    return this.inactivefileffListener.asObservable();
  }
  rotatingfileListener(){
    return this.rotatingfileffListener.asObservable();
  }
  FinalVacationRosterListener(){
    return this.finalVacationRosterListener.asObservable();
  }
  initialVacRosterListener(){
    return this.initialVacRosterffListener.asObservable();
  }
  vacationCalculationXListener(){
    return this.vacationCalculationXffListener.asObservable();
  }
  vacationCalculation6B1Listener(){
    return this.vacationCalculation6B1ffListener.asObservable();
  }

}

