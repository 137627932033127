import { Routes } from '@angular/router';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MVMenuComponent } from './components/MoveProcessing/mv-menu/mv-menu.component';
import { MoveLettersComponent } from './components/MoveProcessing/move-letters/move-letters.component';
import { DisplacementOrResolutionComponent } from './components/MoveProcessing/displacement-or-resolution/displacement-or-resolution.component';
import { HCVacationProjectComponent } from './components/Headcount/hc-vacation-project/hc-vacation-project.component';
import { CreateVacationFileComponent } from './components/Headcount/hc-vacation-project/create-vacation-file/create-vacation-file.component';
import { EditVacationComponent } from './components/Headcount/hc-vacation-project/edit-vacation/edit-vacation.component';
import { EditVacationListComponent } from './components/Headcount/hc-vacation-project/edit-vacation-list/edit-vacation-list.component';
import { DownloadBasicShiftComponent } from './components/Headcount/hc-vacation-project/download-basic-shift/download-basic-shift.component';
import { InitialVacRosterComponent } from './components/Headcount/hc-vacation-project/initial-vac-roster/initial-vac-roster.component';
import { FinalVacRosterComponent } from './components/Headcount/hc-vacation-project/final-vac-roster/final-vac-roster.component';
import { ProdVacCalcAComponent } from './components/Headcount/hc-vacation-project/prod-vac-calc-a/prod-vac-calc-a.component';
import { ProdVacCalcBComponent } from './components/Headcount/hc-vacation-project/prod-vac-calc-b/prod-vac-calc-b.component';
import { HCMenuComponent } from './components/Headcount/hc-menu/hc-menu.component';
import { ProduceRotatingEmpListComponent } from './components/Headcount/hc-vacation-project/produce-rotating-emp-list/produce-rotating-emp-list.component';
import { ProduceInactiveListComponent } from './components/Headcount/hc-vacation-project/produce-inactive-list/produce-inactive-list.component';
import { RMenuComponent } from './components/Records/r-menu/r-menu.component';
import { ProbationaryMenuComponent } from './components/Records/probationary-menu/probationary-menu.component';
import { EditProbationaryComponent } from './components/Records/probationary-menu/edit-probationary/edit-probationary.component';
import { PSEnterNewRecordsComponent } from './components/Records/probationary-menu/ps-enter-new-records/ps-enter-new-records.component';
import { GPMenuComponent } from './components/GeneralPersonnel/gp-menu/gp-menu.component';
import { PrintProbationaryDueComponent } from './components/Records/probationary-menu/print-probationary-due/print-probationary-due.component';
import { ExitComponent } from './components/exit/exit.component';
import { AuthenticationGuardService } from './Services/auth/auth-guard.service';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
var ɵ0 = { expectedRole: 'SUPERADMIN' }, ɵ1 = { expectedRole: 'SUPERADMIN' }, ɵ2 = { expectedRole: 'SUPERADMIN' }, ɵ3 = { expectedRole: 'SUPERADMIN' };
var routes = [
    { path: '', redirectTo: 'Mainmenu', pathMatch: 'full', canActivate: [AuthenticationGuardService] },
    { path: 'Mainmenu', component: MainMenuComponent, canActivate: [AuthenticationGuardService] },
    // MOVE PROCESSING SETION
    { path: 'MoveProcessing', component: MVMenuComponent, canActivate: [AuthenticationGuardService] },
    { path: 'MoveProcessing/MoveLetters', component: MoveLettersComponent, canActivate: [AuthenticationGuardService] },
    { path: 'MoveProcessing/DisplacementOrResolutionNotices', component: DisplacementOrResolutionComponent, canActivate: [AuthenticationGuardService] },
    // HEADCOUNT SECTION
    { path: 'Headcount', component: HCMenuComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject', component: HCVacationProjectComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject/CreateVacafile', component: CreateVacationFileComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject/RotatingEmployees', component: ProduceRotatingEmpListComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject/EditVacationFile', component: EditVacationComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject/DownloadBasicShift', component: DownloadBasicShiftComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject/VACAINAC', component: ProduceInactiveListComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject/VacationList', component: EditVacationListComponent, canActivate: [AuthenticationGuardService], data: ɵ0 },
    { path: 'Headcount/VacationProject/ProduceInactiveList', component: ProduceInactiveListComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Headcount/VacationProject/InitialRoster', component: InitialVacRosterComponent, canActivate: [AuthenticationGuardService], data: ɵ1 },
    { path: 'Headcount/VacationProject/FinalRoster', component: FinalVacRosterComponent, canActivate: [AuthenticationGuardService], data: ɵ2 },
    { path: 'Headcount/VacationProject/ProduceVacCalcA', component: ProdVacCalcAComponent, canActivate: [AuthenticationGuardService], data: ɵ3 },
    { path: 'Headcount/VacationProject/ProduceVacCalcB', component: ProdVacCalcBComponent, canActivate: [AuthenticationGuardService] },
    { path: 'VacationProject', component: HCVacationProjectComponent, canActivate: [AuthenticationGuardService] },
    // RECORDS SECTION
    { path: 'Records', component: RMenuComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Records/Prebids', component: ProbationaryMenuComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Records/ProbationarySystem', component: ProbationaryMenuComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Records/ProbationarySystem/EditProbationary/:Page', component: EditProbationaryComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Records/ProbationarySystem/PrintReportsDue', component: PrintProbationaryDueComponent, canActivate: [AuthenticationGuardService] },
    { path: 'Records/ProbationarySystem/EnterNewRecords', component: PSEnterNewRecordsComponent, canActivate: [AuthenticationGuardService] },
    // GENERAL SECTION
    { path: 'GeneralPersonnel', component: GPMenuComponent, canActivate: [AuthenticationGuardService] },
    { path: 'logout', component: ExitComponent, canActivate: [AuthenticationGuardService] },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: '**', redirectTo: 'unauthorized' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
