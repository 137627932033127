import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api-endpoint.service";
var MoveprocessingService = /** @class */ (function () {
    function MoveprocessingService(api) {
        this.api = api;
        this.awardsmondayListener = new Subject();
        this.reawardsListener = new Subject();
        this.awardsthursdayListener = new Subject();
        this.displacementListener = new Subject();
        this.resolutionListener = new Subject();
    }
    MoveprocessingService.prototype.getAwardsMonday = function (bidDateJSON) {
        return this.api.PostRequest("/move/MoveAwards3/extract", bidDateJSON);
    };
    MoveprocessingService.prototype.getReawards = function (bidDateJSON) {
        return this.api.PostRequest("/moves/MoveReAward/extract", bidDateJSON);
    };
    MoveprocessingService.prototype.getAwardsThursday = function (bidDateJSON) {
        return this.api.PostRequest("/move/MoveAwards5/extract", bidDateJSON);
    };
    MoveprocessingService.prototype.getDisplacement = function (DateTimeJSON) {
        return this.api.PostRequest("/move/displacement/notice/extract", DateTimeJSON);
    };
    MoveprocessingService.prototype.getResolution = function (DateTimeJSON) {
        return this.api.PostRequest("/move/notices/resolution/extract", DateTimeJSON);
    };
    MoveprocessingService.prototype.DownloadMoveLettersFile = function (Url, FileName) {
        this.api.DownloadFile(Url, FileName);
    };
    MoveprocessingService.prototype.DownloadNotices = function (Url, FileName) {
        this.api.DownloadFile(Url, FileName);
    };
    MoveprocessingService.prototype.extractData = function (data) {
        return this.api.extractData(data);
    };
    MoveprocessingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MoveprocessingService_Factory() { return new MoveprocessingService(i0.ɵɵinject(i1.APIEndpointService)); }, token: MoveprocessingService, providedIn: "root" });
    return MoveprocessingService;
}());
export { MoveprocessingService };
