<app-header></app-header>
<div class="col-lg-12 no-padd ">
    <span class="page-title">FINAL  VAC ROSTERS (AFTER 11/01) BY DIV/CITY/DEPT/SHIFT</span>
    <span class="page-titlesub"></span>
</div>

<div class="col-lg-12 no-padd">
    <p class="description"><br>THE AMERICAN AIRLINES TWU-IAM ASSOCIATION VACATION LIST WILL BE PRINTED</p>
    <p class="description">Are you sure you want to continue? (Y/N)</p>
    <br>

    <ul class="col-lg-12 no-padd">
        <li class="columns choices-yn" (click)="show()"><span class="menu-num">Y.</span> Yes</li>
        <li class="columns choices-yn" (click)="inputNo()"><span class="menu-num">N.</span> No</li>
    </ul>
    <br><br><br>

    <div class="modal modal-dialog-centered" id="yes-modal" role="dialog" [style.display]="showModal ? 'flex' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p class="modal-desc1">{{ content }}<span class="loader" [style.display]="OnProcess ? 'inline-block' : 'none'"> </span></p>
                    <p class="modal-time">{{ time }}</p>
                    <p class="modal-count">{{ count }}</p>
                    <p class="modal-desc2">{{ ques }}</p>
                    <div class="btn-holder" [style.display]="showModalButton ? 'block' : 'none'">
                        <!-- <button class="btn btn-download" (click)='createfinalVacRoster()'>Download</button> -->
                        <button class="btn btn-exit" (click)='inputNo()'>Ok</button>
                    </div>
                    <!-- <div class="btn-holder" [style.display]="showExitButton ? 'block' : 'none'">
                      <button class="btn btn-exit" (click) = 'HideErrorPrompt()'>Ok</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 no-padd form-mainmenu" name="form-mainmenu">
        <span class="columns">ENTER OPTION HERE:</span>
        <input type="text" class="mf-input" booleanOnly maxlength="1" (keydown.enter)="NavigateTo($event.target.value) " toUpperCase>
    </div>
</div>

 

