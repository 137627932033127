import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeadcountService } from 'src/app/Services/headcount.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-produce-rotating-emp-list',
  templateUrl: './produce-rotating-emp-list.component.html',
  styleUrls: ['./produce-rotating-emp-list.component.scss']
})
export class ProduceRotatingEmpListComponent implements OnInit, AfterViewInit {
  showModal: boolean;
  showModalButton: boolean;
  content: string;
  time: string;
  count: string;
  ques: string;
  realTime;
  realCount;
  subscription: Subscription;
  rotatingff: any;
  subcatcher: Subscription;
  counter: number;
  
  constructor(private HeadCountService: HeadcountService, private router:Router) { }

  ngOnInit(): void {
    this.subscription = this.HeadCountService.rotatingfileListener().subscribe((data: any) => {
      this.rotatingff = data;
      this.realCount = this.rotatingff.numberOfRecordsEx;
      this.realTime = this.rotatingff.responseTime;
    }), error => (console.log(error));
  }

  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }

  FetchedData;


produceRotatingList() {    
  this.HeadCountService.DownoadVacationFile('/headcount/vacation/rotate/download','ROTATING LIST');
  this.hide();
}



  inputYes(){
    console.log("THE USER CLICKED YES!!!! ");
    this.show();

  }
  inputNo(){
    this.router.navigate(['Headcount/VacationProject']);
    this.showModal = false;
  }

  
  NavigateTo(NavKey) {
    switch (NavKey) {
      case "N":
        this.inputNo();
        break;
      case "Y":
        this.inputYes();
        break;
    }
    return;
  }

  show() {
    this.HeadCountService.produceRotatingList();
    this.showModal = true;
    this.content = "The system is creating the list of rotating employee file..."; 
    const source = interval(1000);
    this.counter = 1;
    this.subcatcher = source.subscribe(val => this.checkvalue(this.realTime, this.realCount, this.counter++));
    setTimeout( () => { 
      this.content = "The system is done creating the list of rotating employee file."; 
      this.time="Time: " + this.realTime + "millisecond(s)";
      this.count="Total number of records: " + this.realCount;
      this.ques="Do you want to download the file?";
      this.showModalButton = true;
    }, 2000 );
    
  }

  checkvalue(realtime, realcount,  counter) {
    if(counter <= 10) {
      if(realtime === undefined) {
        // console.log(counter);
      }
      else{
        this.subcatcher && this.subcatcher.unsubscribe();
        this.content = 'The system is done creating the vacation file.';
        this.time = 'Time: ' + realtime + ' millisecond(s)';
        this.count = 'Total number of records: ' + realcount;
        this.ques = 'Do you want to download the file?';
        this.showModalButton = true;
      }
    }
    else {
      this.subcatcher && this.subcatcher.unsubscribe();
      this.content = "The system failed to create the file. Please try again later."; 
      // this.showExitButton = true;
    }
  }

  ngOnDestroy() {
    this.subcatcher && this.subcatcher.unsubscribe();
  }
  
  hide(){
    this.showModal = false;
  }


}
