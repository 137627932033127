import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
        this._localStorage = null;
        //Some browser will not allow localStorage so we check if sessionStorage is allowed
        try {
            this._localStorage = window.localStorage;
        }
        catch (e) { }
        try {
            if (!this._localStorage)
                this._localStorage = window.sessionStorage;
        }
        catch (e) { }
    }
    StorageService.prototype.set = function (key, val) {
        this._localStorage.setItem(key, val);
    };
    StorageService.prototype.get = function (key) {
        return this._localStorage.getItem(key);
    };
    StorageService.prototype.remove = function (key) {
        this._localStorage.removeItem(key);
    };
    StorageService.prototype.clear = function () {
        this._localStorage.clear();
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
