import { Component, OnInit , AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { HeadcountService } from 'src/app/Services/headcount.service';

@Component({
  selector: 'app-produce-inactive-list',
  templateUrl: './produce-inactive-list.component.html',
  styleUrls: ['./produce-inactive-list.component.scss']
})
export class ProduceInactiveListComponent implements OnInit, AfterViewInit{
  subscription: Subscription;
  inactiveff;
  showModal: boolean;
  showModalButton: boolean;
  content: string;
  time: string;
  count: string;
  ques: string;
  realTime;
  realCount;
  subcatcher: Subscription;
  counter: number;
  
  constructor(private headCountService: HeadcountService , private route:ActivatedRoute, private router:Router) { }

  ngOnInit(){
    this.subscription = this.headCountService.inactivefileListener().subscribe((data: any) => {
      this.inactiveff = data;
      this.realCount = data.numberOfRecordsEx;
      this.realTime =  data.responseTime;
    })
  }
  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }

  produceInactiveList() {    
    this.headCountService.DownoadVacationFile('/headcount/vacation/inactive/download','INACTIVE LIST');
    this.hide();
  }



  gotoVacationMenu(){
    this.router.navigate(['Headcount/VacationProject']);
    this.showModal = false;
  }

  NavigateTo(NavKey) {
    switch (NavKey) {
      case "Y":
        this.show();
        break;
      case "N":
        this.gotoVacationMenu();
        break;
    }
    return;
  }


  show() {
    this.headCountService.produceInactiveList();
    this.showModal = true;
    this.content = "The system is creating the list of inactive employees file..."; 
    const source = interval(1000);
    this.counter = 1;
    this.subcatcher = source.subscribe(val => this.checkvalue(this.realTime, this.realCount, this.counter++));
  }

  checkvalue(realtime, realcount,  counter) {
    if(counter <= 10) {
      if(realtime === undefined) {
        // console.log(counter);
      }
      else{
        this.subcatcher && this.subcatcher.unsubscribe();
        this.content = 'The system is done creating the vacation file.';
        this.time = 'Time: ' + realtime + ' millisecond(s)';
        this.count = 'Total number of records: ' + realcount;
        this.ques = 'Do you want to download the file?';
        this.showModalButton = true;
      }
    }
    else {
      this.subcatcher && this.subcatcher.unsubscribe();
      this.content = "The system failed to create the file. Please try again later."; 
      // this.showExitButton = true;
    }
  }

  ngOnDestroy() {
    this.subcatcher && this.subcatcher.unsubscribe();
  }

  hide(){
    this.showModal = false;
  }
}
