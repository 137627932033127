import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HeadcountService } from 'src/app/Services/headcount.service';
import { Subscription, interval } from 'rxjs';
import { APIEndpointService } from 'src/app/Services/api-endpoint.service';

@Component({
  selector: 'app-prod-vac-calc-b',
  templateUrl: './prod-vac-calc-b.component.html',
  styleUrls: ['./prod-vac-calc-b.component.scss']
})
export class ProdVacCalcBComponent implements OnInit {
  showModal: boolean;
  showModalButton: boolean;
  showExitButton: boolean;
  blockModal: boolean;
  OnProcess: boolean;
  content: string;
  time: string;
  count: string;
  ques: string;
  realTime;
  realCount;
  subscription: Subscription;
  vaccalc6B1ff;
  subcatcher: Subscription;
  counter: number;
  extractResult: {
    numberOfRecordsEx: any,
    responseTime: any,
  };

  constructor(private HeadCountService: HeadcountService, private router:Router, private route:ActivatedRoute, private apiService: APIEndpointService) { }

  ngOnInit(): void {
    // this.subscription = this.HeadCountService.vacationCalculation6B1Listener().subscribe((data: any) => {
    //   this.vaccalc6B1ff = data;
    //   this.realTime = data.responseTime;
    //   this.realCount = data.numberOfRecordsEx;
    // })
  }

  ngAfterViewInit(){
    setTimeout(() => document.querySelector("input").focus(),500);
  }

  FetchedData;

  vacCalc6B1() {    
    this.HeadCountService.DownoadVacationFile('/headcount/vacation/vac6B1/download','6B_1_DISTRIBUTION_TO_STNS');
    this.router.navigate(['Headcount/VacationProject']);
    this.showModal = false;
    this.HideErrorPrompt();
  }

  choice(choice) {
    this.show(choice);
  }
  
  NavigateTo(NavKey) {
    switch (NavKey) {
      case "1":
        this.choice(1);
        break;
      // case "2":
      //   this.showBlockModal();
      //   break;
      case "2":
        this.exit();
        break;
    }
    return;
  }

  show(choice) {
    if(choice == 1){
      this.HeadCountService.vacationCalculation6B1();
      this.showModal = true;
      this.showModalButton = true;
      this.content = "Process will be completed approximately 5mins.";
      // const source = interval(1000);
      // this.counter = 1;
      // this.subcatcher = source.subscribe(val => this.checkvalue(this.realTime, this.realCount,this.counter++));
      this.apiService.GetRequest('/headcount/vacation/vac6B1/extract', '6B_1_DISTRIBUTION_TO_STNS')
      .subscribe(
        (response) => {
          if(response.ok) {
            const { numberOfRecordsEx, responseTime } = response.body;
            this.extractResult = {
              numberOfRecordsEx,
              responseTime,
            };
            this.OnProcess = false;
          } else {

            this.showError();
          }
        },
        (error) => {
          this.showError();
        });
    }
    if(choice == 2){
      this.HeadCountService.initialVacRoster();
      this.showModal = true;
      this.content = "The system is creating the Summaries For Distribution To Division Heads (Shorter) file..."; 
      const source = interval(1000);
      this.counter = 1;
      this.subcatcher = source.subscribe(val => this.checkvalue(this.realTime, this.realCount,this.counter++));
    }
    this.OnProcess = true;
  }

  checkvalue(realtime, realcount,  counter) {
    if(counter <= 10) {
      if(realtime === undefined) {
        // console.log(counter);
      }
      else{
        this.subcatcher && this.subcatcher.unsubscribe();
        this.content = 'The system is done creating the Summaries For Distribution To Division Heads file.';
        this.time = 'Time: ' + realtime + ' millisecond(s)';
        this.count = 'Total number of records: ' + realcount;
        this.ques = 'Do you want to download the file?';
        this.showModalButton = true;
        this.OnProcess = false;
      }
    }
    else {
      this.subcatcher && this.subcatcher.unsubscribe();
      this.content = "The system failed to create the file. Please try again later."; 
      this.showExitButton = true;
      this.OnProcess = false;
    }
  }

  ngOnDestroy() {
    this.subcatcher && this.subcatcher.unsubscribe();
  }

  HideErrorPrompt(){
    this.showModal = false;
    this.showModalButton = false;
    this.showExitButton = false;
  }

  exit(){
    this.router.navigate(['Headcount/VacationProject']);
    this.showModal = false;
  }

  getError() {
    this.showModal = false;
  }

  showBlockModal() {
    this.blockModal = true;
  }

  hideBlockModal() {
    this.blockModal = false;
  }

  showError() {
    this.content = "The system failed to create the file. Please try again later."; 
    // this.showExitButton = true;
    this.OnProcess = false;
  }
}
