<app-header></app-header>
<div>
    <div class="col-lg-12 no-padd main-title">
        <span class="">=====            MAINTENANCE OPERATIONS ADMINISTRATION             =====</span><br>
        <span class="">MOVE PROCESSING MENU</span><br>
        <span>============================================================================</span>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd">
        <p class="choices"><span class="menu-num">&nbsp;1.</span> GENERATING EMPLOYEE NOTICES USING FSLETTER</p>
        <p class="choices"><span class="menu-num">&nbsp;2.</span> WORD PROCESSOR FOR EDITING LETTERS OR SPECIAL LISTINGS</p>
        <p class="choices"><span class="menu-num">&nbsp;3.</span> VACANCY LIST <br>
            <span class="choices-cont">AT PANEL TYPE 'USA.ME.DEPT.ADM.TEXT(VACANCY)'</span></p>
        <p class="choices" (click) = "gotoMoveLetters()"><span class="menu-num">&nbsp;4.</span> MOVE LETTERS FROM SYSTEM BID AWARDS/REAWARDS</p>
        <p class="choices"><span class="menu-num">&nbsp;5.</span> PRINT VACANCY LIST</p>
        <p class="choices"><span class="menu-num">&nbsp;6.</span> COUNT OF BIDS/BUMPS/FURLOUGHS FOR WEDNESDAY REPORT</p>
        <p class="choices"><span class="menu-num">&nbsp;7.</span> TRAINING COPY (END OF EACH WORK DAY)</p>
        <p class="choices" (click) = "gotoDisplacementResolution()"><span class="menu-num">&nbsp;8.</span> DISPLACEMENT LETTERS OR RESOLUTION LETTERS</p>
        <p class="choices"><span class="menu-num">&nbsp;9.</span> TSO PREFERENCE BIDDING FUNCTIONS</p>
        <p class="choices"><span class="menu-num">10.</span> TRAINING COPY FOR 431/435</p>
        <p class="choices"><span class="menu-num">11.</span> PAID  MOVES</p>
        <p class="choices"><span class="menu-num">12.</span> TRAINING COPY FOR INSPECTION</p>
        <p class="choices" (click) = "gotoMainMenu()"><span class="menu-num">&nbsp;E.</span> EXIT TO MAIN MENU</p>
    </div>

    <div class="col-lg-12 no-padd"><span class="line columns"> </span></div>

    <div class="col-lg-12 no-padd" name="form-mainmenu">
        <span class="col3 columns">ENTER SELECTION FROM ABOVE MENU: </span>
        <input type="text" class="mf-input" maxlength="2" autofocus (keydown.enter)="NavigateTo($event.target.value)" toUpperCase>
    </div>
</div>