import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { APIEndpointService } from '../Services/api-endpoint.service'


@Injectable({
  providedIn: 'root'
})
export class RecordsProbationService {

  constructor(private api: APIEndpointService) { }


  SearchProbation(filter,page): Observable<object> {
    return this.api.PostRequest("/records/probation/edit/search", filter,{"page":page});
  }

  SaveProbRecord(Probation): Observable<object> {
    return this.api.PostRequest("/records/probation/edit/save", Probation);
  }

  FetchFirst(filter,page) {
    return this.api.PostRequest("/records/probation/edit/search", filter,{"page":page});
  }

  FetchLast(filter,page) {
    return this.api.PostRequest("/records/probation/edit/search", filter,{"page":page});
  }

  extractData(data){
    return this.api.extractData(data);
   }
}
